import Joi from "joi";
import { AsyncResult, Result } from "../models/result";
import { BACKEND_URL } from "../config";
import { logout } from "./utils";

enum HttpMethods {
  GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
}

export type HttpError = {
  error?: string;
  message: string;
  statusCode: number;
};

export type ValidationError = {
  errors: string[];
};

export type CallError = HttpError | ValidationError;

export function isHttpError(error: CallError): error is HttpError {
  return (error as HttpError).statusCode !== undefined;
}

export function isValidationError(error: CallError): error is ValidationError {
  return (error as ValidationError).errors !== undefined;
}

const createQueryParamsSegment = (queryParams?: Record<string, string>) => {
  return queryParams ? `?${new URLSearchParams(queryParams)}` : "";
};

const getErrors = (error: Joi.ValidationError): string[] => {
  return error.details.map((detail) => detail.message);
};

const getText = async (
  path: string,
  queryParams?: Record<string, string>
): AsyncResult<string, CallError> => {
  const response = await fetch(
    `${BACKEND_URL}/${path}${createQueryParamsSegment(queryParams)}`,
    { method: HttpMethods.GET }
  );
  if (!response.ok) {
    if (response.status === 401) {
      logout();
    }
    return Result.err(await response.json());
  }
  return Result.ok(await response.text());
};

const getJson = async <Response>(
  path: string,
  schema?: Joi.ObjectSchema<Response> | Joi.ArraySchema<Response>,
  queryParams?: Record<string, string>
): AsyncResult<Response, CallError> => {
  const response = await fetch(
    `${BACKEND_URL}/${path}${createQueryParamsSegment(queryParams)}`,
    { method: HttpMethods.GET }
  );
  const responseJson = await response.json();
  if (!response.ok) {
    if (response.status === 401) {
      logout();
    }
    return Result.err(responseJson);
  }

  if (schema) {
    const { error, value } = schema.validate(responseJson);
    if (error) {
      return Result.err({ errors: getErrors(error) });
    }
    return Result.ok(value);
  }

  return Result.ok(responseJson);
};

const post = async <Response>(
  path: string,
  body: unknown,
  schema?: Joi.ObjectSchema<Response>,
  queryParams?: Record<string, string>
): AsyncResult<Response | null, CallError> => {
  return writeData(HttpMethods.POST, path, body, schema, queryParams);
};

const patch = async <Response>(
  path: string,
  body: unknown,
  schema?: Joi.ObjectSchema<Response>,
  queryParams?: Record<string, string>
): AsyncResult<Response | null, CallError> => {
  return writeData(HttpMethods.PATCH, path, body, schema, queryParams);
};

const writeData = async <Response>(
  method: HttpMethods,
  path: string,
  body: unknown,
  schema?: Joi.ObjectSchema<Response>,
  queryParams?: Record<string, string>
): AsyncResult<Response | null, CallError> => {
  const response = await fetch(
    `${BACKEND_URL}/${path}${createQueryParamsSegment(queryParams)}`,
    {
      method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    }
  );
  if (!response.ok) {
    if (response.status === 401) {
      logout();
    }
    return Result.err(await response.json());
  }

  const responseText = await response.text();
  if (responseText === "") {
    return Result.ok(null);
  }

  if (schema) {
    const responseJson = JSON.parse(responseText);
    const { error, value } = schema.validate(responseJson);
    if (error) {
      return Result.err({ errors: getErrors(error) });
    }
    return Result.ok(value);
  } else {
    return Result.err({ errors: ["No schema provided for response json"] });
  }
};

export const httpClient = {
  getText,
  getJson,
  post,
  patch,
};
