import Lottie from "lottie-react";
import LoadingAnimation from "../assets/loading/ANS_A_BlackWhite.json";

export const Loading = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        zIndex: 999,
        animation: "fadeIn 5s",
        maxHeight: 500,
        maxWidth: 500,
      }}
    >
      <Lottie animationData={LoadingAnimation} loop={true} />
    </div>
  );
};
