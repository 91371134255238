import { useState } from "react";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { Button, Grid, Typography, IconButton } from "@mui/material";
import ArrowBackFilledIcon from "@mui/icons-material/ArrowBack";
import {
  CardNumberElement,
  ExpirationMonthElement,
  ExpirationYearElement,
  useCollectContainer,
  useMakeSkyflowStyles,
  REGEX_MATCH_RULE,
  LENGTH_MATCH_RULE,
} from "skyflow-react-js";
import { getUserAccountId } from "../../../utils/getUserAccountId";
import { usersApi } from "../../../api";
import get from "lodash/get";
import { SKYFLOW_CARDS_TABLE } from "../../../config";
import { Loading } from "../../../components/Loading";
import { visaOrMastercard } from "../../../utils";

const _P2C = ({
  onComplete,
  goBack,
}: {
  onComplete: (publicToken?: string) => void;
  goBack: () => void;
}) => {
  const container = useCollectContainer();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleCollect = () => {
    setLoading(true);

    const response = container.collect({
      additionalFields: {
        records: [
          {
            table: SKYFLOW_CARDS_TABLE,
            fields: {
              ansible_user_id: getUserAccountId(),
            },
          },
        ],
      },
    });
    response
      .then(async (res: unknown) => {
        const responseFields: any = get(res, ["records", 0, "fields"], {});
        const skyflowId: string | undefined = get(
          responseFields,
          ["skyflow_id"],
          undefined
        );
        const cardToken: string | undefined = get(
          responseFields,
          ["card_number"],
          undefined
        );
        const expMonthToken: string | undefined = get(
          responseFields,
          ["expiration_month"],
          undefined
        );
        const expYearToken: string | undefined = get(
          responseFields,
          ["expiration_year"],
          undefined
        );

        if (!skyflowId || !cardToken || !expMonthToken || !expYearToken) {
          // ERROR
          setLoading(false);
          setError(
            "Something went wrong, please check for typos and try resubmitting"
          );
        } else {
          // SUCCESS
          const response = await usersApi.saveDebitCardInfo(
            String(skyflowId),
            String(cardToken),
            String(expMonthToken),
            String(expYearToken)
          );
          if (response.status === 202) {
            console.log("response", response);
            onComplete(response.data);
          } else {
            // ERROR
            console.log(error);
            setLoading(false);
            setError(
              "Something went wrong, please check for typos and try resubmitting"
            );
          }
        }
      })
      .catch((e: any) => {
        // ERROR
        console.log(e);
        const message =
          e?.response?.data?.message ||
          "Something went wrong, please check for typos and try resubmitting";
        setLoading(false);
        setError(message);
      });
  };

  const useStyles = useMakeSkyflowStyles({
    inputStyles: {
      base: {
        border: "1px solid #bbb",
        borderRadius: "4px",
        color: "#01190F",
        padding: "15px 15px",
        marginTop: 4,
      },
      complete: {
        color: "#01190F",
      },
      empty: {},
      focus: {
        border: "2px solid #20C563",
      },
      invalid: {
        color: "#ED6C02",
      },
      cardIcon: {
        position: "absolute",
        left: "8px",
        bottom: "calc(50% - 12px)",
      },
    },
    labelStyles: {
      base: {
        display: "none",
        fontSize: "16px",
        color: "#01190F",
        fontFamily: "Manrope",
      },
    },
    errorTextStyles: {
      base: {
        color: "#ED6C02",
        fontWeight: 600,
        fontSize: "12px",
      },
    },
  });
  const classes = useStyles();

  if (loading) return <Loading />;

  return (
    <>
      <Grid
        container
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ mb: 3 }}
      >
        <IconButton
          size="small"
          aria-label="delete"
          style={{ alignSelf: "flex-start" }}
          onClick={goBack}
        >
          <ArrowBackFilledIcon />
          <Typography variant="body1">Back</Typography>
        </IconButton>
      </Grid>

      <Typography variant="h6" align="center">
        Add debit card details
      </Typography>
      <Typography variant="body2" align="center">
        We only support Visa and Mastercard debit cards
      </Typography>
      <div style={{ height: 100, marginTop: 40, width: "100%" }}>
        <Typography variant="body1">Card number</Typography>
        <CardNumberElement
          container={container}
          table={SKYFLOW_CARDS_TABLE}
          classes={classes}
          column={"card_number"}
          label={"Card Number"}
          options={{
            enableCopy: false,
          }}
          validations={[
            {
              type: REGEX_MATCH_RULE,
              params: {
                regex: visaOrMastercard,
                error:
                  "Only Visa and Mastercard debit cards are supported at this time",
              },
            },
            {
              type: LENGTH_MATCH_RULE,
              params: { min: 10, error: "Please enter a valid card number" },
            },
          ]}
        />
      </div>
      <div
        style={{
          height: 100,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "48%" }}>
          <Typography variant="body1">Expiration month</Typography>
          <ExpirationMonthElement
            container={container}
            table={SKYFLOW_CARDS_TABLE}
            classes={classes}
            column={"expiration_month"}
            label={"Expiration month"}
            options={{}}
            validations={[
              {
                type: LENGTH_MATCH_RULE,
                params: {
                  min: 2,
                  max: 2,
                  error: "Please enter a valid expiration month",
                },
              },
            ]}
          />
        </div>
        <div style={{ width: "48%" }}>
          <Typography variant="body1">Expiration year</Typography>
          <ExpirationYearElement
            container={container}
            table={SKYFLOW_CARDS_TABLE}
            classes={classes}
            column={"expiration_year"}
            label={"Expiration year"}
            options={{}}
            validations={[
              {
                type: LENGTH_MATCH_RULE,
                params: {
                  min: 2,
                  max: 2,
                  error: "Please enter a valid expiration year",
                },
              },
            ]}
          />
        </div>
      </div>

      {error && (
        <div style={{ marginTop: 24 }}>
          <Typography variant="body2" align="center" color="error">
            {error}
          </Typography>
        </div>
      )}
      <Button
        variant="contained"
        color="secondary"
        size="large"
        sx={{ mt: 4 }}
        fullWidth
        onClick={handleCollect}
        disabled={loading}
      >
        ADD DEBIT CARD
      </Button>
    </>
  );
};

export const P2C = withLDConsumer()(_P2C);
