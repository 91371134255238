import { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { differenceInSeconds } from "date-fns";
import { useLocation } from "react-router-dom";
import { usersApi } from "../../api";
import { saveSessionMetadata } from "../../utils/tokenRepository";
import { Result } from "../../models/result";

// watches for user activity and auth expirations
// structured as a component in case we want to add a visible inactivity warning (like banks do)
export const TimeoutWatcher = () => {
  const location = useLocation();

  const getSecondsToAuthExpiration = (): number => {
    const authExpString = localStorage.getItem("ansible-auth-exp");
    if (!authExpString || authExpString === "") return -1;

    const exp = Number(authExpString);
    const expirationDateTime = new Date(exp * 1000);
    const secondsToExpiration = differenceInSeconds(
      expirationDateTime,
      new Date()
    );
    return secondsToExpiration;
  };

  const refreshUserAuth = async (event: Event | undefined) => {
    const secondsToExpiration = getSecondsToAuthExpiration();
    const secondsToRefresh = 60 * 14; // refresh the token if activity within 14 minutes
    if (secondsToExpiration <= secondsToRefresh && secondsToExpiration > 0) {
      const jwt = await usersApi.refreshAuth();
      Result.onSuccess((value) => saveSessionMetadata(value), jwt);
    }
  };

  useEffect(() => {
    // check for expired auth every 10 seconds
    const interval = setInterval(() => {
      const secondsToExpiration = getSecondsToAuthExpiration();
      if (secondsToExpiration <= 0) {
        // auth is invalid - redirect user unless they're already logged out
        const loggedOutPaths = ["/login", "/", "/embedded"];
        if (window && !loggedOutPaths.includes(location.pathname)) {
          window.location.href = "/login?logout=true";
        }
      }
    }, 1000 * 10);
    return () => clearInterval(interval);
  }, [location]);

  // refresh auth based on user activity
  useIdleTimer({
    timeout: 1000 * 60, // trigger 'onIdle' after 'n' milliseconds of inactivity
    onIdle: () => {}, // may be useful later
    onActive: refreshUserAuth,
    crossTab: true,
    syncTimers: 1,
    eventsThrottle: 200, // only check for fresh events every 'n' milliseconds
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
  });

  return null;
};
