import { Grid, Typography } from "@mui/material";

export const OnboardingComplete = () => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Typography variant="h6" align="center" sx={{ mt: 14, mb: 1 }}>
        You have successfully onboarded. Please close the current tab
      </Typography>
    </Grid>
  );
};
