import React from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import * as FullStory from "@fullstory/browser";
import { useCookies } from "react-cookie";

import { FULLSTORY_ORG_ID } from "./config";
import "./App.scss";
import { AuthContextProvider } from "./context/AuthContext";
import { TimeoutWatcher } from "./components/timeoutWatcher";

if (process?.env?.NODE_ENV === "production") {
  FullStory.init({ orgId: FULLSTORY_ORG_ID }, ({ sessionUrl }) =>
    console.log(`Started session: ${sessionUrl}`)
  );
}

function App() {
  const [searchParams] = useSearchParams();
  const [, setCookies] = useCookies(["beam-referrer", "beam-wallet"]);
  // when the app loads, check if there is a referral code in the url
  // if there is, set the cookie
  React.useEffect(() => {
    if (searchParams.has("referrer")) {
      const referralCode = searchParams.get("referrer");
      if (referralCode) {
        setCookies("beam-referrer", referralCode, {
          path: "/",
          maxAge: 86400,
          domain: ".ansiblelabs.xyz",
          secure: true,
          sameSite: "none",
        });
      }
    }

    // check url for wallet and save to cookies
    if (searchParams.has("wallet")) {
      const wallet = searchParams.get("wallet");
      if (wallet) {
        setCookies("beam-wallet", wallet, {
          path: "/",
          maxAge: 86400,
          domain: ".ansiblelabs.xyz",
          secure: true,
          sameSite: "none",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TimeoutWatcher />
      <AuthContextProvider>
        <main>
          <Outlet />
        </main>
      </AuthContextProvider>
    </>
  );
}

export default App;
