import { TransactionInfo, transactionInfoSchema } from "../models/transactions";
import { Result } from "../models/result";
import { httpClient } from "./httpClient";

export const transactionsQueryKey = ["transactions"];

const getTransactions = async (): Promise<TransactionInfo[]> => {
  const res = await httpClient.getJson<{ data: TransactionInfo[] }>(
    "transactions/",
    transactionInfoSchema
  );
  const res2 = Result.getOrNull(res);
  return res2?.data || [];
};

export const transactionsApi = {
  getTransactions,
};
