import Web3 from "web3";
import {
  mainnet,
  sepolia,
  arbitrum,
  arbitrumSepolia,
  optimism,
  optimismSepolia,
  polygon,
  polygonMumbai,
  avalanche,
  avalancheFuji,
  zora,
  zoraSepolia,
  base,
  baseSepolia,
} from "viem/chains";

import { User } from "../models/user";
import {
  ChainConfig,
  BlockchainFullNames,
  BlockchainNativeToken,
  BeamSupportedZeroHashAsset,
} from "../types";
import { getUserWalletAddress } from "../utils/getUserWalletAddress";
import { ReactComponent as EthIcon } from "../assets/icons/eth.svg";
import { ReactComponent as SolIcon } from "../assets/icons/sol.svg";
import { ReactComponent as PolygonIcon } from "../assets/icons/polygon.svg";
import { ReactComponent as AvaxIcon } from "../assets/icons/avax.svg";
import { ReactComponent as BtcIcon } from "../assets/icons/bitcoin.svg";
import { ReactComponent as XlmIcon } from "../assets/icons/stellar.svg";
import { ReactComponent as ArbIcon } from "../assets/icons/arbitrum.svg";
import { ReactComponent as OpIcon } from "../assets/icons/optimism.svg";
import { ReactComponent as XrpIcon } from "../assets/icons/xrp.svg";
import { ReactComponent as DogeIcon } from "../assets/icons/dogecoin.svg";
import { ReactComponent as ZoraIcon } from "../assets/icons/zora.svg";
import { ReactComponent as BaseIcon } from "../assets/icons/base.svg";
import { isProd as isProdEnv } from "../utils";

const isProd = isProdEnv();
const web3 = new Web3(window.ethereum);

// sources:
// https://chainlist.org/

export const MAINNET_CHAINID = {
  ETH: mainnet.id,
  POLYGON: polygon.id,
  AVAX: avalanche.id,
};

export const TESTNET_CHAINID = {
  ETH: sepolia.id,
  POLYGON: polygonMumbai.id,
  AVAX: avalancheFuji.id,
};

export const TESTNET = {
  ETH: {
    chainName: "Sepolia test network",
    chainId: web3.utils.toHex(TESTNET_CHAINID.ETH),
    nativeCurrency: { name: "Ether", decimals: 18, symbol: "ETH" },
    rpcUrls: [
      "https://endpoints.omniatech.io/v1/eth/sepolia/public",
      "https://ethereum-sepolia-rpc.publicnode.com",
    ],
  },
  POLYGON: {
    chainName: "Mumbai test network",
    chainId: web3.utils.toHex(TESTNET_CHAINID.POLYGON),
    nativeCurrency: { name: "Matic Token", decimals: 18, symbol: "MATIC" },
    rpcUrls: [
      "https://polygon-mumbai.blockpi.network/v1/rpc/public",
      "https://endpoints.omniatech.io/v1/matic/mumbai/public",
    ],
  },
  AVAX: {
    chainName: "Avalanche Fuji Testnet",
    chainId: web3.utils.toHex(TESTNET_CHAINID.AVAX),
    nativeCurrency: { name: "Avalanche", decimals: 18, symbol: "AVAX" },
    rpcUrls: [
      "https://avalanche-fuji-c-chain.publicnode.com",
      "https://api.avax-test.network/ext/bc/C/rpc",
    ],
  },
};

export const MAINNET = {
  ETH: {
    chainName: "Ethereum Mainnet",
    chainId: web3.utils.toHex(MAINNET_CHAINID.ETH),
    nativeCurrency: { name: "Ether", decimals: 18, symbol: "ETH" },
    rpcUrls: ["https://eth.llamarpc.com", "https://ethereum.publicnode.com"],
  },
  POLYGON: {
    chainName: "Polygon Mainnet",
    chainId: web3.utils.toHex(MAINNET_CHAINID.POLYGON),
    nativeCurrency: { name: "Matic Token", decimals: 18, symbol: "MATIC" },
    rpcUrls: [
      "https://polygon-rpc.com",
      "https://polygon.blockpi.network/v1/rpc/public",
    ],
  },
  AVAX: {
    chainName: "Avalanche C-Chain Mainnet",
    chainId: web3.utils.toHex(MAINNET_CHAINID.AVAX),
    nativeCurrency: { name: "Avalanche", decimals: 18, symbol: "AVAX" },
    rpcUrls: [
      "https://avalanche.blockpi.network/v1/rpc/public",
      "https://avalanche.public-rpc.com",
    ],
  },
};

// Solana
// https://explorer.solana.com/
export const MAINNET_SPL_TOKEN_ID = {
  USDC: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
};

export const DEVNET_SPL_TOKEN_ID = {
  USDC: "4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU",
};

export const LAMPORTS_PER_SOL = 10 ** 9;
export const LAMPORTS_PER_USDC = 10 ** 6;

export const CONTRACT_ADDRESSES = {
  // https://developers.circle.com/stablecoins/docs/usdc-on-main-networks
  MAINNET: {
    USDC_POLYGON_POS: "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
    USDC_ETHEREUM: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    USDC_OPTIMISM_NATIVE: "0x0b2c639c533813f4aa9d7837caf62653d097ff85",
    USDC_SOLANA: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    USDC_ARBITRUM_NATIVE: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    USDC_BASE_NATIVE: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
  },
  // https://developers.circle.com/stablecoins/docs/usdc-on-test-networks
  TESTNET: {
    USDC_ETHEREUM_SEPOLIA: "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238",
    USDC_POLYGON_POS: "0x9999f7fea5938fd3b1e26a12c3f2fb024e194f97",
    USDC_OPTIMISM_SEPOLIA_NATIVE: "0x5fd84259d66Cd46123540766Be93DFE6D43130D7",
    USDC_SOLANA_DEVNET: "4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU",
    USDC_ARBITRUM_SEPOLIA_NATIVE: "0x75faf114eafb1BDbe2F0316DF893fd58CE46AA4d",
    USDC_BASE_SEPOLIA_NATIVE: "0x036CbD53842c5426634e7929541eC2318f3dCF7e",
  },
};

// todo: get this (except for Icon) from the backend
export function getChainConfig(user?: User | null): ChainConfig {
  return {
    ETH: {
      name: BlockchainFullNames.ETH,
      address: getUserWalletAddress("ETH", user),
      Icon: EthIcon,
      chainId: isProd ? mainnet.id : sepolia.id,
      nativeToken: BlockchainNativeToken.ETH,
      getTxnScanUrl: (
        hash: string,
        mainnet: boolean = isProd
      ): `https://${string}` =>
        mainnet
          ? `https://etherscan.io/tx/${hash}`
          : `https://sepolia.etherscan.io//tx/${hash}`,
    },
    POLYGON: {
      name: BlockchainFullNames.POLYGON,
      address: getUserWalletAddress("MATIC.POLYGON", user),
      Icon: PolygonIcon,
      chainId: isProd ? polygon.id : polygonMumbai.id,
      nativeToken: BlockchainNativeToken.POLYGON,
      getTxnScanUrl: (
        hash: string,
        mainnet: boolean = isProd
      ): `https://${string}` =>
        mainnet
          ? `https://polygonscan.com/tx/${hash}`
          : `https://mumbai.polygonscan.com/tx/${hash}`,
    },
    SOL: {
      name: BlockchainFullNames.SOL,
      address: getUserWalletAddress("SOL", user),
      Icon: SolIcon,
      nativeToken: BlockchainNativeToken.SOL,
      getTxnScanUrl: (
        hash: string,
        mainnet: boolean = isProd
      ): `https://${string}` =>
        mainnet
          ? `https://solscan.io/tx/${hash}`
          : `https://solscan.io/tx/${hash}?cluster=devnet`,
    },
    ARBITRUM: {
      name: BlockchainFullNames.ARBITRUM,
      address: getUserWalletAddress("ETH.ARBITRUM", user),
      Icon: ArbIcon,
      chainId: isProd ? arbitrum.id : arbitrumSepolia.id,
      nativeToken: BlockchainNativeToken.ARBITRUM,
      getTxnScanUrl: (
        hash: string,
        mainnet: boolean = isProd
      ): `https://${string}` =>
        mainnet
          ? `https://arbiscan.io/tx/${hash}`
          : `https://sepolia.arbiscan.io/tx/${hash}`,
    },
    OPTIMISM: {
      name: BlockchainFullNames.OPTIMISM,
      address: getUserWalletAddress("ETH.OPTIMISM", user),
      Icon: OpIcon,
      chainId: isProd ? optimism.id : optimismSepolia.id,
      nativeToken: BlockchainNativeToken.OPTIMISM,
      getTxnScanUrl: (
        hash: string,
        mainnet: boolean = isProd
      ): `https://${string}` =>
        mainnet
          ? `https://optimistic.etherscan.io/tx/${hash}`
          : `https://sepolia-optimism.etherscan.io/tx/${hash}`,
    },
    BTC: {
      name: BlockchainFullNames.BTC,
      address: getUserWalletAddress("BTC", user),
      Icon: BtcIcon,
      nativeToken: BlockchainNativeToken.BTC,
      getTxnScanUrl: (
        hash: string,
        mainnet: boolean = isProd
      ): `https://${string}` =>
        mainnet
          ? `https://blockstream.info/tx/${hash}`
          : `https://blockstream.info/testnet/tx/${hash}`,
    },
    XLM: {
      name: BlockchainFullNames.XLM,
      address: getUserWalletAddress("XLM", user),
      Icon: XlmIcon,
      nativeToken: BlockchainNativeToken.XLM,
      getTxnScanUrl: (
        hash: string,
        mainnet: boolean = isProd
      ): `https://${string}` =>
        mainnet
          ? `https://stellarchain.io/transactions/${hash}`
          : `https://testnet.stellarchain.io/transactions/${hash}`,
    },
    AVAX: {
      name: BlockchainFullNames.AVAX,
      address: getUserWalletAddress("AVAX", user),
      Icon: AvaxIcon,
      chainId: isProd ? avalanche.id : avalancheFuji.id,
      nativeToken: BlockchainNativeToken.AVAX,
      getTxnScanUrl: (
        hash: string,
        mainnet: boolean = isProd
      ): `https://${string}` =>
        mainnet
          ? `https://snowtrace.io/tx/${hash}`
          : `https://testnet.snowtrace.io/tx/${hash}`,
    },
    XRP: {
      name: BlockchainFullNames.XRP,
      address: getUserWalletAddress("XRP", user),
      Icon: XrpIcon,
      nativeToken: BlockchainNativeToken.XRP,
      getTxnScanUrl: (
        hash: string,
        mainnet: boolean = isProd
      ): `https://${string}` =>
        mainnet
          ? `https://livenet.xrpl.org/transactions/${hash}`
          : `https://testnet.xrpl.org/transactions/${hash}`,
    },
    DOGE: {
      name: BlockchainFullNames.DOGE,
      address: getUserWalletAddress("DOGE", user),
      Icon: DogeIcon,
      nativeToken: BlockchainNativeToken.DOGE,
      getTxnScanUrl: (
        hash: string,
        mainnet: boolean = isProd
      ): `https://${string}` =>
        mainnet
          ? `https://dogechain.info/tx/${hash}`
          : `https://blockexplorer.one/dogecoin/testnet/blockHash/${hash}`,
    },
    ZORA: {
      name: BlockchainFullNames.ZORA,
      address: "",
      Icon: ZoraIcon,
      chainId: isProd ? zora.id : zoraSepolia.id,
      nativeToken: BlockchainNativeToken.ETH,
      getTxnScanUrl: (
        hash: string,
        mainnet: boolean = isProd
      ): `https://${string}` =>
        mainnet
          ? `https://explorer.zora.energy/tx/${hash}`
          : `https://testnet.explorer.zora.energy/tx/${hash}`,
    },
    BASE: {
      name: BlockchainFullNames.BASE,
      address: "",
      Icon: BaseIcon,
      chainId: isProd ? base.id : baseSepolia.id,
      nativeToken: BlockchainNativeToken.BASE,
      getTxnScanUrl: (
        hash: string,
        mainnet: boolean = isProd
      ): `https://${string}` =>
        mainnet
          ? `https://basescan.org/tx/${hash}`
          : `https://sepolia.basescan.org/tx/${hash}`,
    },
  };
}

export function getSupportedChains(
  supportedAssets?: BeamSupportedZeroHashAsset[]
): Set<string> {
  const chains = new Set<string>();

  if (!supportedAssets) return chains;

  supportedAssets.forEach((asset) => {
    const [token, chain] = asset.split(".");
    chains.add(chain ?? token);
  });

  return chains;
}

export function mapChainsToSupportedAssets(
  supportedAssets?: BeamSupportedZeroHashAsset[]
): {
  [key: string]: Set<string>;
} {
  if (!supportedAssets) return {};

  const mapping = {};

  supportedAssets.forEach((asset) => {
    const [token, chain] = asset.split(".");

    if (chain && !mapping[chain]) {
      mapping[chain] = new Set();
    } else if (!chain && token && !mapping[token]) {
      mapping[token] = new Set();
    }

    if (!chain) {
      mapping[token].add(token); // eg. ETH
    } else {
      mapping[chain].add(token); // eg. USDC.ETH
    }
  });

  return mapping;
}

export type GetNetworkOptionsParams = {
  user?: User | null;
  supportedAssets?: BeamSupportedZeroHashAsset[];
};

export function getNetworkOptions(params: GetNetworkOptionsParams): {
  value: string;
  label: string;
  Icon: React.FunctionComponent;
}[] {
  const { user, supportedAssets } = params;

  const chainConfig = getChainConfig(user);
  if (!chainConfig) return [];

  const supportedChains = getSupportedChains(supportedAssets);

  return Array.from(supportedChains).map((option: string) => {
    return {
      value: option,
      label: chainConfig[option]?.name,
      Icon: chainConfig[option]?.Icon,
    };
  });
}

// with no chain information
// eg. ETH, USDC, BTC, ...
export function getAssetOptions(assets?: Set<string>) {
  if (!assets) return [];

  return Array.from(assets)
    .map((asset) => {
      return {
        value: asset,
        label: asset,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
}
