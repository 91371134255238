import { CallError, httpClient } from "./httpClient";
import { AsyncResult } from "../models/result";

const postTesterCommand = async (
  commandName: string,
  payload: any
): AsyncResult<null, CallError> => {
  return await httpClient.post(`admin/testerCommand/${commandName}`, payload);
};

export const adminApi = {
  testerCommands: {
    resetUserAccount: async () =>
      await postTesterCommand("ResetUserAccountTesterCommand", {}),
    initiatePayout: async (amount: number) =>
      await postTesterCommand("InitiatePayoutTesterCommand", { amount }),
  },
};
