import Joi from "joi";
import { Result } from "../models/result";
import { httpClient } from "./httpClient";
import { BeamSupportedZeroHashAsset } from "../types";

const supportedAssetsDto: Joi.ObjectSchema<{
  data: BeamSupportedZeroHashAsset[];
}> = Joi.object({
  data: Joi.array().items(Joi.string()),
});

async function getSupportedAssets(): Promise<BeamSupportedZeroHashAsset[]> {
  const res = await httpClient.getJson<{ data: BeamSupportedZeroHashAsset[] }>(
    `digitalAssets/supportedAssets`,
    supportedAssetsDto
  );
  const res2 = Result.getOrNull(res);

  return res2?.data ?? [];
}

export const assetsApi = {
  getSupportedAssets,
};
