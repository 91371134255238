import { OnboardingInfo } from "../models/onboarding";
import { BACKEND_URL } from "../config";

const getOnboardingInfo = async (): Promise<OnboardingInfo> => {
  const response = await fetch(`${BACKEND_URL}/onboarding/info`, {
    credentials: "include",
  });
  return await response.json();
};

const patchOnboardingInfo = async (info: Partial<OnboardingInfo>) => {
  return await fetch(`${BACKEND_URL}/onboarding/info`, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(info),
    credentials: "include",
  });
};

const getAddressRiskScore = async () => {
  const response = await fetch(`${BACKEND_URL}/onboarding/addressRiskScore`, {
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });
  return await response.json();
};

export const onboardingApi = {
  getOnboardingInfo,
  patchOnboardingInfo,
  getAddressRiskScore,
};
