import * as Yup from "yup";
import { useFormik } from "formik";
import Web3 from "web3";
import { Grid, Typography, TextField, Button } from "@mui/material";
import * as WAValidator from "multicoin-address-validator";
import { useEffect, useState } from "react";

import { isValidEthAddress } from "../../../utils/ethAddress";
import { isValidSolAddress } from "../../../utils/solAddress";
import useFeatureFlag from "../../../hooks/useFeatureFlag";
import { BlockchainAccountType } from "../../../types";
import { Loading } from "../../../components/Loading";
import { usersApi } from "../../../api";
import { useCookies } from "react-cookie";

const validationSchema = Yup.object({
  walletAddress: Yup.string()
    .test(
      "isAddressValid",
      "Please enter a valid ALGO, ETH or SOL address",
      async (value = "") => {
        return (
          (await isValidEthAddress(value.trim())) ||
          isValidSolAddress(value.trim()) ||
          WAValidator.validate(value.trim(), "algo")
        );
      }
    )
    .required(),
  accountType: Yup.mixed<BlockchainAccountType>()
    .oneOf(Object.values(BlockchainAccountType))
    .required(),
});

export const WalletAddress = ({ onComplete }: { onComplete: () => void }) => {
  const walletPromptTitle = String(
    useFeatureFlag(
      "onboarding-wallet-prompt-title",
      "Enter your wallet address"
    )
  );
  const walletPromptDetails = String(
    useFeatureFlag(
      "onboarding-wallet-prompt-details",
      "Providing us with your crypto wallet address helps keep malicious activity off of our platform."
    )
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<null | string>(null);
  const [cookies] = useCookies(["beam-wallet"]);
  console.log(errorMsg);
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      walletAddress: "",
      accountType: BlockchainAccountType.ETH,
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      let { walletAddress, accountType } = values;
      // check if ens and if so get the address
      if (values.walletAddress.includes(".eth")) {
        try {
          walletAddress = (await new Web3(window.ethereum).eth.ens.getAddress(
            walletAddress
          )) as string;
          accountType = BlockchainAccountType.ETH;
        } catch (error) {
          setErrorMsg(
            `Unable to get eth address from ens ${values.walletAddress}`
          );
          setLoading(false);
          return;
        }
      }
      if (isValidSolAddress(values.walletAddress)) {
        accountType = BlockchainAccountType.SOL;
      }
      if (WAValidator.validate(values.walletAddress.trim(), "algo")) {
        accountType = BlockchainAccountType.ALGO;
      }
      const response = await usersApi.updateWalletAddress(
        walletAddress,
        accountType
      );
      if (response.isOk) {
        setLoading(false);
        onComplete();
      } else {
        const error = response.error as any;
        setErrorMsg(error.message);
        setLoading(false);
      }
    },
  });

  // useeffect to check cookies for beam-wallet
  // if it exists, set the value in the formik
  // and submit the form
  useEffect(() => {
    const wallet = cookies["beam-wallet"];
    if (wallet) {
      formik.setFieldValue("walletAddress", wallet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading />;

  return (
    <Grid
      container
      columns={12}
      alignItems="center"
      justifyItems="center"
      direction="column"
    >
      <Grid item alignItems="center" justifyContent="center">
        <Typography variant="h5" align="center" sx={{ mt: 14, mb: 1 }}>
          {walletPromptTitle}
        </Typography>
        <Typography variant="body1" align="center">
          {walletPromptDetails}
        </Typography>
        <TextField
          fullWidth
          sx={{ mt: 4 }}
          label="Wallet address"
          onChange={(e) => {
            formik.setFieldValue("walletAddress", e.target.value);
          }}
          value={formik.values.walletAddress}
          error={!!(formik.values.walletAddress && formik.errors.walletAddress)}
          helperText={
            formik.values.walletAddress && formik.errors.walletAddress
              ? formik.errors.walletAddress
              : " "
          }
        />
        <Button
          sx={{ mt: 3 }}
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          onClick={() => formik.handleSubmit()}
          disabled={!formik.isValid || loading}
        >
          Continue
        </Button>
      </Grid>
    </Grid>
  );
};
