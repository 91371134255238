import axios from "axios";

import { BACKEND_URL } from "../config";
import { logout } from "./utils";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = BACKEND_URL;

axiosClient.interceptors.request.use(
  function (config) {
    // Get the bearer token from localStorage with the name "accessToken"
    const accessToken = localStorage.getItem("accessToken");

    // Add the bearer token to the request headers
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    // Set withCredentials to true for all requests
    config.withCredentials = true;

    return config;
  },
  null,
  { synchronous: true }
);

axiosClient.interceptors.response.use(
  function (response) {
    if (response?.status === 401) {
      logout();
    }
    return response;
  },
  null,
  { synchronous: true }
);

export function getRequest(url: string) {
  return axiosClient.get(`/${url}`).then((response) => response);
}

export function postRequest(url: string, payload: any) {
  return axiosClient.post(`/${url}`, payload).then((response) => response);
}

export function patchRequest(url: string, payload: any) {
  return axiosClient.patch(`/${url}`, payload).then((response) => response);
}

export function deleteRequest(url: string) {
  return axiosClient.delete(`/${URL}`).then((response) => response);
}
