import { useState, useEffect } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

// must be used inside component wrapped with withLDConsumer
export default function useFeatureFlag<T>(flagKey: string, defaultValue: T): T {
  const [value, setValue] = useState(defaultValue);
  const ldFlags = useFlags();
  let ldFlagValue = ldFlags?.[flagKey];

  useEffect(() => {
    if (!(ldFlagValue === null || ldFlagValue === undefined)) {
      setValue(ldFlagValue);
    } else {
      setValue(defaultValue);
    }
  }, [ldFlagValue, defaultValue]);

  return value;
}
