import { useRef, useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import "./Header.scss";
import AnsibleBeamLogoBlack from "../../assets/images/beam_logo.png";
import { usersApi } from "../../api";
import { User } from "../../models/user";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PopoverMenu from "../Menu/Menu";
import { UserFeedback } from "../UserFeedback/UserFeedback";

const MOBILE_BREAKPOINT = 515;

export const Header = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const iconButtonRef = useRef(null);
  const showMenuView = screenWidth <= MOBILE_BREAKPOINT;

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // did not use useQuery because it was making repeated calls with no changes
  useEffect(() => {
    usersApi
      .getUserAccountById()
      .then((res) => {
        if (res.isOk && res.value) setUser(res.value);
      })
      .catch((error) => {});

    return () => {
      setUser(null);
    };
  }, []);

  const handleAvatarClick = () => {
    if (showMenuView) {
      navigate("/menu");
    } else {
      setMenuOpen(!menuOpen);
    }
  };

  return (
    <nav>
      <img src={AnsibleBeamLogoBlack} alt="Beam Logo" width={80} />
      {!!user && <UserFeedback user={user} />}
      {!!user && (
        <IconButton
          ref={iconButtonRef}
          onClick={handleAvatarClick}
          size="small"
          sx={{ ml: 2 }}
        >
          <Avatar sx={{ width: 40, height: 40 }}>
            <Typography>
              {user.firstName?.[0]?.toUpperCase() || "B"}
              {user.lastName?.[0]?.toUpperCase() || ""}
            </Typography>
          </Avatar>
        </IconButton>
      )}
      {iconButtonRef?.current && (
        <PopoverMenu
          anchorRef={iconButtonRef}
          open={menuOpen}
          user={user}
          fullPage={showMenuView}
          transformOrigin={{ horizontal: 156, vertical: "top" }}
          onClose={() => {
            setMenuOpen(false);
          }}
        />
      )}
    </nav>
  );
};
