import React, { useEffect, useState } from "react";
import { Magic, MagicUserMetadata } from "magic-sdk";
import { OAuthExtension } from "@magic-ext/oauth";
import { MAGIC_AUTH_PUBLIC_KEY } from "../config";

const magic = new Magic(MAGIC_AUTH_PUBLIC_KEY, {
  extensions: [new OAuthExtension()],
});

export type UserId = string | undefined;
export type AccountAddress = string | undefined;

export type AuthContextState = {
  user: MagicUserMetadata | null;
  setUser: (user: MagicUserMetadata) => void;
};

export const AuthContext = React.createContext<AuthContextState>({
  user: null,
  setUser: (_user: MagicUserMetadata) => undefined,
});

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [user, setUser] = useState<MagicUserMetadata | null>(null);

  useEffect(() => {
    magic.user.isLoggedIn().then((isLoggedIn) => {
      isLoggedIn
        ? magic.user.getMetadata().then((res) => setUser(res))
        : setUser(null);
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
