export type ErrorResult<E = Error> = { isOk: false; error: E };
export type SuccessResult<T> = { isOk: true; value: T };
export type Result<T, E = Error> = SuccessResult<T> | ErrorResult<E>;

export type AsyncResult<T, E = Error> = Promise<Result<T, E>>;
export type Mapping<A, B> = (value: A) => B;
export type Consumer<A> = (value: A) => void;

const ok = <T>(value: T): SuccessResult<T> => ({ isOk: true, value });
const err = <E>(error: E): ErrorResult<E> => ({ isOk: false, error });
const isOk = <T, E>(result: Result<T, E>): result is SuccessResult<T> => {
  return result.isOk;
};
const isErr = <T, E>(result: Result<T, E>): result is ErrorResult<E> => {
  return !result.isOk;
};
const mapOk = <T, E, NewT>(
  mapping: Mapping<T, NewT>,
  r: Result<T, E>
): Result<NewT, E> =>
  Result.isOk(r) ? Result.ok(mapping(r.value)) : Result.err(r.error);
const mapErr = <T, E, NewE>(
  mapping: Mapping<E, NewE>,
  r: Result<T, E>
): Result<T, NewE> =>
  Result.isOk(r) ? Result.ok(r.value) : Result.err(mapping(r.error));
const onSuccess = <T, E>(consumer: Consumer<T>, r: Result<T, E>): void => {
  if (Result.isOk(r)) {
    consumer(r.value);
  }
};
const onError = <T, E>(consumer: Consumer<E>, r: Result<T, E>): void => {
  if (Result.isErr(r)) {
    consumer(r.error);
  }
};
const getOrNull = <T, E>(r: Result<T, E>): T | null =>
  Result.isOk(r) ? r.value : null;
const getOrUndefined = <T, E>(r: Result<T, E>): T | undefined =>
  Result.isOk(r) ? r.value : undefined;
const getOrThrow = <T, E>(r: Result<T, E>): T | never => {
  if (Result.isOk(r)) {
    return r.value;
  } else {
    throw new Error(`Failed with error: ${r.error}`);
  }
};
const getErrorOrThrow = <T, E>(r: Result<T, E>): E | never => {
  if (Result.isErr(r)) {
    return r.error;
  } else {
    throw new Error(
      `Expected an error result but got a success result with value ${r.value}`
    );
  }
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Result = {
  ok,
  err,
  isOk,
  isErr,
  mapOk,
  mapErr,
  onSuccess,
  onError,
  getOrNull,
  getOrUndefined,
  getOrThrow,
  getErrorOrThrow,
};
