import type { User } from "../models/user";
import type { BeamSupportedZeroHashAsset } from "../types";

export function getUserWalletAddress(
  asset: BeamSupportedZeroHashAsset,
  user?: User | null
): string {
  if (!user) return "";
  const walletAddress = user.walletAddresses?.find(
    (address) => asset === address.asset
  )?.address;
  if (!walletAddress) return "";
  return walletAddress;
}
