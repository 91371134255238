// keys/values based on what zerohash uses
// https://zerohash.zendesk.com/hc/en-us/articles/360050647533-What-assets-do-you-support-
export enum BlockchainAccountType {
  // most popular
  ETH = "ETH",
  BTC = "BTC",
  ALGO = "ALGO",

  // alphabetical
  ARBITRUM = "ARBITRUM",
  AVAX = "AVAX",
  DOGE = "DOGE",
  OPTIMISM = "OPTIMISM",
  POLYGON = "POLYGON",
  XRP = "XRP",
  SOL = "SOL",
  XLM = "XLM",

  // not supported by zerohash
  ZORA = "ZORA",
  BASE = "BASE",
}

export enum BlockchainFullNames {
  ETH = "Ethereum",
  AVAX = "Avalanche",
  SOL = "Solana",
  ARBITRUM = "Arbitrum",
  OPTIMISM = "Optimism",
  BTC = "Bitcoin",
  XLM = "Stellar Lumens",
  POLYGON = "Polygon",
  XRP = "Ripple",
  DOGE = "Dogecoin",
  ZORA = "Zora",
  BASE = "Base",
}

export enum BlockchainNativeToken {
  ETH = "ETH",
  POLYGON = "MATIC",
  SOL = "SOL",
  AVAX = "AVAX",
  XLM = "XLM",
  BTC = "BTC",
  ARBITRUM = "ETH",
  OPTIMISM = "ETH",
  XRP = "XRP",
  DOGE = "DOGE",
  BASE = "ETH",
  ZORA = "ETH",
}

// https://seedcx.zendesk.com/hc/en-us/articles/360050647533-What-assets-do-you-support-
export const BEAM_SUPPORTED_ZEROHASH_ASSETS = [
  "ETH",
  "USDC.ETH",
  "USDC.POLYGON",
  "MATIC.POLYGON",
  "SOL",
  "USDC.SOL",
  "AVAX",
  "USDC.AVAX",
  "XLM",
  "USDC.XLM",
  "BTC",
  "USDC.ARBITRUM",
  "USDC.OPTIMISM",
  "XRP",
  "DOGE",
  "ETH.ARBITRUM",
  "ETH.OPTIMISM",
  // "ETH.BASE",
  // "USDC.BASE",
] as const;

export type BeamSupportedZeroHashAsset =
  typeof BEAM_SUPPORTED_ZEROHASH_ASSETS[number];

export type ChainConfig = {
  [key: string]: {
    name: string;
    address: string;
    Icon: React.FunctionComponent;
    chainId?: number;
    nativeToken: string;
    getTxnScanUrl?: (
      hash: string,
      mainnet?: boolean
    ) => `https://${string}` | `http://${string}`;
  };
};

export type TxnHash = {
  hash: string;
  scanUrl?: `https://${string}` | `http://${string}` | "";
};

export type PersonaInquiryOptionsFields = {
  email_address: string;
  trm_wallet_risk_score: number;
};

export function getPersonaInquiryOptionsFields(params: {
  emailAddress: string;
  trmWalletRiskScore: number;
}): PersonaInquiryOptionsFields {
  return {
    email_address: params.emailAddress,
    trm_wallet_risk_score: params.trmWalletRiskScore,
  };
}
