import { BeamSupportedZeroHashAsset } from "../types";
import * as config from "../config/chains";
import { isProd as isProdEnv } from "../utils";

const isProd = isProdEnv();

export function getChainFromZeroHashAsset(asset: BeamSupportedZeroHashAsset) {
  const parts = asset.split(".");
  return parts[1] || parts[0];
}

// only applies to EVM chains
export function getChainId(asset: BeamSupportedZeroHashAsset): number {
  const chain = getChainFromZeroHashAsset(asset);
  return isProd
    ? config.MAINNET_CHAINID?.[chain]
    : config.TESTNET_CHAINID?.[chain];
}

// only applies to EVM chains
export function getChainParams(asset: BeamSupportedZeroHashAsset) {
  const chain = getChainFromZeroHashAsset(asset);
  return isProd ? config.MAINNET?.[chain] : config.TESTNET?.[chain];
}

export function isEVM(asset: BeamSupportedZeroHashAsset) {
  const chain = getChainFromZeroHashAsset(asset);
  const chainConfig = isProd
    ? config.MAINNET?.[chain]
    : config.TESTNET?.[chain];
  return !!chainConfig;
}

export function isSolana(asset: BeamSupportedZeroHashAsset) {
  return getChainFromZeroHashAsset(asset) === "SOL";
}

export function isSPLToken(asset: BeamSupportedZeroHashAsset) {
  return getChainFromZeroHashAsset(asset) === "SOL" && asset !== "SOL";
}

export function getSPLTokenId(token: string) {
  try {
    return isProd
      ? config.MAINNET_SPL_TOKEN_ID?.[token]
      : config.DEVNET_SPL_TOKEN_ID?.[token];
  } catch (err) {
    throw err;
  }
}
