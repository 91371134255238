const isProd = () => {
  let env = process.env["NODE_ENV"].toLowerCase();
  env = env.replace("_fargate", "");
  return env === "prod" || env === "production";
};

const isDev = () => {
  let env = process.env["NODE_ENV"].toLowerCase();
  env = env.replace("_fargate", "");
  return env === "dev" || env === "development";
};

const isSandbox = () => {
  let env = process.env["NODE_ENV"].toLowerCase();
  env = env.replace("_fargate", "");
  return env === "sandbox";
};

const isPentest = () => {
  let env = process.env["NODE_ENV"].toLowerCase();
  env = env.replace("_fargate", "");
  return env === "pentest";
};

const isLocal = () => {
  let env = process.env["NODE_ENV"].toLowerCase();
  env = env.replace("_fargate", "");
  return env === "local";
};

const isTest = () => {
  let env = process.env["NODE_ENV"].toLowerCase();
  env = env.replace("_fargate", "");
  return env === "test";
};

const isFargate = () => {
  return process.env["NODE_ENV"].toLowerCase().includes("fargate");
};

export { isProd, isDev, isLocal, isPentest, isSandbox, isTest, isFargate };
