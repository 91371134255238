import { useState, useEffect } from "react";
import { Magic } from "magic-sdk";
import { OAuthExtension } from "@magic-ext/oauth";
import { useNavigate, useSearchParams } from "react-router-dom";
import get from "lodash/get";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { Button, Grid, Typography, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import { ReactComponent as GoogleIcon } from "../../assets/icons/logo_google.svg";
import { MAGIC_AUTH_PUBLIC_KEY } from "../../config";
import { usersApi } from "../../api";
import { saveSessionMetadata } from "../../utils/tokenRepository";
import { isValidEmail } from "../../utils/email";
import { Result } from "../../models/result";
import { Loading } from "../../components/Loading";
// import { Loading } from "../shared/loading/loading";
import "./styles.scss";

const emailValidationSchema = Yup.object({
  emailAddress: Yup.string()
    .test(
      "isAddressValid",
      "Please enter a valid email address",
      (value = "") => {
        return isValidEmail(value);
      }
    )
    .required(),
});

const magic = new Magic(MAGIC_AUTH_PUBLIC_KEY, {
  extensions: [new OAuthExtension()],
});

const _Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [, setErrorMessage] = useState<null | string>(null);

  const socialProvider = searchParams.get("provider");
  const [processing, setProcessing] = useState<boolean>(false);

  const handleLoginWithEmail = async () => {
    setProcessing(true);

    try {
      // Trigger Magic link to be sent to user
      const didToken = await magic.auth.loginWithMagicLink({
        email: formik.values.emailAddress,
      });

      const userMetadata = await magic.user.getMetadata();

      // get and save the JWT
      const resp = await usersApi.login({
        magicAuthId: userMetadata?.publicAddress || "",
        magicAuthToken: didToken || "",
        email: formik.values.emailAddress,
        emailVerified: false,
      });

      const jwt = Result.getOrThrow(resp);

      await saveSessionMetadata(jwt);
      navigate("/onboarding");
    } catch (error: any) {
      setErrorMessage(error.message as string);
      setProcessing(false);
    }
  };

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      emailAddress: "",
    },
    validationSchema: emailValidationSchema,
    onSubmit: handleLoginWithEmail,
  });

  const googleSignIn = async () => {
    await magic.oauth.loginWithRedirect({
      provider: "google" /* 'google', 'facebook', 'apple', or 'github' */,
      redirectURI: `${window.location.origin}/login`,
    });
    // console.log(await magic.oauth.getRedirectResult());
  };

  useEffect(() => {
    const handleSocialSignin = async () => {
      // get successful login results
      setProcessing(true);
      const result = await magic.oauth.getRedirectResult();

      // login to Ansible to receive JWT (magic token is reverified on server)
      const metadata = get(result, ["magic", "userMetadata"], null);
      const magicAuthId = String(get(metadata, ["publicAddress"], ""));
      const email = String(get(metadata, ["email"], ""));
      const emailVerified = Boolean(
        get(result, ["oauth", "userInfo", "emailVerified"], false)
      );

      // get and save the JWT
      try {
        const jwt = await usersApi.login({
          magicAuthId: magicAuthId,
          magicAuthToken: get(result, ["magic", "idToken"], ""),
          email,
          emailVerified,
        });
        await saveSessionMetadata(jwt?.value);
        navigate("/onboarding");
      } catch (error: any) {
        setErrorMessage(error.message as string);
        setProcessing(false);
      }
    };
    if (socialProvider) {
      handleSocialSignin();
    }
  }, [socialProvider, navigate]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && formik.isValid) {
      formik.handleSubmit();
    }
  };

  if (processing) return <Loading />;

  return (
    <>
      {processing && <Loading />}
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <Typography variant="h5" align="center" sx={{ mt: 14 }}>
          Welcome to Beam
        </Typography>
      </Grid>
      <Grid item width="100%">
        <Button
          variant="outlined"
          color="black"
          size="large"
          sx={{ mt: 3 }}
          fullWidth
          onClick={googleSignIn}
        >
          <GoogleIcon style={{ marginRight: 8 }} /> Sign In with google
        </Button>
        <Typography variant="body1" align="center" sx={{ my: 2 }}>
          or
        </Typography>
        <TextField
          fullWidth
          InputLabelProps={{
            classes: {
              root: "login-email-label",
              focused: "login-email-label-focused",
            },
          }}
          InputProps={{
            classes: { root: "login-email-input" },
          }}
          sx={{ mb: 3 }}
          label="Email"
          onChange={(e) => formik.setFieldValue("emailAddress", e.target.value)}
          onKeyDown={handleKeyDown}
          value={formik.values.emailAddress}
          error={!!(formik.touched.emailAddress && formik.errors.emailAddress)}
          size="small"
        />
        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          onClick={() => formik.handleSubmit()}
          disabled={!formik.isValid}
        >
          Login
        </Button>
      </Grid>
    </>
  );
};

export const Login = withLDConsumer()(_Login);
