import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import CreditCardFilledIcon from "@mui/icons-material/CreditCard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useFeatureFlag from "../../../hooks/useFeatureFlag";
import { Button, Grid, Typography, Divider, Radio } from "@mui/material";
import { P2C } from "./P2C";
import { BankSelection } from "./BankSelection";
import "./Payment.scss";

enum PaymentTypes {
  BANK = "BANK",
  P2C = "P2C",
}

const _Payment = ({
  onComplete,
}: {
  onComplete?: (publicToken?: string) => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isPaymentView = location?.pathname === "/payment";
  const [payoutSelection, setPayoutSelection] = useState<PaymentTypes | null>(
    null
  );
  const [selectedType, setSelectedType] = useState<PaymentTypes | null>(null);
  const p2cFlagEnabled = Boolean(useFeatureFlag("p2c-input", false));
  const p2cEnabled =
    Boolean(localStorage.getItem("enable-p2c")) || p2cFlagEnabled;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedType(event.target.value as PaymentTypes);
  };

  const resetSelection = () => {
    setSelectedType(null);
    setPayoutSelection(null);
  };

  const viewOnComplete = () => {
    navigate("/bank-info");
  };

  const handleComplete = (publicToken?: string) => {
    if (isPaymentView) return viewOnComplete();
    if (onComplete) return onComplete(publicToken);
  };

  // show login form
  return (
    <>
      {payoutSelection === null && (
        <>
          {isPaymentView && (
            <Grid item classes={{ root: "menu-back-row" }} sx={{ mt: 3 }}>
              <IconButton
                classes={{ root: "menu-back-btn" }}
                onClick={() => navigate("/bank-info")}
              >
                <ArrowBackIcon fontSize="medium" />
              </IconButton>
              <Typography variant="body1" classes={{ root: "menu-back-text" }}>
                Back
              </Typography>
            </Grid>
          )}
          <Typography variant="h6" align="center" sx={{ mt: 3 }}>
            Where would you like your payout?
          </Typography>
          <Typography variant="body2" align="center" sx={{ mt: 1 }}>
            We're excited to help you get your cash with the lowest fees
          </Typography>
          <Grid
            container
            width="100%"
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Typography variant="subtitle2" align="center" sx={{ mt: 4 }}>
              Select a payout method
            </Typography>
          </Grid>
          <Divider style={{ width: "100%" }} sx={{ mt: 1 }} />
          <Grid width="100%">
            <Grid
              width="100%"
              container
              direction="row"
              alignItems="flex-start"
              sx={{ mt: 3 }}
            >
              <CreditCardFilledIcon
                style={{ color: "#01190F", opacity: 0.6 }}
              />
              <Grid item alignItems="flex-start" sx={{ ml: 2 }}>
                <Typography variant="subtitle2">
                  {p2cEnabled ? "Debit card" : "Card (COMING SOON)"}
                </Typography>
                <Typography variant="caption" style={{ display: "block" }}>
                  Faster payout time
                </Typography>
                <Typography variant="caption">
                  Now accepting Visa and Mastercard debit cards
                </Typography>
              </Grid>
              <Radio
                size="medium"
                name="radio-buttons"
                value={PaymentTypes.P2C}
                style={{ alignSelf: "center", marginLeft: "auto" }}
                checked={selectedType === PaymentTypes.P2C}
                onChange={handleChange}
                disabled={!p2cEnabled}
              />
            </Grid>
            <Grid
              width="100%"
              container
              direction="row"
              alignItems="flex-start"
              sx={{ mt: 3 }}
            >
              <AccountBalanceIcon style={{ color: "#01190F", opacity: 0.6 }} />
              <Grid item alignItems="flex-start" sx={{ ml: 2 }}>
                <Typography variant="subtitle2">Bank account</Typography>
                <Typography variant="caption" style={{ display: "block" }}>
                  Payout time can vary (Instant to 3 days)
                </Typography>
                <Typography variant="caption">
                  Time dependent on your bank
                </Typography>
              </Grid>
              <Radio
                size="medium"
                name="radio-buttons"
                value={PaymentTypes.BANK}
                style={{ alignSelf: "center", marginLeft: "auto" }}
                checked={selectedType === PaymentTypes.BANK}
                onChange={handleChange}
              />
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              sx={{ mt: 4 }}
              fullWidth
              onClick={() => setPayoutSelection(selectedType)}
            >
              add payout details
            </Button>
          </Grid>
        </>
      )}
      {payoutSelection === PaymentTypes.P2C && (
        <P2C onComplete={handleComplete} goBack={resetSelection} />
      )}
      {payoutSelection === PaymentTypes.BANK && (
        <BankSelection onComplete={handleComplete} goBack={resetSelection} />
      )}
    </>
  );
};

export const Payment = withLDConsumer()(_Payment);
