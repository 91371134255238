import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { usersApi, userAccountQueryKey } from "../../api";
import { Result } from "../../models/result";
import "./Profile.scss";

function Profile() {
  const { data: userResult } = useQuery(userAccountQueryKey, async () => {
    return await usersApi.getUserAccountById();
  });
  const user = userResult && Result.getOrNull(userResult);
  const navigate = useNavigate();

  return (
    <Grid container direction={"column"} classes={{ root: "profile-view" }}>
      <Grid item classes={{ root: "menu-back-row" }}>
        <IconButton
          classes={{ root: "menu-back-btn" }}
          onClick={() => navigate("/dashboard")}
        >
          <ArrowBackIcon fontSize="medium" />
        </IconButton>
        <Typography variant="body1" classes={{ root: "menu-back-text" }}>
          Back
        </Typography>
      </Grid>
      <Grid item direction={"column"} classes={{ root: "menu" }}>
        <Grid item classes={{ root: "profile-subtitle" }}>
          <Typography>Profile</Typography>
        </Grid>
        <Divider classes={{ root: "menu-divider" }} />
        <Grid
          item
          direction={"column"}
          classes={{ root: "profile-content-container" }}
        >
          <Typography variant="body1" classes={{ root: "profile-label" }}>
            First Name
          </Typography>
          <Typography variant="body1">{user?.firstName}</Typography>
          <Typography
            variant="body1"
            sx={{ mt: 1.5 }}
            classes={{ root: "profile-label" }}
          >
            Last Name
          </Typography>
          <Typography variant="body1">{user?.lastName}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Profile;
