import "./polyfill";
import React from "react";
import ReactDOM from "react-dom/client";
import "@fontsource/manrope/300.css";
import "@fontsource/manrope/400.css";
import "@fontsource/manrope/500.css";
import "@fontsource/manrope/700.css";
import "./index.scss";
import "@solana/wallet-adapter-react-ui/styles.css";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { Grid } from "@mui/material";
import {
  ConnectionProvider as SolConnectionProvider,
  WalletProvider as SolWalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { UnsafeBurnerWalletAdapter } from "@solana/wallet-adapter-wallets";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";
import App from "./App";
import {
  validateConfig,
  LAUNCH_DARKLY_CLIENT_ID,
  SOLANA_RPC_ENDPOINT,
  DECENT_API_KEY,
} from "./config";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "react-tooltip/dist/react-tooltip.css";

import { Dashboard } from "./views/Dashboard";
import Profile from "./views/Profile/Profile";
import Settings from "./views/Settings/Settings";
import BankInfo from "./views/BankInfo/BankInfo";
import BankDetails from "./views/BankDetails/BankDetails";
import Menu from "./views/Menu/Menu";
import { Payment } from "./views/Onboarding/Payment/Payment";
import { Transactions } from "./views/Transactions/Transactions";
import {
  createTheme,
  PaletteColorOptions,
  ThemeProvider,
} from "@mui/material/styles";
import { Login } from "./views/Login/Login";
import { Onboarding } from "./views/Onboarding/Onboarding";

import { Embedded } from "./views/Embedded/Embedded";

import ViewWrapper from "./views/ViewWrapper";

import { SkyflowElements } from "skyflow-react-js";
import skyflowConfig from "./config/skyflow";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import {
  arbitrum,
  arbitrumSepolia,
  mainnet,
  sepolia,
  polygon,
  polygonMumbai,
  avalanche,
  avalancheFuji,
  optimism,
  optimismSepolia,
  zora,
  zoraSepolia,
  zoraTestnet,
  base,
  baseSepolia,
} from "viem/chains";
import { BoxHooksContextProvider } from "@decent.xyz/box-hooks";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

validateConfig();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

Sentry.init({
  dsn: "https://4ff235b03e9e42faa66c7a71c78c3dc5@o4504360915828736.ingest.sentry.io/4504533247721472",
  environment: process.env.NODE_ENV,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

// web3modal config
const chains = [
  arbitrum,
  mainnet,
  sepolia,
  polygon,
  polygonMumbai,
  avalanche,
  avalancheFuji,
  optimism,
  arbitrumSepolia,
  optimismSepolia,
  zora,
  zoraTestnet,
  zoraSepolia,
  base,
  baseSepolia,
];
const walletConnectProjectId = "6f107698fc9c242c62976a85761c0ad1";
const metadata = {
  name: "Beam",
  description: "",
  url: "",
  icons: ["./assets/images/beam_logo.png"],
};
const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId: walletConnectProjectId,
  metadata,
  enableCoinbase: true,
  enableInjected: true,
  enableEIP6963: true,
  enableWalletConnect: true,
});
createWeb3Modal({ wagmiConfig, projectId: walletConnectProjectId, chains });

// solana web3 config
// The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
const network =
  process.env?.NODE_ENV === "production"
    ? WalletAdapterNetwork.Mainnet
    : WalletAdapterNetwork.Devnet;
const endpoint =
  process.env?.NODE_ENV === "production"
    ? SOLANA_RPC_ENDPOINT
    : clusterApiUrl(network);
const solWallets = [
  /**
   * Wallets that implement either of these standards will be available automatically.
   *
   *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
   *     (https://github.com/solana-mobile/mobile-wallet-adapter)
   *   - Solana Wallet Standard
   *     (https://github.com/solana-labs/wallet-standard)
   *
   * If you wish to support a wallet that supports neither of those standards,
   * instantiate its legacy wallet adapter here. Common legacy adapters can be found
   * in the npm package `@solana/wallet-adapter-wallets`.
   */
  new UnsafeBurnerWalletAdapter(),
];

// mui config
const theme = createTheme({
  typography: {
    fontFamily: "Manrope",
    h1: {
      fontWeight: 300,
      fontSize: 96,
      color: "#01190F",
    },
    h2: {
      fontWeight: 300,
      fontSize: 60,
      color: "#01190F",
    },
    h3: {
      fontWeight: 300,
      fontSize: 40,
      color: "#01190F",
    },
    h4: {
      fontWeight: 400,
      fontSize: 34,
      color: "#01190F",
    },
    h5: {
      fontWeight: 500,
      fontSize: 24,
      color: "#01190F",
    },
    h6: {
      fontWeight: 500,
      fontSize: 20,
      color: "#01190F",
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 16,
      color: "#01190F",
    },
    subtitle2: {
      color: "#01190F",
      fontWeight: 500,
      fontSize: 14,
    },
    body1: {
      color: "#01190F",
      fontWeight: 400,
      fontSize: 16,
    },
    body2: {
      color: "#01190F",
      fontWeight: 400,
      fontSize: 14,
    },
    caption: {
      color: "#01190F",
      fontWeight: 400,
    },
  },
  palette: {
    text: {
      primary: "#01190F 12%",
      secondary: "#01190F 60%",
      disabled: "#01190F 12%",
    },
    black: createColor("#000000"),
    primary: {
      main: "#20C563",
      dark: "#1A9E4F",
      light: "#79DCA1",
      contrastText: "#01190F",
    },
    secondary: {
      main: "#84FAB3",
      dark: "#6AD996",
      light: "#B5FCD1",
      contrastText: "#01190F",
    },
    error: {
      main: "#D32F2F",
      dark: "#8A222A",
      light: "#F08890",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#ED6C02",
      dark: "#E65100",
      light: "#FF9800",
      contrastText: "#01190F",
    },
  },
});

declare module "@mui/material/styles" {
  interface CustomPalette {
    black: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    black: true;
  }
}

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LAUNCH_DARKLY_CLIENT_ID,
    options: {
      streaming: true, // live updates
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });
  // const navigate = useNavigate();
  root.render(
    <React.Fragment>
      <SkyflowElements config={skyflowConfig}>
        <LDProvider>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <WagmiConfig config={wagmiConfig}>
                <WalletModalProvider>
                  <SolConnectionProvider endpoint={endpoint}>
                    <SolWalletProvider wallets={solWallets}>
                      <BrowserRouter>
                        <BoxHooksContextProvider apiKey={DECENT_API_KEY}>
                          <Grid
                            container
                            columns={12}
                            alignItems="center"
                            justifyItems="center"
                            direction="column"
                          >
                            <Grid
                              minWidth={300}
                              maxWidth={450}
                              width="100%"
                              sx={{ px: 2 }}
                            >
                              <Routes>
                                <Route path="/*" element={<App />}>
                                  <Route
                                    path="embedded"
                                    element={<Embedded />}
                                  />
                                  <Route path={"login"} element={<Login />} />
                                  <Route
                                    path={"onboarding"}
                                    element={<Onboarding />}
                                  />
                                  <Route
                                    path={"menu"}
                                    element={<ViewWrapper View={<Menu />} />}
                                  />
                                  <Route
                                    path={"settings"}
                                    element={
                                      <ViewWrapper View={<Settings />} />
                                    }
                                  />
                                  <Route
                                    path={"profile"}
                                    element={<ViewWrapper View={<Profile />} />}
                                  />
                                  <Route
                                    path={"bank-info"}
                                    element={
                                      <ViewWrapper View={<BankInfo />} />
                                    }
                                  />
                                  <Route
                                    path={"bank-details"}
                                    element={
                                      <ViewWrapper View={<BankDetails />} />
                                    }
                                  />
                                  <Route
                                    path={"dashboard"}
                                    element={
                                      <ViewWrapper View={<Dashboard />} />
                                    }
                                  />
                                  <Route
                                    path={"transactions"}
                                    element={
                                      <ViewWrapper View={<Transactions />} />
                                    }
                                  />
                                  <Route
                                    path={"payment"}
                                    element={<ViewWrapper View={<Payment />} />}
                                  />
                                  <Route
                                    path="*"
                                    element={<Navigate to="/login" />}
                                  />
                                </Route>
                              </Routes>
                            </Grid>
                          </Grid>
                        </BoxHooksContextProvider>
                      </BrowserRouter>
                    </SolWalletProvider>
                  </SolConnectionProvider>
                </WalletModalProvider>
              </WagmiConfig>
            </QueryClientProvider>
          </ThemeProvider>
        </LDProvider>
      </SkyflowElements>
    </React.Fragment>
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// deploy nonce abcd
reportWebVitals();
