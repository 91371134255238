import { useEffect, useState } from "react";
import { parseUnits } from "viem";
import { erc20ABI } from "@wagmi/core";
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  UsePrepareContractWriteConfig,
} from "wagmi";
import { User } from "../../models/user";
import { ChainConfig, TxnHash } from "../../types";
import { getUserWalletAddress } from "../../utils/getUserWalletAddress";
import { getZeroHashAssetFromTokenAndNetwork } from "../../utils/getZeroHashAssetFromTokenAndNetwork";
import { getAssetContractAddress } from "../../utils/getAssetContractAddress";

type Props = {
  chainId: number;
  amount: string;
  user: User;
  senderAddress: `0x${string}`;
  selectedCurrency: string;
  selectedNetwork: string;
  handleTransactionError: (error: any) => void;
  setTransactionHash: (hash: TxnHash) => void;
  chainConfig: ChainConfig;
};

export default function ERC20Transaction({
  chainId,
  amount, // in terms of selectedCurrency
  selectedCurrency,
  selectedNetwork,
  user,
  senderAddress,
  handleTransactionError,
  setTransactionHash,
  chainConfig,
}: Props) {
  const asset = getZeroHashAssetFromTokenAndNetwork(
    selectedCurrency,
    selectedNetwork
  );
  const contractAddress = getAssetContractAddress(
    selectedCurrency,
    selectedNetwork
  ) as `0x${string}`;
  const decimals =
    selectedCurrency === "USDC" || selectedCurrency === "USDT" ? 6 : 18; // todo: store decimal places in config or db
  const receiverAddress = getUserWalletAddress(asset, user) as `0x${string}`;

  const [txnInProgress, setTxnInProgress] = useState<boolean>(false);
  const params: UsePrepareContractWriteConfig = {
    chainId,
    address: contractAddress,
    abi: erc20ABI,
    functionName: "transfer",
    account: senderAddress,
    args: [receiverAddress, parseUnits(amount, decimals)],
  };

  const { config: configWrite, error: prepareContractWriteErr } =
    usePrepareContractWrite(params);

  const {
    data: writeData,
    write,
    error: contractWriteErr,
  } = useContractWrite(configWrite);

  const {
    isLoading,
    isSuccess,
    error: waitForTxnErr,
  } = useWaitForTransaction({
    hash: writeData?.hash, //transaction hash
  });

  useEffect(() => {
    if (write && !isLoading && !txnInProgress) {
      try {
        setTxnInProgress(true);
        write();
      } catch (error) {
        handleTransactionError(error);
      }
    }
  }, [write, isLoading, handleTransactionError, txnInProgress]);

  useEffect(() => {
    if (prepareContractWriteErr || contractWriteErr || waitForTxnErr) {
      handleTransactionError(
        prepareContractWriteErr ?? contractWriteErr ?? waitForTxnErr
      );
    }
  }, [
    prepareContractWriteErr,
    contractWriteErr,
    waitForTxnErr,
    handleTransactionError,
  ]);

  useEffect(() => {
    if (isSuccess && !!writeData?.hash) {
      setTransactionHash({
        hash: writeData.hash as `0x${string}`,
        scanUrl:
          chainConfig[selectedNetwork]?.getTxnScanUrl?.(writeData.hash) ??
          ("" as `https://${string}`),
      });
    }
  }, [
    isSuccess,
    setTransactionHash,
    writeData?.hash,
    chainConfig,
    selectedNetwork,
  ]);

  return <></>;
}
