import * as Yup from "yup";
import { useFormik } from "formik";

import { usersApi } from "../../../api";
import { useEffect, useState } from "react";

import { Grid, Typography, Button, TextField, Link } from "@mui/material";
import { Loading } from "../../../components/Loading";

const validationSchema = Yup.object({
  verificationCode: Yup.string().length(5).required(),
});

export const EmailVerification = ({
  onComplete,
}: {
  onComplete: () => void;
}) => {
  const [incorrectCode, setIncorrectCode] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [codeResent, setCodeResent] = useState<boolean>(false);
  console.info(codeResent);
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      verificationCode: "",
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      usersApi
        .verifyUserAccountEmailAddress(formik.values.verificationCode)
        .then((res) => {
          if (res.isOk && res?.value) {
            onComplete();
          } else {
            setIncorrectCode(true);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    },
  });

  useEffect(() => {
    setLoading(true);
    usersApi
      .getUserAccountById()
      .then((res) => {
        if (res.isOk && res.value.emailAddress) {
          setEmail(res?.value?.emailAddress);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  const resendCode = async () => {
    const response = await usersApi.resendVerificationCode();
    if (response.isOk) {
      setIncorrectCode(false);
      setCodeResent(true);
      setTimeout(() => {
        setCodeResent(false);
      }, 5000);
    }
  };

  return (
    <Grid
      container
      columns={12}
      alignItems="center"
      justifyItems="center"
      direction="column"
    >
      {loading && <Loading />}
      <Grid item alignItems="center" justifyContent="center">
        <Typography variant="h5" align="center" sx={{ mt: 14, mb: 1 }}>
          We sent one time passcode
        </Typography>
        <Typography variant="body1" align="center">
          Passcode was sent to {email}
        </Typography>
        <TextField
          fullWidth
          sx={{ mt: 4 }}
          label="Passcode"
          onChange={(e) =>
            formik.setFieldValue("verificationCode", e.target.value)
          }
          value={formik.values.verificationCode}
          error={incorrectCode}
          helperText={incorrectCode ? "Invalid verification code" : ""}
          inputProps={{
            maxLength: 5,
          }}
        />
        <Button
          sx={{ my: 3 }}
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          onClick={() => formik.handleSubmit()}
          disabled={!formik.isValid || loading}
        >
          Continue
        </Button>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyItems="center"
        direction="column"
      >
        <Link underline="none" onClick={resendCode} color="inherit">
          Resend code
        </Link>
      </Grid>
    </Grid>
  );
};
