import { Navigate } from "react-router-dom";

export default function RequireAuth({ children }) {
  let isAuthed = false;

  try {
    const authExp = localStorage.getItem("ansible-auth-exp");
    isAuthed = !!authExp && parseInt(authExp) * 1000 > new Date().getTime();
  } catch {
    isAuthed = false;
  }

  return isAuthed ? children : <Navigate to="/signin" replace={true} />;
}
