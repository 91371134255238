const mastercard =
  "^(5[1-5][0-9]{14}|2[2-6][0-9]{14}|27[0-1][0-9]{14}|2720[0-9]{12})$";
const visa = "^4[0-9]{12}(?:[0-9]{3})?$"; // Regex for Visa cards
const visaOrMastercard =
  "^(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14}|2[2-6][0-9]{14}|27[0-1][0-9]{14}|2720[0-9]{12})$";

const usBankAccountNumberInputRegex = "^[0-9]{0,17}$";
const usBankRoutingNumberInputRegex = "^[0-9]{0,9}$";

export {
  mastercard,
  visa,
  visaOrMastercard,
  usBankAccountNumberInputRegex,
  usBankRoutingNumberInputRegex,
};
