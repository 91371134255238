import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { usersApi } from "../../api";

export const UserConfirmation = ({
  onComplete,
}: {
  onComplete: (publicToken?: string) => void;
}) => {
  const [customer, setCustomer] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    usersApi
      .getPartnerFromToken()
      .then((res) => setCustomer(res.name))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
    setLoading(true);
  }, []);

  const shareProfile = async () => {
    setLoading(true);
    const token = await usersApi.sharePartnerAccess();
    onComplete(token);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Typography variant="h5" align="center" sx={{ mt: 14, mb: 1 }}>
        You have previously onboarded to Beam
      </Typography>
      <Typography variant="body1" align="center">
        would you like to share your profile with {customer}
      </Typography>
      <Button
        sx={{ my: 3 }}
        variant="contained"
        color="secondary"
        size="large"
        fullWidth
        onClick={shareProfile}
        disabled={loading}
      >
        Share Profile
      </Button>
    </Grid>
  );
};
