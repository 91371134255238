import { adminApi } from "./admin";
import { onboardingApi } from "./onboarding";

const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
  let [resource, config] = args;
  const origin =
    resource instanceof URL
      ? resource.origin
      : typeof resource === "string"
      ? new URL(resource).origin
      : null;

  // request interceptor starts
  const newHeaders = { ...config?.headers };
  config = {
    ...config,
    headers: newHeaders,
    credentials:
      origin === "https://explorer-api.walletconnect.com" ||
      origin === "https://rpc.walletconnect.com" ||
      origin === "https://relay.walletconnect.org" ||
      origin === "https://box-v1.api.decent.xyz" ||
      origin === "https://api-mainnet.layerzero-scan.com" ||
      origin === "https://api.web3modal.com" ||
      origin === "https://sepolia.base.org" ||
      origin === "https://sepolia-rollup.arbitrum.io" ||
      origin === "https://sepolia.optimism.io"
        ? "omit"
        : "include",
  };

  // Check if the request is for a domain ending with ".ansiblelabs.xyz"
  if (origin && origin.includes(".ansiblelabs.xyz")) {
    // Get the bearer token from localStorage with the name "accessToken"
    const accessToken = localStorage.getItem("accessToken");

    // Add the bearer token to the request headers
    if (accessToken) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
  }

  // request interceptor ends
  const response = await originalFetch(resource, config);

  // response interceptor here
  return response;
};

export * from "./users";
export * from "./transactions";
export * from "./admin";
export * from "./kyc";
export * from "./onboarding";
export * from "./rates";
export * from "./assets";
export * from "./embedded";

(window as any).$ = {
  ...adminApi.testerCommands,
  onboardingApi,
};
