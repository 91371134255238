import Web3 from "web3";

const isValidEthAddress = async (ethAddress: string) => {
  if (ethAddress.includes(".eth")) {
    try {
      ethAddress = await new Web3(window.ethereum).eth.ens.getAddress(
        ethAddress
      );
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  return new Web3().utils.isAddress(ethAddress);
};

export { isValidEthAddress };
