import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import "./UserFeedback.scss";

export function UserFeedback({ user }) {
  return (
    <>
      {user && (
        <Link
          href="mailto:support@ansiblelabs.xyz?subject=Feedback"
          target="_blank"
        >
          <Typography
            variant="body1"
            classes={{ root: "feedback-widget-label" }}
          >
            Give us feedback
          </Typography>
        </Link>
      )}
    </>
  );
}
