import { zeroAddress } from "viem";
import { BlockchainFullNames } from "../types";
import { isProd as isProdEnv } from "../utils";
import { CONTRACT_ADDRESSES } from "../config/chains";

const isProd = isProdEnv();

export function getAssetContractAddress(
  token: string,
  network: string,
  mainnet = isProd
): string {
  const networkName: string = BlockchainFullNames[network]?.toUpperCase();
  const asset = `${token}_${networkName}`;

  // doesnt apply to BTC, DOGE, XLM, etc because they dont have smart contracts
  switch (asset) {
    case "ETH_ETHEREUM":
    case "MATIC_POLYGON":
    case "ETH_ARBITRUM":
    case "ETH_OPTIMISM":
    case "ETH_ZORA":
    case "AVAX_AVALANCHE":
    case "SOL_SOLANA":
      return zeroAddress;
    case "USDC_ETHEREUM":
      return mainnet
        ? CONTRACT_ADDRESSES.MAINNET.USDC_ETHEREUM
        : CONTRACT_ADDRESSES.TESTNET.USDC_ETHEREUM_SEPOLIA;
    case "USDC_POLYGON":
      return mainnet
        ? CONTRACT_ADDRESSES.MAINNET.USDC_POLYGON_POS
        : CONTRACT_ADDRESSES.TESTNET.USDC_POLYGON_POS;
    case "USDC_OPTIMISM":
      return mainnet
        ? CONTRACT_ADDRESSES.MAINNET.USDC_OPTIMISM_NATIVE
        : CONTRACT_ADDRESSES.TESTNET.USDC_OPTIMISM_SEPOLIA_NATIVE;
    case "USDC_ARBITRUM":
      return mainnet
        ? CONTRACT_ADDRESSES.MAINNET.USDC_ARBITRUM_NATIVE
        : CONTRACT_ADDRESSES.TESTNET.USDC_ARBITRUM_SEPOLIA_NATIVE;
    case "USDC_SOLANA":
      return mainnet
        ? CONTRACT_ADDRESSES.MAINNET.USDC_SOLANA
        : CONTRACT_ADDRESSES.TESTNET.USDC_SOLANA_DEVNET;
    default:
      break;
  }

  throw new Error(
    `Could not find contract address for asset ${asset} and network ${network}`
  );
}
