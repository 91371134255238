import { useQuery } from "@tanstack/react-query";
import { onboardingApi } from "../../api";
import { EmailInfo } from "./EmailInfo";
import Kyc from "./Kyc/Kyc";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { EmailVerification } from "./EmailVerification/EmailVerification";
import { SevereRiskWalletWarning } from "./SeverRiskWalletWarning/SevereRiskWalletWarning";

import { AwaitingReview } from "./AwaitingReview/AwaitingReview";
import { WalletAddress } from "./WalletAddress/WalletAddress";
import { Terms } from "./Terms/Terms";
import { Payment } from "./Payment/Payment";
import { Loading } from "../../components/Loading";

export enum OnboardingSteps {
  PENDING_WALLET_SCREENING = "PENDING_WALLET_SCREENING",
  SEVERE_RISK_WALLET = "SEVERE_RISK_WALLET",
  COLLECT_EMAIL = "COLLECT_EMAIL",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  ACCEPT_TERMS = "ACCEPT_TERMS",
  COLLECT_IDENTITY_INFO = "COLLECT_IDENTITY_INFO",
  COLLECT_PAYMENT_CREDENTIAL = "COLLECT_PAYMENT_CREDENTIAL",
  ONBOARDING_COMPLETE = "ONBOARDING_COMPLETE",
  AWAITING_KYC_REVIEW = "AWAITING_KYC_REVIEW",
  KYC_DECLINED = "KYC_DECLINED",
  COLLECT_WALLET_ADDRESS = "COLLECT_walletAddress",
  PENDING_BEAM_ACCOUNT_CREATION = "PENDING_BEAM_ACCOUNT_CREATION",
}

export const Onboarding = () => {
  const navigate = useNavigate();
  const expectedNextStep = useRef<string | undefined>(undefined);
  const [retryInterval, setRetryInterval] = useState<number>(1000);
  const { data: onboardingInfo } = useQuery(
    ["onboardingApi.getOnboardingInfo"],
    onboardingApi.getOnboardingInfo,
    {
      refetchInterval: retryInterval,
      onSuccess: (onboardingInfo) => {
        // we want to stop the polling if the user has been declined or they are awaiting a case review
        if (
          onboardingInfo.currentStep === OnboardingSteps.KYC_DECLINED ||
          onboardingInfo.currentStep === OnboardingSteps.AWAITING_KYC_REVIEW ||
          expectedNextStep.current === onboardingInfo.currentStep
        ) {
          setRetryInterval(0);
        }
      },
    }
  );
  const onboardingStep = onboardingInfo?.currentStep;

  const renderOnboardingStep = () => {
    if (onboardingStep === OnboardingSteps.SEVERE_RISK_WALLET) {
      return <SevereRiskWalletWarning />;
    } else if (onboardingStep === OnboardingSteps.ACCEPT_TERMS) {
      return (
        <Terms
          onComplete={createOnComplete(OnboardingSteps.COLLECT_IDENTITY_INFO)}
        />
      );
    } else if (onboardingStep === OnboardingSteps.COLLECT_EMAIL) {
      return (
        <EmailInfo
          onComplete={createOnComplete(OnboardingSteps.VERIFY_EMAIL)}
        ></EmailInfo>
      );
    } else if (onboardingStep === OnboardingSteps.VERIFY_EMAIL) {
      return (
        <EmailVerification
          onComplete={createOnComplete(OnboardingSteps.COLLECT_IDENTITY_INFO)}
        />
      );
    } else if (onboardingStep === OnboardingSteps.COLLECT_PAYMENT_CREDENTIAL) {
      return (
        <Payment
          onComplete={createOnComplete(OnboardingSteps.ONBOARDING_COMPLETE)}
        />
      );
    } else if (onboardingStep === OnboardingSteps.ONBOARDING_COMPLETE) {
      navigate("/dashboard");
    } else if (onboardingStep === OnboardingSteps.AWAITING_KYC_REVIEW) {
      return <AwaitingReview />;
    } else if (onboardingStep === OnboardingSteps.KYC_DECLINED) {
      return <SevereRiskWalletWarning />;
    } else if (onboardingStep === OnboardingSteps.PENDING_WALLET_SCREENING) {
      return (
        <WalletAddress
          onComplete={createOnComplete(OnboardingSteps.COLLECT_IDENTITY_INFO)}
        />
      );
    } else if (
      onboardingStep === OnboardingSteps.PENDING_BEAM_ACCOUNT_CREATION
    ) {
      return <Loading />;
    }
  };

  const createOnComplete = (onboardingNextStep: string) => {
    return () => {
      expectedNextStep.current = onboardingNextStep;
      setRetryInterval(1000);
    };
  };

  return (
    <>
      {onboardingStep === OnboardingSteps.COLLECT_IDENTITY_INFO ? (
        <Kyc
          onComplete={createOnComplete(
            OnboardingSteps.COLLECT_PAYMENT_CREDENTIAL
          )}
        />
      ) : (
        <>{renderOnboardingStep()}</>
      )}
    </>
  );
};
