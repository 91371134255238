export const logout = () => {
  // clear session from localstorage
  localStorage.removeItem("ansible-reference-id");
  localStorage.removeItem("ansible-auth-exp");
  localStorage.removeItem("ansible-beam-user-jwt"); // legacy - no longer used

  // redirect to login
  // will cause infinite rerender if redirecting to the same page
  if (window?.location?.pathname !== "/login") {
    window.location.href = "/login";
  }
};

export const logoutForEmbed = () => {
  localStorage.removeItem("ansible-reference-id");
  localStorage.removeItem("ansible-auth-exp");
  localStorage.removeItem("ansible-beam-user-jwt"); // legacy - no longer used
};
