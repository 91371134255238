import Joi from "joi";

export type UserDto = {
  userId: string;
  signedTimestamp?: Date;
  zeroHashParticipantCode?: string;
  emailAddress?: string;
  walletAddresses?: UserWalletAddressDto[];
  firstName: string;
  lastName: string;
  blocked: boolean;
  deletedAt: Date | null;
};

export const userDtoSchema: Joi.ObjectSchema<UserDto> = Joi.object({
  userId: Joi.string().required(),
  signedTimestamp: Joi.date(),
  zeroHashParticipantCode: Joi.any(),
  emailAddress: Joi.any(),
  walletAddresses: Joi.any(),
  blocked: Joi.boolean(),
  deletedAt: Joi.any(),
}).unknown();

export type UserWalletAddressDto = {
  chainId: string;
  walletAddress: string;
  memoId?: string;
  destinationTag?: string;
};

export type UserTasksDto = {
  id: string;
  title: string;
  description: string;
  required: boolean;
  createdAt: Date;
};

export type BankingInfoDto = {
  id: string;
  last4Digits: string;
  name: string;
  routingNumber?: string;
  supportsRTP?: boolean;
  type?: string;
  paymentInfoCount: number;
  status: string;
  preferred?: boolean;
};

export const bankingInfoDtoSchema: Joi.ObjectSchema<BankingInfoDto> =
  Joi.object({
    id: Joi.string().required(),
    last4Digits: Joi.string().required(),
    name: Joi.string().required(),
    routingNumber: Joi.string(),
    supportsRTP: Joi.boolean(),
    type: Joi.string(),
    paymentInfoCount: Joi.number(),
    status: Joi.string().required(),
    preferred: Joi.boolean().allow(null),
  });
