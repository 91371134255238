import { Grid, Typography } from "@mui/material";

export const AwaitingReview = () => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Typography variant="h5" align="center" sx={{ mt: 14, mb: 1 }}>
        You are currently under review
      </Typography>
      <Typography variant="body1" align="center">
        We’ll send you an email once the review is complete
      </Typography>
    </Grid>
  );
};
