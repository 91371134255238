import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TransactionRow } from "../../components/TransactionRow/TransactionRow";
import { transactionsQueryKey, transactionsApi } from "../../api";
import "./Transactions.scss";

export function Transactions() {
  const navigate = useNavigate();
  const { data: transactions } = useQuery({
    queryKey: transactionsQueryKey,
    queryFn: async () => {
      return await transactionsApi.getTransactions();
    },
  });

  return (
    <Grid
      container
      direction={"column"}
      classes={{ root: "transactions-container" }}
    >
      <Grid item classes={{ root: "transactions-back-row" }}>
        <IconButton
          classes={{ root: "transactions-back-btn" }}
          onClick={() => navigate("/dashboard")}
        >
          <ArrowBackIcon fontSize="medium" />
        </IconButton>
        <Typography
          variant="body1"
          classes={{ root: "transactions-back-text" }}
        >
          Back
        </Typography>
      </Grid>
      <Grid item classes={{ root: "transactions-rows-container" }}>
        {transactions?.map((row, index) => (
          <Grid key={row.depositId} item>
            <TransactionRow
              data={row}
              showDivider={index < transactions.length - 1}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
