import { useEffect, useState, useCallback } from "react";
import Tooltip from "@mui/material/Tooltip";
import Web3 from "web3";
import * as solWeb3 from "@solana/web3.js";
import * as splToken from "@solana/spl-token";
import * as solWalletAdapterBase from "@solana/wallet-adapter-base";
import * as solWalletAdapter from "@solana/wallet-adapter-react";
import { PhantomWalletName } from "@solana/wallet-adapter-wallets";
import {
  useAccount,
  useDisconnect,
  useSwitchNetwork,
  useWalletClient,
  usePublicClient,
  useTransaction,
  useNetwork,
} from "wagmi";
import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";
import { parseEther } from "viem";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import { PayoutEstimator } from "../PayoutEstimator/PayoutEstimator";
import { User } from "../../models/user";
import type {
  BeamSupportedZeroHashAsset,
  ChainConfig,
  TxnHash,
} from "../../types";
import { getUserWalletAddress } from "../../utils/getUserWalletAddress";
import { getChainParams, isSPLToken, getSPLTokenId } from "../../utils/chain";
import { LAMPORTS_PER_SOL, LAMPORTS_PER_USDC } from "../../config/chains";
import useFeatureFlag from "../../hooks/useFeatureFlag";
import { Alert, AlertTitle, Typography } from "@mui/material";
import BoxTransaction from "../BoxTransaction";
import ERC20Transaction from "../ERC20Transaction";
import { getZeroHashAssetFromTokenAndNetwork } from "../../utils/getZeroHashAssetFromTokenAndNetwork";
import { isMobile } from "../../utils";

type Props = {
  user?: User | null;
  amount: string;
  selectedCurrency: BeamSupportedZeroHashAsset;
  selectedCurrencyLabel: string;
  onAmountChange?: (event: any) => void;
  onSelectedCurrencyChange?: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  assetOptions?: { label: string; value: string }[];
  supportedAssetsLoaded?: boolean;
  chainConfig: ChainConfig | null;
  selectedNetwork: string;
  onSelectedNetworkChange?: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  // getNetworkOptions: (
  //   params: GetNetworkOptionsParams
  // ) => { label: string; value: string }[];
  networkOptions: { label: string; value: string }[];
};

const _CashOut = ({
  user,
  amount,
  selectedCurrency,
  selectedCurrencyLabel,
  onAmountChange,
  onSelectedCurrencyChange,
  supportedAssetsLoaded,
  assetOptions,
  chainConfig,
  selectedNetwork,
  onSelectedNetworkChange,
  networkOptions,
}: Props) => {
  const cashOutFlagEnabled = Boolean(useFeatureFlag("enable-cashout", false));
  const [loadingModal, setLoadingModal] = useState(false);
  const [cashingOut, setCashingOut] = useState(false);
  const [cashingOutEVMNative, setCashingOutEVMNative] = useState(false);
  const [cashingOutERC20, setCashingOutERC20] = useState(false);
  const [transactionHash, setTransactionHash] = useState<TxnHash>({
    hash: "" as `0x${string}`,
  });
  const [cashingOutSOLOrSPL, setCashingOutSOLOrSPL] = useState(false);
  const [executingTransaction, setExecutingTransaction] = useState(false);
  const [error, setError] = useState<{ title: string; content: string } | null>(
    null
  );
  const showCashoutMobileWarning = isMobile();

  // Decent/Box state
  const [cashingOutViaBox, setCashingOutViaBox] = useState(false);
  const [boxTxnInProgress, setBoxTxnInProgress] = useState(false);

  const { open: openWeb3Modal, close: closeWeb3Modal } = useWeb3Modal();
  const { open: modalIsOpen } = useWeb3ModalState();
  const {
    address: accountAddress,
    // connector,
    isConnecting: accountIsConnecting,
    isReconnecting: accountIsReconnecting,
    isConnected: accountIsConnected,
    // isDisconnected: accountIsDisconnected,
  } = useAccount();
  const {
    disconnectAsync: disconnectAccount,
    // isError: disconnectError,
    isIdle: disconnectIdle,
    isLoading: disconnectLoading,
    isSuccess: disconnectSuccess,
  } = useDisconnect();
  const { connection: solConnection } = solWalletAdapter.useConnection();
  const {
    publicKey: solWalletPubkey,
    signTransaction: signSolTransaction,
    sendTransaction: sendSolTransaction,
    connect: connectSolWallet,
    select: solWalletSelect,
    connecting: connectingSolWallet,
    disconnecting: disconnectingSolWallet,
  } = solWalletAdapter.useWallet();
  solWalletSelect(PhantomWalletName); // todo: support other solana wallets
  const {
    // error: switchNetworkError,
    isIdle: switchNetworkIdle,
    isLoading: switchNetworkLoading,
    isSuccess: switchNetworkSuccess,
    pendingChainId,
    switchNetworkAsync,
  } = useSwitchNetwork();
  const { chain } = useNetwork();

  const [web3SwitchNetworkError, setWeb3SwitchNetworkError] = useState(null);
  const [web3SwitchNetworkLoading, setWeb3SwitchNetworkLoading] =
    useState(false);
  // const chainId = getChainId(selectedCurrency);
  const chainId = chainConfig?.[selectedNetwork]?.chainId;

  const {
    data: walletClient,
    // isIdle: walletClientIdle,
    // isLoading: walletClientLoading,
    // isFetching: walletClientFetching,
    isSuccess: walletClientSuccess,
    // isError: walletClientError,
  } = useWalletClient({
    chainId,
    onError(error) {
      setError({
        title: "Error connecting to wallet",
        content: error.message ?? JSON.stringify(error, null, 2),
      });
    },
  });
  const publicClient = usePublicClient({
    chainId,
  });
  const {
    // data: transaction,
    isIdle: transactionIdle,
    isLoading: transactionLoading,
    // isSuccess: transactionSuccess,
    error: transactionError,
  } = useTransaction({ chainId, hash: transactionHash.hash as `0x${string}` });

  const allowERC20CashOut =
    cashingOutERC20 &&
    !!chainId &&
    !!user &&
    !!accountAddress &&
    selectedCurrency.startsWith("USDC");
  const allowCashOutViaBox =
    cashingOutViaBox &&
    !!user &&
    !!accountAddress &&
    selectedNetwork === "BASE";

  const resetState = useCallback(async () => {
    setLoadingModal(false);
    setCashingOutEVMNative(false);
    setCashingOutSOLOrSPL(false);
    setWeb3SwitchNetworkError(null);
    setWeb3SwitchNetworkLoading(false);
    setExecutingTransaction(false);
    setCashingOutViaBox(false);
    setBoxTxnInProgress(false);
    setCashingOutERC20(false);
    setCashingOut(false);
    // do not reset txn hash here so it can be displayed
    if (accountIsConnected) await disconnectAccount();
  }, [disconnectAccount, accountIsConnected]);

  const handleCashOutSuccess = useCallback(async () => {
    // todo: confetti animation
    resetState();
  }, [resetState]);

  const handleCashOutSOL = useCallback(async () => {
    try {
      const fromPubkey = solWalletPubkey;
      const toPubkey = new solWeb3.PublicKey(
        getUserWalletAddress(
          getZeroHashAssetFromTokenAndNetwork(
            selectedCurrency,
            selectedNetwork
          ),
          user
        )
      );

      let instruction;
      if (fromPubkey) {
        instruction = solWeb3.SystemProgram.transfer({
          fromPubkey,
          toPubkey,
          lamports: parseFloat(amount) * LAMPORTS_PER_SOL,
        });
      } else {
        throw new solWalletAdapterBase.WalletNotConnectedError();
      }

      const transaction = new solWeb3.Transaction().add(instruction);

      const {
        context: { slot: minContextSlot },
        value: { blockhash, lastValidBlockHeight },
      } = await solConnection.getLatestBlockhashAndContext();

      const signature = await sendSolTransaction(transaction, solConnection, {
        minContextSlot,
      });

      await solConnection.confirmTransaction({
        blockhash,
        lastValidBlockHeight,
        signature,
      });

      setTransactionHash({
        hash: signature,
        scanUrl: chainConfig?.[selectedNetwork]?.getTxnScanUrl?.(signature),
      });
    } catch (err: any) {
      resetState();
      setError({
        title: "Error executing transaction",
        content: err.message ?? JSON.stringify(err, null, 2),
      });
    }
  }, [
    amount,
    selectedCurrency,
    selectedNetwork,
    sendSolTransaction,
    solConnection,
    solWalletPubkey,
    user,
    chainConfig,
    resetState,
  ]);

  const handleCashOutSPL = useCallback(async () => {
    try {
      const tokenAddress = new solWeb3.PublicKey(
        getSPLTokenId(selectedCurrency)
      );
      const recipientAddress = new solWeb3.PublicKey(
        getUserWalletAddress(
          getZeroHashAssetFromTokenAndNetwork(
            selectedCurrency,
            selectedNetwork
          ),
          user
        )
      );

      const transactionInstructions: solWeb3.TransactionInstruction[] = [];

      if (solWalletPubkey && signSolTransaction) {
        const associatedTokenFrom = await splToken.getAssociatedTokenAddress(
          tokenAddress,
          solWalletPubkey
        );
        const fromAccount = await splToken.getAccount(
          solConnection,
          associatedTokenFrom
        );
        const associatedTokenTo = await splToken.getAssociatedTokenAddress(
          tokenAddress,
          recipientAddress
        );

        if (!(await solConnection.getAccountInfo(associatedTokenTo))) {
          transactionInstructions.push(
            splToken.createAssociatedTokenAccountInstruction(
              solWalletPubkey,
              associatedTokenTo,
              recipientAddress,
              tokenAddress
            )
          );
        }

        transactionInstructions.push(
          splToken.createTransferInstruction(
            fromAccount.address, // source
            associatedTokenTo, // dest
            solWalletPubkey,
            parseFloat(amount) *
              (selectedCurrency.startsWith("USDC")
                ? LAMPORTS_PER_USDC
                : LAMPORTS_PER_SOL)
          )
        );

        const transaction = new solWeb3.Transaction().add(
          ...transactionInstructions
        );

        // signature is transaction address
        // confirm your transaction on 'https://explorer.solana.com/?cluster=devnet'
        const signature = await configureAndSendCurrentSolTransaction(
          transaction,
          solConnection,
          solWalletPubkey,
          signSolTransaction
        );

        setTransactionHash({
          hash: signature,
          scanUrl: chainConfig?.[selectedNetwork]?.getTxnScanUrl?.(signature),
        });
      } else {
        throw new solWalletAdapterBase.WalletNotConnectedError();
      }
    } catch (err: any) {
      resetState();
      setError({
        title: "Error executing transaction",
        content: err.message ?? JSON.stringify(err, null, 2),
      });
    }
  }, [
    amount,
    selectedCurrency,
    selectedNetwork,
    signSolTransaction,
    solConnection,
    solWalletPubkey,
    user,
    chainConfig,
    resetState,
  ]);

  const openModal = useCallback(async () => {
    if (selectedCurrency === "SOL" || isSPLToken(selectedCurrency)) {
      // web3modal doesnt handle solana natively
      // todo: integrate solib?
      return;
    }

    try {
      setLoadingModal(true);
      await openWeb3Modal();
      setLoadingModal(false);
    } catch (err: any) {
      resetState();
      setError({
        title: "Error opening web3modal",
        content: err.message ?? JSON.stringify(err, null, 2),
      });
    } finally {
      setLoadingModal(false);
    }
  }, [openWeb3Modal, selectedCurrency, resetState]);

  const initCashOutSOLOrSPL = useCallback(async () => {
    setCashingOutSOLOrSPL(true);

    try {
      if (!solWalletPubkey) {
        await connectSolWallet();
      }
    } catch (error: any) {
      resetState();
      setError({
        title: "Issue connecting to your SOL wallet",
        content:
          "We ran into an issue connecting to your SOL wallet, please make sure you have the proper browser extension installed",
      });
    }
  }, [connectSolWallet, solWalletPubkey, resetState]);

  useEffect(() => {
    if (cashingOutSOLOrSPL && solWalletPubkey && selectedNetwork === "SOL") {
      if (selectedCurrency === "SOL") {
        handleCashOutSOL();
      } else {
        handleCashOutSPL();
      }
    }
  }, [
    cashingOutSOLOrSPL,
    solWalletPubkey,
    connectingSolWallet,
    disconnectingSolWallet,
    handleCashOutSOL,
    handleCashOutSPL,
    selectedCurrency,
    selectedNetwork,
  ]);

  const handleCashOut = useCallback(async () => {
    try {
      setError(null);
      setTransactionHash({
        hash: "" as `0x${string}`,
      });
      setCashingOut(true);

      // web3modal doesnt handle solana natively
      // todo: integrate solib
      // https://solib.dev/
      if (selectedNetwork === "SOL") return initCashOutSOLOrSPL();

      // 1. disconnect wallet
      if (accountIsConnected) {
        await disconnectAccount();
      }

      // 3. open modal and connect account
      if (!accountIsConnected && !modalIsOpen) {
        await openModal();
      }
    } catch (err: any) {
      resetState();
      setError({
        title: "Error cashing out",
        content: err.message ?? JSON.stringify(err, null, 2),
      });
      await closeWeb3Modal();
    }
  }, [
    closeWeb3Modal,
    modalIsOpen,
    openModal,
    accountIsConnected,
    disconnectAccount,
    resetState,
    initCashOutSOLOrSPL,
    selectedNetwork,
  ]);

  const configureAndSendCurrentSolTransaction = async (
    transaction: solWeb3.Transaction,
    connection: solWeb3.Connection,
    feePayer: solWeb3.PublicKey,
    signTransaction: any
    // signTransaction: solWalletAdapterBase.SignerWalletAdapterProps["signTransaction"]
  ): Promise<string> => {
    try {
      const blockHash = await connection.getLatestBlockhash();
      transaction.feePayer = feePayer;
      transaction.recentBlockhash = blockHash.blockhash;
      const signed = await signTransaction(transaction);
      const signature = await connection.sendRawTransaction(signed.serialize());
      await connection.confirmTransaction({
        blockhash: blockHash.blockhash,
        lastValidBlockHeight: blockHash.lastValidBlockHeight,
        signature,
      });
      return signature;
    } catch (err: any) {
      throw err;
    }
  };

  // 2. reconnect wallet
  // ensure disconnect is not in progress and
  // account connection is not in progress
  useEffect(() => {
    if (
      disconnectSuccess &&
      !disconnectLoading &&
      !accountIsConnected &&
      !accountIsConnecting &&
      !transactionLoading &&
      !connectingSolWallet &&
      !disconnectingSolWallet &&
      !window.ethereum?._addresses?.[0] &&
      !boxTxnInProgress &&
      cashingOut
    ) {
      handleCashOut();
    }
  }, [
    disconnectLoading,
    disconnectSuccess,
    accountIsConnected,
    accountIsConnecting,
    cashingOut,
    handleCashOut,
    transactionLoading,
    connectingSolWallet,
    disconnectingSolWallet,
    boxTxnInProgress,
  ]);

  const setNetwork = useCallback(async () => {
    if (selectedCurrency === "SOL" || isSPLToken(selectedCurrency)) {
      // web3modal doesnt handle solana natively
      // todo: integrate solib?
      return;
    }

    // chainId not applicable
    if (!chainId) {
      // eg BTC
      return;
    }

    if (chain?.id === chainId) {
      // if wallet is already on desired network, dont call switchNetworkAsync or it will hang forever
      return;
    }

    try {
      if (switchNetworkAsync) {
        await switchNetworkAsync(chainId);
      } else {
        setWeb3SwitchNetworkLoading(true);

        // Some wallet apps do not support programmatic network switching and switchNetworkAsync will be undefined.
        // For those situations, you can typically switch networks in the wallet app and wagmi will stay up-to-date.
        try {
          const web3 = new Web3(window.ethereum);
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: web3.utils.toHex(chainId) }],
          });
        } catch (err: any) {
          // This error code indicates that the chain has not been added to the wallet
          // attempt to add it
          if (err.code === 4902) {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [getChainParams(selectedCurrency)],
            });
          } else {
            setWeb3SwitchNetworkError(err);
            throw err;
          }
        }
      }
    } catch (err: any) {
      resetState();
      setError({
        title: "Error switching network",
        content: err.message ?? JSON.stringify(err, null, 2),
      });
    } finally {
      setWeb3SwitchNetworkLoading(false);
    }
  }, [chainId, selectedCurrency, switchNetworkAsync, resetState, chain?.id]);

  // 4. on account connection, set correct network
  // ensure account successfully connected and
  // network switch is not in progress
  useEffect(() => {
    if (
      cashingOut &&
      !switchNetworkLoading &&
      !web3SwitchNetworkLoading &&
      !disconnectLoading &&
      !loadingModal &&
      !(accountIsConnecting || accountIsReconnecting) &&
      accountIsConnected &&
      accountAddress &&
      !transactionLoading &&
      !connectingSolWallet &&
      !boxTxnInProgress
    ) {
      setNetwork();
    }
  }, [
    disconnectLoading,
    switchNetworkLoading,
    accountIsConnecting,
    accountIsReconnecting,
    accountIsConnected,
    cashingOut,
    accountAddress,
    setNetwork,
    web3SwitchNetworkLoading,
    loadingModal,
    transactionLoading,
    connectingSolWallet,
    boxTxnInProgress,
  ]);

  const executeTransaction = useCallback(async () => {
    try {
      if (selectedNetwork === "BASE") {
        setCashingOutViaBox(true);
        return;
      } else if (selectedCurrency.startsWith("USDC")) {
        setCashingOutERC20(true);
        return;
      } else if (walletClient) {
        // For EVM native token
        try {
          setExecutingTransaction(true);
          setCashingOutEVMNative(true);

          // @ts-ignore
          const txnHash = await walletClient.sendTransaction({
            account: accountAddress,
            to: getUserWalletAddress(
              getZeroHashAssetFromTokenAndNetwork(
                selectedCurrency,
                selectedNetwork
              ),
              user
            ) as `0x${string}`,
            value: parseEther(amount),
          });

          setTransactionHash({
            hash: txnHash,
            scanUrl:
              chainConfig?.[selectedNetwork]?.getTxnScanUrl?.(txnHash) ??
              ("" as `https://${string}`),
          });
          handleCashOutSuccess(); // finish cash out here or else extra transactions will be initiated
        } catch (err: any) {
          throw err;
        }
      } else {
        throw new Error("Wallet client not connected");
      }
    } catch (err: any) {
      resetState();
      setError({
        title: "Error executing transaction",
        content: err.message ?? JSON.stringify(err, null, 2),
      });
    }
  }, [
    accountAddress,
    amount,
    selectedCurrency,
    // user,
    walletClient,
    resetState,
    handleCashOutSuccess,
    selectedNetwork,
    chainConfig,
    user,
  ]);

  // 5. on network switch, execute transaction
  // ensure network switch is not in progress and
  // transaction is not in progress and
  // account is connected and
  // walletClient is connected
  useEffect(() => {
    try {
      if (
        cashingOut &&
        !disconnectLoading &&
        !loadingModal &&
        !accountIsConnecting &&
        accountIsConnected &&
        !switchNetworkLoading &&
        !web3SwitchNetworkLoading &&
        !web3SwitchNetworkError &&
        (switchNetworkSuccess || chain?.id === chainId) &&
        walletClientSuccess &&
        !!walletClient &&
        !transactionLoading &&
        !executingTransaction &&
        !boxTxnInProgress
      ) {
        executeTransaction();
      }
    } catch (err: any) {
      resetState();
      setError({
        title: "Error executing transaction",
        content: err.message ?? JSON.stringify(err, null, 2),
      });
    }
  }, [
    chain,
    cashingOut,
    switchNetworkSuccess,
    switchNetworkLoading,
    web3SwitchNetworkLoading,
    web3SwitchNetworkError,
    chainId,
    pendingChainId,
    executeTransaction,
    resetState,
    accountIsConnected,
    walletClientSuccess,
    disconnectLoading,
    loadingModal,
    accountIsConnecting,
    walletClient,
    transactionLoading,
    connectingSolWallet,
    executingTransaction,
    boxTxnInProgress,
  ]);

  // 6. complete transaction when hash is returned
  useEffect(() => {
    if (!!transactionHash.hash) {
      // 7. confirm transaction on the blockchain
      // if (publicClient) {
      //   publicClient
      //     // @ts-ignore
      //     .getTransactionConfirmations({ hash: transactionHash })
      //     .then((confirmations) => {
      //       console.log(confirmations);
      //     });
      // }

      handleCashOutSuccess();
    }
  }, [transactionHash, handleCashOutSuccess, publicClient]);

  useEffect(() => {
    if (
      (cashingOutEVMNative || cashingOutERC20) &&
      transactionError &&
      !transactionLoading
    ) {
      resetState();
      setError({
        title: "Error executing transaction",
        content:
          transactionError.message ?? JSON.stringify(transactionError, null, 2),
      });
    }
  }, [
    cashingOutEVMNative,
    cashingOutERC20,
    resetState,
    transactionError,
    transactionLoading,
  ]);

  const disableCashOut = useCallback(() => {
    return (
      !(selectedCurrency && amount) ||
      cashingOut ||
      showCashoutMobileWarning ||
      !amount ||
      amount === "0"
    );
  }, [selectedCurrency, amount, cashingOut, showCashoutMobileWarning]);

  // modal was closed before selecting a wallet => reset
  useEffect(() => {
    if (
      cashingOut &&
      !modalIsOpen &&
      !loadingModal &&
      accountIsConnecting &&
      disconnectIdle &&
      transactionIdle &&
      switchNetworkIdle &&
      !web3SwitchNetworkLoading
    ) {
      resetState();
    }
  }, [
    modalIsOpen,
    accountIsConnecting,
    cashingOut,
    resetState,
    accountIsConnected,
    disconnectIdle,
    loadingModal,
    transactionIdle,
    switchNetworkIdle,
    web3SwitchNetworkLoading,
  ]);

  const handleTransactionError = useCallback(
    (error: any) => {
      resetState();
      setError({
        title: "Error executing transaction",
        content: error.message ?? JSON.stringify(error, null, 2),
      });
    },
    [resetState]
  );

  const CashOutButton = () => (
    <Button
      variant="contained"
      color="secondary"
      size="large"
      fullWidth
      onClick={async () => await handleCashOut()}
      disabled={disableCashOut()}
      sx={{ mt: 3 }}
    >
      {cashingOut ? <CircularProgress size={26} /> : "Cash Out"}
    </Button>
  );

  return (
    <>
      <PayoutEstimator
        amount={amount}
        selectedCurrency={selectedCurrency}
        selectedCurrencyLabel={selectedCurrencyLabel}
        onAmountChange={onAmountChange}
        onSelectedCurrencyChange={onSelectedCurrencyChange}
        assetOptions={assetOptions}
        supportedAssetsLoaded={supportedAssetsLoaded}
        selectedNetwork={selectedNetwork}
        onSelectedNetworkChange={onSelectedNetworkChange}
        networkOptions={networkOptions}
      />
      {cashOutFlagEnabled &&
        (showCashoutMobileWarning ? (
          <Tooltip
            arrow
            open={showCashoutMobileWarning}
            disableTouchListener={false}
            placement="bottom"
            title={
              <>
                <Typography color="inherit" variant={"caption"}>
                  This feature is not currently supported on mobile devices.
                  Please copy the address below and send it from your
                  self-custody wallet directly
                </Typography>
              </>
            }
            children={CashOutButton()}
          ></Tooltip>
        ) : (
          <CashOutButton />
        ))}
      {!!error && (
        <Alert
          severity="warning"
          sx={{ width: "90%", marginY: 2 }}
          onClose={() => setError(null)}
        >
          <AlertTitle>{error?.title}</AlertTitle>
          {error?.content}
        </Alert>
      )}
      {!!transactionHash.hash && (
        <Alert severity="success" sx={{ width: "90%", marginY: 2 }}>
          <AlertTitle>Transaction Hash</AlertTitle>
          {!!transactionHash.scanUrl ? (
            <Link
              variant="body2"
              target="_blank"
              href={transactionHash.scanUrl}
            >
              {transactionHash.hash}
            </Link>
          ) : (
            <Typography
              variant="body2"
              sx={{ overflowX: "scroll", height: "25px" }}
            >
              {transactionHash.hash}
            </Typography>
          )}
        </Alert>
      )}

      {/* For single chain EVM ERC20 transactions */}
      {allowERC20CashOut && (
        <ERC20Transaction
          chainId={chainId}
          amount={amount}
          user={user}
          senderAddress={accountAddress as `0x${string}`}
          selectedCurrency={selectedCurrency}
          selectedNetwork={selectedNetwork}
          handleTransactionError={handleTransactionError}
          setTransactionHash={setTransactionHash}
          chainConfig={chainConfig}
        />
      )}

      {/* For crosschain EVM transactions */}
      {allowCashOutViaBox && (
        <BoxTransaction
          amount={amount}
          user={user}
          senderAddress={accountAddress as `0x${string}`}
          selectedNetwork={selectedNetwork}
          selectedCurrency={selectedCurrency}
          handleTransactionError={handleTransactionError}
          setTransactionHash={setTransactionHash}
          setBoxTxnInProgress={setBoxTxnInProgress}
        />
      )}
    </>
  );
};
export const CashOut = withLDConsumer()(_CashOut);
