import {
  PersonaVerificationDto,
  personaVerificationDtoSchema,
} from "../dtos/personaVerificationDto";
import { CallError, httpClient } from "./httpClient";
import { AsyncResult } from "../models/result";

const getPersonaConfirmation = async (
  inquiryId: string
): AsyncResult<PersonaVerificationDto, CallError> => {
  console.log("here");
  return await httpClient.patch(
    "kyc/persona/verify",
    { inquiryId },
    personaVerificationDtoSchema
  );
};

export const kycApi = {
  getPersonaConfirmation,
};
