import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { ReactComponent as Ellipse } from "../../assets/icons/ellipse.svg";
import { ReactComponent as EthIcon } from "../../assets/icons/eth.svg";
import { ReactComponent as SolIcon } from "../../assets/icons/sol.svg";
import { ReactComponent as PolygonIcon } from "../../assets/icons/polygon.svg";
import { ReactComponent as AvaxIcon } from "../../assets/icons/avax.svg";
import { ReactComponent as BtcIcon } from "../../assets/icons/bitcoin.svg";
import { ReactComponent as XlmIcon } from "../../assets/icons/stellar.svg";
import { ReactComponent as ArbIcon } from "../../assets/icons/arbitrum.svg";
import { ReactComponent as OpIcon } from "../../assets/icons/optimism.svg";
import { ReactComponent as XrpIcon } from "../../assets/icons/xrp.svg";
import { ReactComponent as DogeIcon } from "../../assets/icons/dogecoin.svg";
import { TransactionStatus, TransactionInfo } from "../../models/transactions";

import "./TransactionRow.scss";

export enum TRMSupportedBlockchainId {
  Ethereum = "ethereum",
  Polygon = "polygon",
  Solana = "solana",
  Avalanche = "avalanche_c_chain",
  Stellar = "stellar",
  Bitcoin = "bitcoin",
  Arbitrum = "arbitrum",
  Optimism = "optimism",
  Ripple = "ripple",
  Doge = "dogecoin",
}

type Props = {
  data: TransactionInfo;
  showDivider?: boolean;
};

enum DisplayStatus {
  Processing = "Processing",
  Completed = "Completed",
  InReview = "In Review",
}

export function TransactionRow({ data, showDivider }: Props) {
  const location = useLocation();
  const isTransactionsView = location?.pathname === "/transactions";
  const timestamp = moment(data.createdAt);
  let value = data.payoutValue || data.estimatedPayoutValue || "0.00";
  value = typeof value === "number" ? value.toString() : value;

  const getStatusText = () => {
    let text = DisplayStatus.Processing;
    if (data.paymentStatus === TransactionStatus.PAYMENT_INITIATED)
      return DisplayStatus.Completed;
    if (data.approvalStatus === TransactionStatus.UNDER_REVIEW)
      return DisplayStatus.InReview;
    return text;
  };

  const getStatusClass = () => {
    let className = "transaction-info-status-processing";
    if (data.paymentStatus === TransactionStatus.PAYMENT_INITIATED)
      return "transaction-info-status-completed";
    if (data.approvalStatus === TransactionStatus.UNDER_REVIEW)
      return "transaction-info-status-inreview";
    return className;
  };

  const getChainIcon = () => {
    switch (data.chain) {
      case TRMSupportedBlockchainId.Ethereum:
        return <EthIcon />;
      case TRMSupportedBlockchainId.Polygon:
        return <PolygonIcon />;
      case TRMSupportedBlockchainId.Solana:
        return <SolIcon />;
      case TRMSupportedBlockchainId.Avalanche:
        return <AvaxIcon />;
      case TRMSupportedBlockchainId.Bitcoin:
        return <BtcIcon />;
      case TRMSupportedBlockchainId.Stellar:
        return <XlmIcon />;
      case TRMSupportedBlockchainId.Arbitrum:
        return <ArbIcon />;
      case TRMSupportedBlockchainId.Optimism:
        return <OpIcon />;
      case TRMSupportedBlockchainId.Doge:
        return <DogeIcon />;
      case TRMSupportedBlockchainId.Ripple:
        return <XrpIcon />;
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={1}>
      {isTransactionsView && (
        <Grid item xs={1} classes={{ root: "transaction-info-row-chain-icon" }}>
          {getChainIcon()}
        </Grid>
      )}
      <Grid
        item
        classes={{ root: "transaction-info-row" }}
        xs={isTransactionsView ? 11 : 12}
      >
        <Grid item classes={{ root: "transaction-info-inner-row" }}>
          <Typography
            variant="body2"
            classes={{ root: "transaction-info-row-text" }}
          >
            From:{" "}
            {`${data.sourceAddress?.substring(
              0,
              6
            )}....${data.sourceAddress?.slice(-4)}`}
          </Typography>
          <Typography
            variant="body2"
            classes={{ root: "transaction-info-row-text" }}
          >
            {!!data.payoutValue ? "" : "~"}${parseFloat(value).toFixed(2)}
          </Typography>
        </Grid>
        <Grid
          item
          classes={{
            root: "transaction-info-inner-row transaction-info-bottom-inner-row",
          }}
        >
          <Typography
            variant="caption"
            classes={{ root: "transaction-info-timestamp" }}
          >
            {timestamp.format("L")}{" "}
            <Ellipse className="transaction-info-timestamp-dot" />
            {timestamp.format("LT")}
          </Typography>
          <Typography
            variant="caption"
            classes={{ root: `transaction-info-status ${getStatusClass()}` }}
          >
            {getStatusText()}{" "}
            {data.approvalStatus === TransactionStatus.UNDER_REVIEW && (
              <Tooltip
                title="This transaction has been marked for review"
                placement="bottom-start"
              >
                <ErrorOutlineIcon
                  color="warning"
                  classes={{ root: "transaction-info-status-icon" }}
                />
              </Tooltip>
            )}
          </Typography>
        </Grid>
      </Grid>

      {showDivider && (
        <Divider style={{ width: "100%" }} sx={{ mt: 1, mb: 1 }} />
      )}
    </Grid>
  );
}
