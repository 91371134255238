import RequireAuth from "../../components/requireAuth/RequireAuth";
import { Header } from "../../components/header/Header";

type Props = {
  View: React.ReactElement;
};

export default function ViewWrapper({ View }: Props) {
  return (
    <>
      <RequireAuth>
        <Header />
        {View}
      </RequireAuth>
    </>
  );
}
