import Joi from "joi";

export enum TransactionStatus {
  UNDER_REVIEW = "UNDER_REVIEW", // DepositApprovalStatus
  PAYMENT_INITIATED = "PAYMENT_INITIATED", // TradePaymentStatus
}

export type TransactionInfo = {
  depositId: string;
  createdAt: string;
  sourceAddress: string;
  assetType: string;
  chain: string;
  approvalStatus: string;
  paymentStatus?: string;
  estimatedPayoutValue: string | number | null;
  payoutValue?: string | number | null;
  amount: number | string;
};

export const transactionInfoSchema: Joi.ObjectSchema<{
  data: TransactionInfo[];
}> = Joi.object({
  data: Joi.array().items(
    Joi.object().keys({
      depositId: Joi.string().required(),
      createdAt: Joi.string().required(),
      sourceAddress: Joi.string().required(),
      assetType: Joi.string().required(),
      chain: Joi.string().required(),
      approvalStatus: Joi.string().required(),
      paymentStatus: Joi.string().allow(null),
      estimatedPayoutValue: Joi.alternatives(Joi.string(), Joi.number())
        .required()
        .allow(null),
      payoutValue: Joi.alternatives(Joi.string(), Joi.number()).allow(null),
      amount: Joi.string().required(),
    })
  ),
});
