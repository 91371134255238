import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCard from "@mui/icons-material/CreditCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Result } from "../../models/result";
import { usersApi, bankingInfoQueryKey } from "../../api";
import { BankingAccount } from "../../api/users";
import "./BankInfo.scss";

function BankInfo() {
  const { data: bankingInfoResult, isFetching: isFetchingBankingInfo } =
    useQuery(bankingInfoQueryKey, async () => {
      return await usersApi.getBankingInfo();
    });
  const myAccounts: BankingAccount[] =
    bankingInfoResult && Result.getOrNull(bankingInfoResult);
  const navigate = useNavigate();

  const navigateBankDetails = (id: string) => {
    navigate({
      pathname: "/bank-details",
      search: `?id=${id}`,
    });
  };

  const AccountsList = () => {
    return Array.isArray(myAccounts) ? (
      <List sx={{ width: 1 }}>
        {myAccounts.map((account) => {
          return (
            <ListItem
              key={account.id}
              sx={{ float: "left", width: "100%", pl: 0 }}
              secondaryAction={
                <IconButton
                  aria-label="payment-details"
                  classes={{ root: "to-payment-details-btn" }}
                  onClick={() => navigateBankDetails(account.id)}
                  edge="end"
                >
                  <ArrowForwardIcon fontSize="medium" />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  {account.type === "CARD" ? (
                    <CreditCard fontSize="small" />
                  ) : (
                    <AccountBalanceIcon fontSize="small" />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={account.name}
                secondary={`********** ${account.last4Digits}`}
              />
            </ListItem>
          );
        })}
      </List>
    ) : (
      <></>
    );
  };

  return (
    <Grid container direction={"column"} classes={{ root: "profile-view" }}>
      <Grid item classes={{ root: "menu-back-row" }}>
        <IconButton
          classes={{ root: "menu-back-btn" }}
          onClick={() => navigate("/dashboard")}
        >
          <ArrowBackIcon fontSize="medium" />
        </IconButton>
        <Typography variant="body1" classes={{ root: "menu-back-text" }}>
          Back
        </Typography>
      </Grid>
      <Grid item classes={{ root: "menu" }}>
        <Grid item classes={{ root: "profile-subtitle" }}>
          <Typography>Bank accounts</Typography>
        </Grid>
        <Divider classes={{ root: "menu-divider" }} />
        <Grid item classes={{ root: "profile-content-container" }}>
          <Grid item display={"flex"} justifyContent={"space-between"}>
            <Typography variant="body1" classes={{ root: "profile-label" }}>
              Accounts
            </Typography>
            <Link
              variant="body1"
              classes={{ root: "add-bank-account-btn" }}
              onClick={() => {
                navigate("/payment");
              }}
            >
              Add bank account
            </Link>
          </Grid>
          <Grid
            item
            display={"flex"}
            alignItems={"center"}
            justifyContent={isFetchingBankingInfo ? "center" : "space-between"}
          >
            {isFetchingBankingInfo ? (
              <CircularProgress color="primary" sx={{ mt: 10 }} />
            ) : (
              <AccountsList />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BankInfo;
