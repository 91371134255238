import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as PayoutIcon } from "../../assets/icons/payout.svg";
import { TransactionRow } from "../TransactionRow/TransactionRow";
import { TransactionInfo } from "../../models/transactions";

import "./TransactionPreview.scss";

type Props = {
  data: TransactionInfo[];
};

export function TransactionsPreview({ data }: Props) {
  const noTransactions = !data || data?.length === 0;
  const transactions = noTransactions ? [] : data.slice(0, 3);
  const navigate = useNavigate();

  return noTransactions ? (
    <Grid
      item
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      classes={{ root: "no-transactions-preview-container" }}
    >
      <Grid item sx={{ marginTop: 3 }}>
        <PayoutIcon />
      </Grid>
      <Grid item sx={{ marginTop: 1 }}>
        <Typography variant="body2" align="center">
          No payouts yet!
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      flexDirection={"column"}
      classes={{ root: "transactions-preview-container" }}
      sx={{ marginTop: 2 }}
    >
      <Grid
        item
        justifyContent={"space-between"}
        classes={{
          root: "transactions-preview-row transactions-preview-subtitle-row",
        }}
      >
        <Typography
          variant="subtitle1"
          align="left"
          classes={{ root: "transactions-preview-subtitle" }}
        >
          See all transactions
        </Typography>
        <IconButton
          classes={{ root: "transactions-preview-see-all-icon-btn" }}
          onClick={() => navigate("/transactions")}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Grid>
      {transactions.map((row, index) => (
        <Grid
          key={row.depositId}
          item
          classes={{ root: "transactions-preview-row" }}
        >
          <TransactionRow
            data={row}
            showDivider={index < transactions.length - 1}
          />
        </Grid>
      ))}
    </Grid>
  );
}
