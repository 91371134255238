import Joi from "joi";

export type SignInResponseDto = {
  userAccountId: string;
  hasName: boolean;
  expires: number;
};
export const signInResponseSchema = Joi.object<SignInResponseDto>({
  userAccountId: Joi.string().required(),
  hasName: Joi.boolean().optional(),
  expires: Joi.number().required(),
}).unknown();
