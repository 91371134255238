import { User, WalletAddress } from "../models/user";
import { AsyncResult, Result } from "../models/result";
import { CallError, httpClient } from "./httpClient";
import {
  BankingInfoDto,
  UserDto,
  userDtoSchema,
  UserWalletAddressDto,
  UserTasksDto,
  bankingInfoDtoSchema,
} from "../dtos/userDto";
import {
  SignInResponseDto,
  signInResponseSchema,
} from "../dtos/signInResponseDto";
import { emailVerifiedResponseSchema } from "../dtos/emailVerifiedResponseDto";
import { emailVerificationSentResponseSchema } from "../dtos/emailVerificationSentResponseDto";
import { getRequest, postRequest } from "./axios";
import { BlockchainAccountType, BeamSupportedZeroHashAsset } from "../types";
import * as utils from "./utils";

const getUserAccountById = async (): AsyncResult<User, CallError> => {
  const userDto = await httpClient.getJson<UserDto>(
    `users/profile`,
    userDtoSchema
  );

  return Result.mapOk((value) => {
    const walletAddresses: WalletAddress[] | undefined = value?.walletAddresses
      ? value.walletAddresses.map((address: UserWalletAddressDto) => {
          const { memoId, destinationTag } = address;

          return {
            asset: address.chainId as BeamSupportedZeroHashAsset,
            address: `${address.walletAddress}${
              memoId
                ? "?memoId=" + memoId
                : destinationTag
                ? "?destinationTag=" + destinationTag
                : ""
            }`,
          };
        })
      : undefined;

    const user: User = {
      firstName: value.firstName,
      lastName: value.lastName,
      userId: value.userId,
      emailAddress: value.emailAddress,
      walletAddresses,
      blocked: value.blocked,
      deletedAt: value.deletedAt,
    };

    return user;
  }, userDto);
};

const getCurrentOnboardingStep = async (): AsyncResult<string, CallError> => {
  return await httpClient.getText("onboarding");
};

type LoginCredentials = {
  magicAuthId?: string;
  magicAuthToken?: string;
  email?: string;
  emailVerified?: boolean;
  firstName?: string;
  lastName?: string;
  message?: string;
  signature?: string;
  partnerLinkToken?: string;
};

type LoginPayload = {
  magicAuthId?: string;
  magicAuthToken?: string;
  email?: string;
  emailVerified?: boolean;
  firstName?: string;
  lastName?: string;
  walletMessage?: string;
  walletSignature?: string;
  partnerLinkToken?: string;
};

export enum BankingAccountType {
  CHECKING = "CHECKING",
  SACINGS = "SAVINGS",
  CARD = "CARD",
}

export type BankingAccount = {
  id: string;
  type: BankingAccountType;
  name: string;
  last4Digits: string;
  preferred: boolean;
  status: string;
  supportsRTP: boolean;
  paymentInfoCount?: number;
};

const login = async (credentials: LoginCredentials) => {
  const payload: LoginPayload = {};

  if (credentials.magicAuthId) {
    payload.magicAuthId = credentials.magicAuthId;
    payload.magicAuthToken = credentials.magicAuthToken;
    payload.email = credentials.email;
    payload.emailVerified = credentials.emailVerified;
    payload.firstName = credentials.firstName;
    payload.lastName = credentials.lastName;
  }
  if (credentials.message) {
    payload.walletMessage = credentials.message;
    payload.walletSignature = credentials.signature;
  }

  if (credentials.partnerLinkToken) {
    payload.partnerLinkToken = credentials.partnerLinkToken;
  }

  const response = await httpClient.post<SignInResponseDto>(
    "users/login",
    payload,
    signInResponseSchema
  );
  if (response.isOk) {
    return response;
  } else {
    const error = response.error as any;
    throw new Error(error.message);
  }
};

const getUserKycInquiryId = async () => {
  const response = await getRequest("users/kyc");
  return response.data;
};

const logout = async () => {
  await getRequest("users/logout");
  utils.logout();
};

const logoutForEmbed = async () => {
  await getRequest("users/logout");
  utils.logoutForEmbed();
};

const refreshAuth = async () => {
  const response = await httpClient.post<SignInResponseDto>(
    "users/session/refresh",
    {},
    signInResponseSchema
  );
  return response;
};

const acceptTermsAndConditions = async (
  signedTimestamp: number
): AsyncResult<null, CallError> => {
  return await httpClient.post(`users/acceptTermsAndConditions`, {
    signedTimestamp,
    termsAccepted: [
      {
        vendor: "ZERO_HASH",
        version: "0.0.1",
      },
      {
        vendor: "ANSIBLE",
        version: "0.0.2",
      },
    ],
  });
};

const saveDebitCardInfo = async (
  skyflowId: string,
  cardToken: string,
  expMonthToken: string,
  expYearToken: string
) => {
  try {
    return postRequest(`users/bankingCredentials/card`, {
      skyflowId,
      cardToken,
      expMonthToken,
      expYearToken,
    });
  } catch (error) {
    throw new Error("Unable to save debit card");
  }
};

const getBankingInfo = async (): AsyncResult<any, CallError> => {
  return await httpClient.getJson<BankingInfoDto[]>("users/bankingCredentials");
};

const getBankingInfoById = async (id: string): AsyncResult<any, CallError> => {
  return await httpClient.getJson<BankingInfoDto>(
    `users/bankingCredentials/${id}`
  );
};

const updateBankingInfo = async (
  accountNumber: string,
  routingNumber: string
) => {
  const response = await postRequest(`users/bankingCredentials/account`, {
    accountNumber,
    routingNumber,
  });
  return response.data;
};

const updatePreferredBankingInfo = async (
  id: string,
  preferred: boolean
): AsyncResult<BankingInfoDto | null, CallError> => {
  return await httpClient.post(
    `users/bankingCredentials/preferred`,
    {
      id,
      preferred,
    },
    bankingInfoDtoSchema
  );
};

const deleteBankingInfo = async (id: string): AsyncResult<null, CallError> => {
  return await httpClient.post(`users/bankingCredentials/delete`, { id });
};

const updateWalletAddress = async (
  walletAddress: string,
  accountType: BlockchainAccountType
): AsyncResult<null, CallError> => {
  return await httpClient.post(`users/walletAddress`, {
    walletAddress,
    accountType,
  });
};

const addEmailAddress = async (
  emailAddress: string
): AsyncResult<null, CallError> => {
  return await httpClient.post(`users/emailAddress`, {
    emailAddress,
  });
};

const verifyUserAccountEmailAddress = async (
  otpCode: string
): AsyncResult<boolean, CallError> => {
  const response = await httpClient.post(
    `users/emailAddress/verify`,
    {
      otpCode,
    },
    emailVerifiedResponseSchema
  );
  return Result.mapOk((value) => value!!.emailVerified, response);
};

const resendVerificationCode = async (): AsyncResult<boolean, CallError> => {
  const response = await httpClient.post(
    `users/emailAddress/resendVerification`,
    {},
    emailVerificationSentResponseSchema
  );
  return Result.mapOk((value) => value!!.verificationSent, response);
};

const getPlaidLinkToken = async () => {
  const linkToken = await getRequest(`users/bankingCredentials/linkToken`);
  return linkToken.data;
};

const updateBankingInfoViaPlaid = async (publicToken: string) => {
  const response = await postRequest(`users/bankingCredentials/plaid`, {
    public_token: publicToken,
  });
  return response.data;
};

const getUserTasks = async (): Promise<UserTasksDto[]> => {
  const res = await getRequest(`users/tasks`);
  return res.data;
};

const getPartnerFromToken = async () => {
  const res = await getRequest(`users/partnerFromToken`);
  return res.data;
};

const sharePartnerAccess = async () => {
  const res = await postRequest("users/partners/access", {});
  return res.data;
};

export const userAccountQueryKey = ["userAccount"];
export const bankingInfoQueryKey = ["bankingInfo"];

export const usersApi = {
  getUserAccountById,
  acceptTermsAndConditions,
  login,
  logout,
  refreshAuth,
  updateBankingInfo,
  addEmailAddress,
  saveDebitCardInfo,
  getCurrentOnboardingStep,
  verifyUserAccountEmailAddress,
  resendVerificationCode,
  updateWalletAddress,
  getPlaidLinkToken,
  updateBankingInfoViaPlaid,
  getUserTasks,
  getBankingInfo,
  getBankingInfoById,
  bankingInfoQueryKey,
  logoutForEmbed,
  getPartnerFromToken,
  sharePartnerAccess,
  updatePreferredBankingInfo,
  deleteBankingInfo,
  getUserKycInquiryId,
};
