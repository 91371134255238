import { useEffect, useState, useCallback } from "react";
import { ApproveTokenArgs } from "@decent.xyz/box-hooks";
import {
  ChainId,
  ActionType,
  SwapDirection,
  BoxActionRequest,
  BoxActionResponse,
  EvmTransaction,
  Address,
  EvmAddress,
  bigintSerializer,
  bigintDeserializer,
  Transaction,
} from "@decent.xyz/box-common";
import {
  parseUnits,
  zeroAddress,
  SendTransactionParameters,
  // EstimateGasParameters,
  createWalletClient,
  createPublicClient,
  custom,
  http,
} from "viem";
import { base } from "viem/chains";
import {
  erc20ABI,
  readContract,
  writeContract,
  waitForTransaction,
} from "@wagmi/core";
import { useNetwork, useSwitchNetwork } from "wagmi";
import { User } from "../../models/user";
import { isProd as isProdEnv } from "../../utils";
import { CONTRACT_ADDRESSES } from "../../config/chains";
import { TxnHash } from "../../types";
import { getUserWalletAddress } from "../../utils/getUserWalletAddress";
import { DECENT_API_KEY } from "../../config";

export type BoxActionError = {
  message: string;
  stack: string;
};

type BoxProps = {
  amount: string;
  user: User;
  senderAddress: `0x${string}`;
  selectedNetwork: string;
  selectedCurrency: string;
  handleTransactionError: (error: any) => void;
  setTransactionHash: (hash: TxnHash) => void;
  setBoxTxnInProgress: (inProgress: boolean) => void;
};

const isProd = isProdEnv();
const BASE_URL = "https://box-v1.api.decent.xyz/api/getBoxAction";

const getTxnScanUrl = (
  hash: string,
  bridgeId: string
): `https://${string}` | "" => {
  bridgeId = bridgeId.toLowerCase();

  switch (bridgeId) {
    case "axelar":
      return isProd
        ? `https://axelarscan.io/gmp/${hash}`
        : `https://testnet.axelarscan.io/gmp/${hash}`;
    case "layerzero":
    case "stargate":
      return isProd
        ? `https://layerzeroscan.com/tx/${hash}`
        : `https://testnet.layerzeroscan.com/tx/${hash}`;
    case "wormhole":
      return isProd
        ? `https://wormholescan.io/#/tx/${hash}`
        : `https://wormholescan.io/#/tx/${hash}?network=TESTNET`;
    default:
      return "";
  }
};

const getAllowance = async ({
  user,
  token,
  spender,
  chainId,
}: {
  user: Address;
  spender: Address;
  token: Address;
  chainId: ChainId;
}) => {
  return await readContract({
    address: token as EvmAddress,
    abi: erc20ABI,
    functionName: "allowance",
    args: [user as EvmAddress, spender as EvmAddress],
    chainId,
  });
};

const approveToken = async (
  { token, spender, amount }: ApproveTokenArgs,
  chainId: ChainId
) => {
  const result = await writeContract({
    address: token as EvmAddress,
    abi: erc20ABI,
    functionName: "approve",
    args: [spender as EvmAddress, amount],
    chainId,
  });
  const receipt = await waitForTransaction({ hash: result.hash, chainId });
  return receipt.transactionHash;
};

export default function BoxTransaction({
  amount,
  senderAddress,
  user,
  selectedNetwork, // only BASE
  selectedCurrency, // ETH, USDC
  handleTransactionError,
  setTransactionHash,
  setBoxTxnInProgress,
}: BoxProps) {
  // const [gasEstimate, setGasEstimate] = useState(BigInt(0));
  // const [gasEstimateInProgress, setGasEstimateInProgress] = useState(false);
  const [txnInProgress, setTxnInProgress] = useState(false);
  const [fetchingActionResp, setFetchingActionResp] = useState(false);
  const [requireApproval, setRequireApproval] = useState(false);
  const [gainingApproval, setGainingApproval] = useState(false);
  const [activeTx, setActiveTx] = useState<BoxActionResponse | null>(null);
  const { chain: connectedChain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  // WARNING: Due to low liquidity on L2s, testnet transactions will probably fail with error 404 Resource not found
  // USDC.OPTIMISM and USDC.ARBITRUM are not supported in zerohash cert env, so test on mainnet or choose different tokens
  const srcChain = base;
  const srcChainId = ChainId.BASE;
  const dstChainId = ChainId.OPTIMISM;
  const srcToken =
    selectedCurrency === "ETH"
      ? zeroAddress
      : selectedCurrency === "USDC"
      ? CONTRACT_ADDRESSES.MAINNET.USDC_BASE_NATIVE
      : "";
  const dstToken = CONTRACT_ADDRESSES.MAINNET.USDC_OPTIMISM_NATIVE;
  const receiverAddress = getUserWalletAddress("USDC.OPTIMISM", user);
  const decimals =
    selectedCurrency === "USDC" || selectedCurrency === "USDT" ? 6 : 18;
  const slippage = 1;
  const actionType = ActionType.SwapAction;
  const swapDirection = SwapDirection.EXACT_AMOUNT_IN;
  const parsedAmount = parseUnits(amount, decimals);

  const publicClient = createPublicClient({
    chain: srcChain,
    transport: http(),
  });

  // get bridge and destination chain transaction receipt
  //   const { receipt: _dstTxReceipt } = useBridgeReceipt({
  //     bridgeId: actionResponse?.bridgeId,
  //     srcChainId,
  //     dstChainId,
  //     srcTxHash: srcChainTxnHash,
  //   });
  //   console.log("_dstTxReceipt:", _dstTxReceipt);
  //   const dstTxReceipt = _dstTxReceipt as TransactionReceipt;

  // const estimateGas = useCallback(
  //   async (actionResponse: BoxActionResponse) => {
  //     console.log("estimateGas");
  //     try {
  //       setGasEstimateInProgress(true);
  //       const tx = actionResponse.tx as EvmTransaction;
  //       // console.log("tx:", JSON.stringify(tx, null, 2));

  //       const params: EstimateGasParameters = {
  //         account: sender,
  //         ...tx,
  //         maxFeePerGas: undefined,
  //         maxPriorityFeePerGas: undefined,
  //       };

  //       // @ts-ignore
  //       // const gas = await publicClient.estimateGas(params);

  //       // setGasEstimate(gas);
  //       setGasEstimate(tx.gasLimit as bigint);
  //     } catch (e) {
  //       console.error(e);
  //       handleTransactionError(e);
  //     } finally {
  //       console.log("end gas est");
  //       setGasEstimateInProgress(false);
  //     }
  //   },
  //   [handleTransactionError, publicClient, sender]
  // );

  const checkForApproval = useCallback(
    async ({
      userAddress: user,
      actionResponse,
      srcChainId,
    }: {
      userAddress: Address;
      actionResponse: BoxActionResponse;
      srcChainId: ChainId;
    }) => {
      try {
        const { tokenPayment, tx } = actionResponse;

        if (!tokenPayment || !tx) {
          return false;
        }

        const { to: spender } = tx;
        const { isNative, amount, tokenAddress: token } = tokenPayment;

        if (isNative) {
          return false;
        }

        const allowance = await getAllowance({
          token,
          spender,
          user,
          chainId: srcChainId,
        });

        if (allowance < amount) {
          return true;
        }
      } catch (e) {
        handleTransactionError(e);
      }
    },
    [handleTransactionError]
  );

  const generateResponse = useCallback(
    async ({
      txConfig,
      account,
    }: {
      txConfig: BoxActionRequest;
      account: Address;
    }) => {
      let req;
      if (account) {
        req = txConfig;
      }

      const url = new URL(BASE_URL);
      url.searchParams.set("arguments", JSON.stringify(req, bigintSerializer));

      const requestOptions = {
        method: "GET",
        headers: {
          "x-api-key": DECENT_API_KEY,
        },
      };

      try {
        const response = await fetch(url.toString(), requestOptions);
        const textResponse = await response.text();
        const actionResponse: {
          success: boolean;
          error?: {
            name: string;
            code: number;
            message: string;
            title: string;
          };
          tx?: Transaction;
        } = JSON.parse(textResponse, bigintDeserializer);

        if (!actionResponse.tx) {
          throw new Error(actionResponse.error?.message);
        }

        return {
          config: req,
          response: actionResponse as BoxActionResponse,
        };
      } catch (e) {
        handleTransactionError(e);
        return {
          config: null,
          response: null,
        };
      }
    },
    [handleTransactionError]
  );

  const runApproval = useCallback(async () => {
    setGainingApproval(true);
    try {
      if (connectedChain?.id !== srcChainId && switchNetwork) {
        switchNetwork(srcChainId);
      }

      if (activeTx && activeTx.tokenPayment) {
        const hash = await approveToken(
          {
            token: srcToken,
            spender: activeTx.tx.to,
            amount: BigInt(parsedAmount),
          },
          srcChainId
        );

        if (hash) {
          await waitForTransaction({ hash });
        }
      }
    } catch (e) {
      handleTransactionError(e);
    } finally {
      setGainingApproval(false);
    }
  }, [
    connectedChain?.id,
    srcChainId,
    srcToken,
    switchNetwork,
    activeTx,
    parsedAmount,
    handleTransactionError,
  ]);

  const sendTransaction = useCallback(async () => {
    if (activeTx) {
      try {
        setTxnInProgress(true);

        const tx = activeTx.tx as EvmTransaction;

        const walletClient = createWalletClient({
          chain: srcChain,
          transport: custom(window.ethereum),
        });

        if (walletClient) {
          // @ts-ignore
          const txnParams: SendTransactionParameters = {
            account: senderAddress,
            ...tx,
          };

          // @ts-ignore
          const hash = await walletClient.sendTransaction(txnParams);

          // @ts-ignore
          const receipt = await publicClient.waitForTransactionReceipt({
            hash,
          });

          setTransactionHash({
            hash: receipt.transactionHash,
            scanUrl: getTxnScanUrl(
              receipt.transactionHash,
              activeTx.bridgeId ?? ""
            ),
          });
        }
      } catch (e) {
        handleTransactionError(e);
      }
    }
  }, [
    handleTransactionError,
    senderAddress,
    setTransactionHash,
    srcChain,
    publicClient,
    activeTx,
  ]);

  useEffect(() => {
    const init = async () => {
      if (senderAddress) {
        setFetchingActionResp(true);
        try {
          const { response } = await generateResponse({
            txConfig: {
              sender: senderAddress,
              srcChainId,
              dstChainId,
              srcToken,
              dstToken,
              slippage,
              actionType,
              actionConfig: {
                swapDirection,
                receiverAddress,
                amount: parsedAmount,
                chainId: dstChainId,
              },
            },
            account: senderAddress,
          });

          if (response?.tx) {
            setActiveTx(response);

            const needApproval = await checkForApproval({
              userAddress: senderAddress,
              actionResponse: response,
              srcChainId: srcChainId,
            });

            setRequireApproval(Boolean(needApproval));
          }
        } catch (e) {
          setActiveTx(null);
          handleTransactionError(e);
        } finally {
          setFetchingActionResp(false);
        }
      } else {
        setFetchingActionResp(false);
      }
    };

    if (!fetchingActionResp && !txnInProgress && !gainingApproval) {
      init();
    }
  }, [
    srcChainId,
    actionType,
    senderAddress,
    parsedAmount,
    swapDirection,
    checkForApproval,
    dstChainId,
    dstToken,
    generateResponse,
    handleTransactionError,
    fetchingActionResp,
    gainingApproval,
    txnInProgress,
    receiverAddress,
    srcToken,
  ]);

  useEffect(() => {
    if (
      activeTx &&
      requireApproval &&
      !txnInProgress &&
      !gainingApproval &&
      !fetchingActionResp
    ) {
      runApproval();
    }
  }, [
    requireApproval,
    activeTx,
    fetchingActionResp,
    gainingApproval,
    runApproval,
    txnInProgress,
  ]);

  useEffect(() => {
    if (
      activeTx &&
      !txnInProgress &&
      !requireApproval &&
      !gainingApproval &&
      !fetchingActionResp
    ) {
      sendTransaction();
    }
  }, [
    activeTx,
    txnInProgress,
    sendTransaction,
    requireApproval,
    gainingApproval,
    fetchingActionResp,
  ]);

  useEffect(() => {
    setBoxTxnInProgress(true);
    return () => {
      setBoxTxnInProgress(false);
    };
  });

  return <></>;
}
