import * as Yup from "yup";
import { useFormik } from "formik";
import { usersApi } from "../../../api";
import { useState } from "react";
import { Grid, Typography, Button, Link, Divider } from "@mui/material";
import { Loading } from "../../../components/Loading";

const validationSchema = Yup.object({
  ansibleTerms: Yup.bool().oneOf([true], "Field must be checked"),
  zeroHashTerms: Yup.bool().oneOf([true], "Field must be checked"),
});

export const Terms = ({ onComplete }: { onComplete: () => void }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      ansibleTerms: true,
      zeroHashTerms: true,
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const acceptedTime = Date.now();
      const [termsResponse] = await Promise.all([
        usersApi.acceptTermsAndConditions(acceptedTime),
      ]);
      if (termsResponse.isOk) {
        onComplete();
        setLoading(false);
      }
    },
  });

  if (loading) return <Loading />;

  return (
    <Grid
      container
      columns={12}
      alignItems="center"
      justifyItems="center"
      direction="column"
    >
      <Grid justifyContent="center" maxWidth={300} sx={{ my: 3 }}>
        <Typography variant="caption">
          By clicking “Continue” you agree to the
          <Link
            href="https://assets-global.website-files.com/63752ade0dbac24c119e17f7/65aabea3bddd48f25c2aea95_Ansible%20Labs%20Terms%20of%20Use_Jan%202024.pdf"
            target="_blank"
            underline="none"
          >
            {" "}
            Terms of Use{" "}
          </Link>
          and{" "}
          <Link
            href="https://uploads-ssl.webflow.com/63752ade0dbac24c119e17f7/64dba51dfb45dfb2ea18cbb3_Ansible%20Labs%20Privacy%20Policy.pdf"
            target="_blank"
            underline="none"
          >
            {" "}
            Privacy Policy{" "}
          </Link>
          for Ansible Labs.
        </Typography>
      </Grid>
      <Grid justifyContent="center" maxWidth={300}>
        <Typography variant="caption">
          By clicking “Sign in with Google” or by filling in your email address,
          you are explicitly accepting the
          <Link
            href="https://www.zerohash.com/ansible"
            target="_blank"
            underline="none"
          >
            {" "}
            Zero Hash and Zero Hash Liquidity Services User Agreement,{" "}
          </Link>
          and have read and understand the
          <Link
            href="https://zerohash.zendesk.com/hc/en-us/articles/360009900494-Privacy-Policy"
            target="_blank"
            underline="none"
          >
            {" "}
            Zero Hash Privacy Policy{" "}
          </Link>
          and
          <Link
            href="https://zerohash.zendesk.com/hc/en-us/articles/360008819314-State-Money-Transmitter-Disclosures"
            target="_blank"
            underline="none"
          >
            {" "}
            Regulatory Disclosures.{" "}
          </Link>
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="caption">
          I understand that the value of any cryptocurrency, including digital
          assets pegged to fiat currency, commodities, or any other asset, may
          go to zero.
        </Typography>
      </Grid>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        fullWidth
        onClick={() => formik.handleSubmit()}
        disabled={loading}
        sx={{ mt: 3 }}
      >
        Continue
      </Button>
    </Grid>
  );
};
