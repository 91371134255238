import * as Yup from "yup";
import { useFormik } from "formik";
import { Grid, Typography, Button, TextField } from "@mui/material";

import { usersApi } from "../../../api";
import { isValidEmail } from "../../../utils";
import { useState } from "react";
import { Loading } from "../../../components/Loading";

const validationSchema = Yup.object({
  emailAddress: Yup.string()
    .test(
      "isEmailValid",
      "Please enter a valid email address",
      (value = "") => {
        return isValidEmail(value);
      }
    )
    .required(),
});

export const EmailInfo = ({ onComplete }: { onComplete: () => void }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<string>("");
  console.log(emailError, emailErrorMsg);
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      emailAddress: "",
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      setLoading(true);
      const [emailResponse] = await Promise.all([
        usersApi.addEmailAddress(values.emailAddress),
      ]);
      if (!emailResponse.isOk) {
        const error = emailResponse.error as any;
        setEmailError(true);
        setEmailErrorMsg(error.message);
        setLoading(false);
      }
      if (emailResponse.isOk) {
        onComplete();
        setLoading(false);
      }
    },
  });

  return (
    <Grid
      container
      columns={12}
      alignItems="center"
      justifyItems="center"
      direction="column"
    >
      {loading && <Loading />}

      <Grid item alignItems="center" justifyContent="center">
        <Typography variant="h5" align="center" sx={{ mt: 14, mb: 1 }}>
          Enter your email address
        </Typography>
        <Typography variant="body1" align="center">
          We’ll send you a code so we can confirm that it’s you.
        </Typography>
        <TextField
          fullWidth
          sx={{ mt: 4 }}
          label="Email address"
          onChange={(e) => formik.setFieldValue("emailAddress", e.target.value)}
          value={formik.values.emailAddress}
          error={!!(formik.touched.emailAddress && formik.errors.emailAddress)}
        />
        <Button
          sx={{ mt: 3 }}
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          onClick={() => formik.handleSubmit()}
          disabled={!formik.isValid || loading}
        >
          Continue
        </Button>
      </Grid>
    </Grid>
  );
};
