import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Magic } from "magic-sdk";
import { OAuthExtension } from "@magic-ext/oauth";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDisconnect } from "wagmi";
import { Result } from "../../models/result";
import { usersApi, userAccountQueryKey } from "../../api";
import { logout } from "../../api/utils";
import { MAGIC_AUTH_PUBLIC_KEY } from "../../config";

import "./Menu.scss";

const magic = new Magic(MAGIC_AUTH_PUBLIC_KEY, {
  extensions: [new OAuthExtension()],
});

function MenuView() {
  const { data: userResult } = useQuery(userAccountQueryKey, async () => {
    return await usersApi.getUserAccountById();
  });
  const user = userResult && Result.getOrNull(userResult);
  const navigate = useNavigate();
  const { disconnectAsync } = useDisconnect();

  return (
    <Grid container direction={"column"} classes={{ root: "menu-fullscreen" }}>
      {/* todo: create shared component/hook for back */}
      <Grid item classes={{ root: "menu-back-row" }}>
        <IconButton
          classes={{ root: "menu-back-btn" }}
          onClick={() => navigate("/dashboard")}
        >
          <ArrowBackIcon fontSize="medium" />
        </IconButton>
        <Typography variant="body1" classes={{ root: "menu-back-text" }}>
          Back
        </Typography>
      </Grid>
      <Grid item direction={"column"} classes={{ root: "menu" }}>
        <Grid item classes={{ root: "menu-user-info" }}>
          <Typography>{`${user?.firstName} ${user?.lastName}`}</Typography>
          <Typography variant="body2" classes={{ root: "menu-email-address" }}>
            {user?.emailAddress}
          </Typography>
        </Grid>
        <Divider classes={{ root: "menu-divider" }} />
        <Grid
          item
          classes={{ root: "menu-row" }}
          onClick={() => navigate("/settings")}
        >
          <IconButton classes={{ root: "menu-icon" }}>
            <SettingsIcon fontSize="small" />
          </IconButton>
          <Typography classes={{ root: "menu-list-item-text" }}>
            Settings
          </Typography>
        </Grid>
        <Grid
          item
          classes={{ root: "menu-row" }}
          onClick={() => navigate("/profile")}
        >
          <IconButton classes={{ root: "menu-icon" }}>
            <PersonIcon fontSize="small" />
          </IconButton>
          <Typography classes={{ root: "menu-list-item-text" }}>
            Profile
          </Typography>
        </Grid>
        <Grid
          item
          classes={{ root: "menu-row" }}
          onClick={() => navigate("/bank-info")}
        >
          <IconButton classes={{ root: "menu-icon" }}>
            <AccountBalanceIcon fontSize="small" />
          </IconButton>
          <Typography classes={{ root: "menu-list-item-text" }}>
            Bank account
          </Typography>
        </Grid>
        <Divider classes={{ root: "menu-divider" }} />
        <Grid
          item
          classes={{ root: "menu-row" }}
          onClick={async () => {
            logout();
            await usersApi.logout();
            const isLoggedInWithMagic = await magic.user.isLoggedIn();
            if (isLoggedInWithMagic) await magic.user.logout();
            if (disconnectAsync) await disconnectAsync();
          }}
        >
          <IconButton classes={{ root: "menu-icon" }}>
            <LogoutIcon fontSize="small" />
          </IconButton>
          <Typography classes={{ root: "menu-list-item-text" }}>
            Sign out
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MenuView;
