import { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import IconButton from "@mui/material/IconButton";
import { Grid, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import { Loading } from "../../components/Loading";
import { usersApi } from "../../api";

import useQueryHook from "../../hooks/useQuery";
import { Terms } from "../Onboarding/Terms/Terms";
import { Payment } from "../Onboarding/Payment/Payment";
import Kyc from "../Onboarding/Kyc/Kyc";
import { onboardingApi } from "../../api";
import { OnboardingSteps } from "../Onboarding/Onboarding";
import { SevereRiskWalletWarning } from "../Onboarding/SeverRiskWalletWarning/SevereRiskWalletWarning";
import { AwaitingReview } from "../Onboarding/AwaitingReview/AwaitingReview";
import { saveSessionMetadata } from "../../utils/tokenRepository";
import { UserConfirmation } from "./UserConfirmation";
import { OnboardingComplete } from "./OnboardingComplete";

const _Embedded = () => {
  const [error, setError] = useState<string | null>(null);
  const [processing] = useState<boolean>(false);
  const expectedNextStep = useRef<string | undefined>(undefined);
  const [retryInterval, setRetryInterval] = useState<number>(0);
  const [showOnboaridngCompleteMessage, setShowOnboardingCompleteMessage] =
    useState(false);
  const [paymentAdded, setPaymentAdded] = useState(false);
  let query = useQueryHook();

  const linkToken = query.get("linkToken") || query.get("token") || "";
  const isStandAlone = query.get("standalone") === "true";
  const rerouteTo = query.get("rerouteTo") || null;
  const screen = query.get("screen") || null;

  // get user from linkToken

  const { data: onboardingInfo } = useQuery(
    ["onboardingApi.getOnboardingInfo"],
    onboardingApi.getOnboardingInfo,
    {
      enabled: retryInterval === 0 ? false : true,
      refetchInterval: retryInterval,
      onSuccess: (onboardingInfo) => {
        // we want to stop the polling if the user has been declined or they are awaiting a case review
        if (
          onboardingInfo.currentStep === OnboardingSteps.KYC_DECLINED ||
          onboardingInfo.currentStep === OnboardingSteps.AWAITING_KYC_REVIEW ||
          onboardingInfo.currentStep === OnboardingSteps.ONBOARDING_COMPLETE ||
          expectedNextStep.current === onboardingInfo.currentStep
        ) {
          setRetryInterval(0);
        }
      },
    }
  );
  const onboardingStep = onboardingInfo?.currentStep;

  // link token create
  // server to server with email, wallet, name, etc...
  // creates user account and onboarding status projection

  useEffect(() => {
    if (!linkToken) {
      return;
    }

    usersApi
      .login({ partnerLinkToken: linkToken })
      .then((jwt) => {
        saveSessionMetadata(jwt?.value);
        setRetryInterval(1000);
      })
      .catch(() => {
        setError("Issue with Link token");
      });
  }, [linkToken]);

  const closeOnSuccess = (publicToken: string | undefined) => {
    window.parent.postMessage(
      JSON.parse(
        JSON.stringify({
          eventName: "beamIframeCloseOnSuccess",
          publicToken: publicToken || "",
        })
      ),
      "*"
    );
    if (rerouteTo) {
      window.location.replace(`${rerouteTo}?userId=${publicToken}`);
    }

    setShowOnboardingCompleteMessage(true);
    expectedNextStep.current = OnboardingSteps.ONBOARDING_COMPLETE;
    setRetryInterval(1000);
  };

  const close = () => {
    window.parent.postMessage(
      JSON.parse(JSON.stringify({ eventName: "beamIframeClose" })),
      "*"
    );
  };

  const createOnComplete = (onboardingNextStep: string) => {
    return () => {
      expectedNextStep.current = onboardingNextStep;
      setRetryInterval(1000);
    };
  };

  // show login form
  return (
    <>
      {processing && <Loading />}
      <Grid container direction="row" justifyContent="flex-end">
        {!isStandAlone && (
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        )}
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        {error && (
          <Typography variant="h5" align="center" sx={{ mt: 14 }}>
            {error}
          </Typography>
        )}
        {linkToken && (
          <>
            {onboardingStep === OnboardingSteps.ACCEPT_TERMS && (
              <Terms
                onComplete={createOnComplete(
                  OnboardingSteps.COLLECT_IDENTITY_INFO
                )}
              />
            )}
            {onboardingStep === OnboardingSteps.COLLECT_PAYMENT_CREDENTIAL && (
              <Payment onComplete={(val) => closeOnSuccess(val)} />
            )}
            {onboardingStep === OnboardingSteps.COLLECT_IDENTITY_INFO && (
              <Kyc
                onComplete={createOnComplete(
                  OnboardingSteps.COLLECT_PAYMENT_CREDENTIAL
                )}
              />
            )}
            {onboardingStep === OnboardingSteps.KYC_DECLINED && (
              <SevereRiskWalletWarning />
            )}
            {onboardingStep === OnboardingSteps.AWAITING_KYC_REVIEW && (
              <AwaitingReview />
            )}
            {onboardingStep === OnboardingSteps.ONBOARDING_COMPLETE &&
              showOnboaridngCompleteMessage && <OnboardingComplete />}
            {onboardingStep ===
              OnboardingSteps.PENDING_BEAM_ACCOUNT_CREATION && <Loading />}
            {onboardingStep === OnboardingSteps.ONBOARDING_COMPLETE &&
              !showOnboaridngCompleteMessage &&
              !screen && (
                <UserConfirmation
                  onComplete={(publicToken) => {
                    if (publicToken) {
                      closeOnSuccess(publicToken);
                    }
                  }}
                />
              )}
            {screen === "paymentMethods" && !paymentAdded && (
              <Payment
                onComplete={(val) => {
                  setPaymentAdded(true);
                  closeOnSuccess(val);
                }}
              />
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export const Embedded = withLDConsumer()(_Embedded);
