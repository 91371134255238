import Joi from "joi";
import { Result } from "../models/result";
import { httpClient } from "./httpClient";

type Rates = {
  payoutValue: any;
  marketPricePerUnit: any;
  currency: string;
  value: number;
  plain: string;
  overrideTransactionValue?: number;
  expectedTradingMarginPlusFees: number;
  overrideExpectedTradingMarginPlusFees?: number;
};

const exchangeRatesDto: Joi.ObjectSchema<Rates> = Joi.object({
  currency: Joi.string().required(),
  value: Joi.number().required(),
  plain: Joi.string().required(),
  payoutValue: Joi.number().allow(null),
  overrideTransactionValue: Joi.number().allow(null),
  expectedTradingMarginPlusFees: Joi.number().required(),
  overrideExpectedTradingMarginPlusFees: Joi.number().allow(null),
  marketPricePerUnit: Joi.number().allow(null),
});

async function getExchangeRates(
  symbol: string,
  quantity: string
): Promise<Rates | null> {
  const response = await httpClient.getJson<Rates>(
    `rates?symbol=${symbol}&quantity=${quantity}`,
    exchangeRatesDto
  );
  const result = Result.mapOk((value) => {
    const rates: Rates = {
      currency: value.currency,
      value: value.value,
      plain: value.plain,
      overrideTransactionValue: value.overrideTransactionValue,
      expectedTradingMarginPlusFees: value.expectedTradingMarginPlusFees,
      overrideExpectedTradingMarginPlusFees:
        value.overrideExpectedTradingMarginPlusFees,
      marketPricePerUnit: value.marketPricePerUnit,
      payoutValue: value.payoutValue,
    };
    return rates;
  }, response);
  return result && Result.getOrNull(result);
}

export const ratesApi = {
  getExchangeRates,
};
