import { useQuery } from "@tanstack/react-query";
import { Divider, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { BeamSupportedZeroHashAsset } from "../../types";
import { ratesApi } from "../../api";
import "./PayoutEstimator.scss";
import { useCallback } from "react";

type Props = {
  onChange?: (selectedCurrency: string, amount: string) => any;
  amount?: string;
  selectedCurrency?: BeamSupportedZeroHashAsset;
  selectedCurrencyLabel?: string;
  onAmountChange?: (event: any) => void;
  onSelectedCurrencyChange?: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  assetOptions?: { label: string; value: string }[];
  supportedAssetsLoaded?: boolean;
  selectedNetwork?: string;
  onSelectedNetworkChange?: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  networkOptions: { label: string; value: string }[];
};

export function PayoutEstimator({
  amount = "",
  selectedCurrency = "" as BeamSupportedZeroHashAsset,
  selectedCurrencyLabel = "",
  assetOptions = [],
  supportedAssetsLoaded,
  onAmountChange = (event: any) => {},
  onSelectedCurrencyChange = () => {},
  selectedNetwork,
  onSelectedNetworkChange,
  networkOptions,
}: Props) {
  const getZerohashAsset = useCallback(() => {
    let network = selectedNetwork;

    // use zerohash api to get exchange rates for base
    if (network === "BASE") {
      network = "ETH"; // zerohash format
    }

    return !network || selectedCurrency === network
      ? selectedCurrency
      : `${selectedCurrency}.${network}`;
  }, [selectedNetwork, selectedCurrency]);

  // get exchange rate and processing fee
  const { data: rate } = useQuery(
    ["exchangeRates", selectedCurrency, selectedNetwork],
    async () => {
      return selectedCurrency
        ? await ratesApi.getExchangeRates(getZerohashAsset(), "1")
        : null;
    }
  );
  const rateLoaded = typeof rate?.value === "number";
  const exchangeRate = rate?.marketPricePerUnit || 0;
  const price = (exchangeRate * parseFloat(!!amount ? amount : "0")).toFixed(2);

  const { data: estimate } = useQuery(
    ["estimate", selectedCurrency, amount],
    async () => {
      return selectedCurrency && amount && amount !== "0"
        ? await ratesApi.getExchangeRates(getZerohashAsset(), amount)
        : null;
    }
  );
  const transactionFee = estimate?.overrideExpectedTradingMarginPlusFees
    ? estimate.overrideExpectedTradingMarginPlusFees
    : estimate?.expectedTradingMarginPlusFees ?? 0;
  const payout = estimate?.overrideTransactionValue
    ? estimate.overrideTransactionValue
    : estimate?.value ?? 0;

  const onAmountBlur = useCallback(() => {
    if (!amount) onAmountChange({ target: { value: "0" } });
  }, [amount, onAmountChange]);

  return (
    <>
      <Grid
        container
        columns={12}
        alignItems="center"
        justifyItems="center"
        classes={{ root: "payout-estimator-container" }}
        spacing={1}
      >
        <Grid
          item
          xs={6}
          classes={{ root: "payout-estimator-currency-select-container" }}
        >
          <TextField
            select
            disabled={!supportedAssetsLoaded}
            value={selectedNetwork}
            onChange={onSelectedNetworkChange}
            fullWidth={true}
            classes={{ root: "payout-estimator-currency-select" }}
            label="Network"
          >
            {networkOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                selected={option.value === selectedNetwork}
                classes={{ root: "payout-estimator-currency-select-item" }}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{}}
          classes={{ root: "payout-estimator-currency-select-container" }}
        >
          <TextField
            select
            disabled={!supportedAssetsLoaded}
            value={selectedCurrency}
            onChange={onSelectedCurrencyChange}
            fullWidth={true}
            classes={{ root: "payout-estimator-currency-select" }}
            label={"Asset"}
          >
            {assetOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                selected={option.value === selectedCurrency}
                classes={{ root: "payout-estimator-currency-select-item" }}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid
          item
          xs={12}
          classes={{ root: "payout-estimator-currency-amount-container" }}
        >
          <TextField
            disabled={!(rateLoaded && supportedAssetsLoaded)}
            variant="outlined"
            value={amount}
            onChange={onAmountChange}
            onBlur={onAmountBlur}
            fullWidth={true}
            classes={{
              root: "payout-estimator-currency-amount",
            }}
            InputProps={{
              classes: { focused: "payout-estimator-currency-amount-focused" },
            }}
            label="Amount"
            sx={{ mt: 1 }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="caption"
            classes={{ root: "payout-estimator-exchange-rate-label" }}
          >
            Exchange rate
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            align={"right"}
            variant="caption"
            paragraph={true}
            sx={{ mb: 0 }}
            classes={{ root: "payout-estimator-exchange-rate-value" }}
          >
            {rateLoaded && supportedAssetsLoaded
              ? `1 ${selectedCurrencyLabel} = $${
                  exchangeRate.toFixed(2) || "0.00"
                }`
              : "-"}
          </Typography>
        </Grid>
        <Divider style={{ width: "100%" }} sx={{ mt: 1 }} />
        <Grid item xs={5}>
          <Typography variant="body2">Price</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography align={"right"} variant="body2">
            {rateLoaded ? `$${price}` : "-"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">Transaction fee*</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align={"right"} variant="body2">
            {rateLoaded ? `$${transactionFee?.toFixed(2)}` : "-"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">Estimated payout</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align={"right"} variant="body2">
            {rateLoaded ? `$${payout.toFixed(2)}` : "-"}
            {/* amount && parseFloat(amount) > 0 &&  */}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            align={"right"}
            variant="body2"
            classes={{ root: "estimator-see-terms-text" }}
          >
            *See terms for more details
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
