import {
  BeamSupportedZeroHashAsset,
  BEAM_SUPPORTED_ZEROHASH_ASSETS,
} from "../types";

// Input values from assetSupportedChains in Dashboard.tsx
//  {
//     AVAX: ['AVAX']
//     BTC: ['BTC']
//     DOGE: ['DOGE']
//     ETH: ['ETH', 'ZORA']
//     MATIC: ['POLYGON']
//     SOL: ['SOL']
//     USDC: ['ETH', 'POLYGON', 'SOL', 'AVAX', 'ARBITRUM', 'OPTIMISM']
//     XRP: ['XRP']
// }
export function getZeroHashAssetFromTokenAndNetwork(
  token: string,
  network: string
): BeamSupportedZeroHashAsset {
  if (!token) {
    throw new Error("No token provided");
  }

  if ((!!token && !network) || token === network) {
    if (
      BEAM_SUPPORTED_ZEROHASH_ASSETS.includes(
        token as BeamSupportedZeroHashAsset
      )
    ) {
      return token as BeamSupportedZeroHashAsset;
    }
    throw new Error(`Unsupported asset ${token}`);
  }

  if (!!token && !!network) {
    const asset = `${token}.${network}` as BeamSupportedZeroHashAsset;
    if (BEAM_SUPPORTED_ZEROHASH_ASSETS.includes(asset)) {
      return asset as BeamSupportedZeroHashAsset;
    }
    throw new Error(`Unsupported asset ${asset}`);
  }

  throw new Error(
    `Could not resolve asset from token ${token} and network ${network}`
  );
}
