import { useEffect, useState } from "react";
import Persona from "persona";
import { kycApi, onboardingApi, usersApi } from "../../../api";
import { PERSONA_ENVIRONMENT, PERSONA_TEMPLATE_ID } from "../../../config";
import { getUserAccountId } from "../../../utils/getUserAccountId";
import { Grid } from "@mui/material";
import { Loading } from "../../../components/Loading";
import { getPersonaInquiryOptionsFields } from "../../../types";

const Kyc = ({ onComplete }: { onComplete: () => void }) => {
  const [personaInquiryId, setPersonaInquiryId] = useState<null | string>(null);
  const [prevId, setPrevId] = useState<null | string>(null);
  const userAccountId = getUserAccountId();
  const [email, setEmail] = useState("");
  const [riskScore, setRiskScore] = useState<number>(2);
  const [loading, setLoading] = useState<boolean>(false);

  const inquiryOptionsFields = getPersonaInquiryOptionsFields({
    emailAddress: email,
    trmWalletRiskScore: riskScore,
  });

  useEffect(() => {
    setLoading(true);
    Promise.allSettled([
      usersApi.getUserAccountById(),
      onboardingApi.getAddressRiskScore(),
    ])
      .then(([userResponse, riskResponse]) => {
        if (
          userResponse.status === "fulfilled" &&
          userResponse?.value?.isOk &&
          userResponse?.value?.value?.emailAddress
        ) {
          setEmail(userResponse?.value?.value?.emailAddress);
        }
        if (riskResponse.status === "fulfilled") {
          setRiskScore(riskResponse?.value);
        }
      })
      .catch((err) =>
        console.log("Error getting user profile and risk score", err)
      )
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const waitForPersonaVerification = async () => {
      if (personaInquiryId) {
        await kycApi.getPersonaConfirmation(personaInquiryId);
      }
      onComplete();
    };
    if (personaInquiryId && personaInquiryId !== prevId) {
      setPrevId(personaInquiryId);
      waitForPersonaVerification();
    }
  }, [personaInquiryId, prevId, onComplete]);

  if (loading) return <Loading />;

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Persona.Inquiry
        referenceId={String(userAccountId)}
        templateId={PERSONA_TEMPLATE_ID}
        environment={PERSONA_ENVIRONMENT}
        fields={inquiryOptionsFields}
        onLoad={() => {
          console.log("Loaded inline");
        }}
        onComplete={async ({ inquiryId }) => {
          // persona widget invokes this callback multiple times
          // this causes event stream version mismatch conflicts
          // so, until persona fixes the callback, use this React workaround
          setPersonaInquiryId(inquiryId);
        }}
        onError={(err) => console.log("err", err)}
        onEvent={(event) => console.log("event", event)}
        onReady={() => setLoading(false)}
      />
    </Grid>
  );
};

export default Kyc;
