import { useState, useEffect } from "react";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { useLocation } from "react-router-dom";
import { Button, Grid, Typography, IconButton, Link } from "@mui/material";
import ArrowBackFilledIcon from "@mui/icons-material/ArrowBack";
import { usersApi } from "../../../api";
import { usePlaidLink } from "react-plaid-link";
import { AchForm } from "./AchForm";
import { Loading } from "../../../components/Loading";

// const validationSchema = Yup.object({
//   cardNumber: Yup.string().required(),
//   cardExpiry: Yup.string().required(),
//   cardCode: Yup.string().required(),
// });

const _BankSelection = ({
  onComplete,
  goBack,
}: {
  onComplete: (publicToken?: string) => void;
  goBack: () => void;
}) => {
  const location = useLocation();
  const isPaymentView = location?.pathname === "/payment";
  const [linkToken, setLinkToken] = useState<null | string>(null);
  const [linkManually, setLinkManually] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    usersApi.getPlaidLinkToken().then((res) => {
      setLinkToken(res);
    });
  }, []);

  const updateBanking = async (public_token: string) => {
    setLoading(true);
    const res = await usersApi.updateBankingInfoViaPlaid(public_token);
    onComplete(res);
  };

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: (public_token, metadata) => {
      updateBanking(public_token);
      // send public_token to server
    },
    onExit: (error) => {
      console.log(error);
    },
  });

  if (loading) return <Loading />;

  return (
    <>
      {linkManually && (
        <AchForm
          onComplete={(publicToken) => onComplete(publicToken)}
          goBack={() => setLinkManually(false)}
        />
      )}
      {!linkManually && (
        <>
          {isPaymentView && (
            <Grid
              container
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{ mb: 3, mt: 3 }}
            >
              <IconButton
                size="small"
                aria-label="delete"
                style={{ alignSelf: "flex-start" }}
                onClick={goBack}
                sx={{ p: 1, mr: 1 }}
              >
                <ArrowBackFilledIcon />
              </IconButton>
              <Typography variant="body1">Back</Typography>
            </Grid>
          )}

          <Typography variant="h6" align="center">
            Link bank account
          </Typography>
          <Typography variant="body2" align="center" sx={{ my: 2 }}>
            We use Plaid to link your bank account to Beam. Plaid securely
            connects Beam to your bank and you decide who can see it.
          </Typography>
          <Typography variant="body2" align="center">
            You can turn off Beam’s Plaid connection to this bank by removing
            the bank account.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ mt: 4 }}
            fullWidth
            disabled={!ready || loading}
            onClick={() => open()}
          >
            ADD Bank Account
          </Button>
          <Grid
            container
            sx={{ mt: 3 }}
            flexDirection="row"
            justifyContent="center"
          >
            <Link color="inherit" onClick={() => setLinkManually(true)}>
              Verify bank manually instead
            </Link>
          </Grid>
        </>
      )}
    </>
  );
};

export const BankSelection = withLDConsumer()(_BankSelection);
