import Joi from "joi";

type PersonaEnvironment = "sandbox" | "production" | undefined;

export const BACKEND_URL: string = process.env.REACT_APP_BACKEND_URL as string;
export const PERSONA_TEMPLATE_ID: string = process.env
  .REACT_APP_PERSONA_TEMPLATE_ID as string;
export const PERSONA_ENVIRONMENT: PersonaEnvironment = process.env
  .REACT_APP_PERSONA_ENVIRONMENT as PersonaEnvironment;
export const FULLSTORY_ORG_ID: string = process.env
  .REACT_APP_FULLSTORY_ORG_ID as string;
export const MAGIC_AUTH_PUBLIC_KEY: string = process.env
  .REACT_APP_MAGIC_AUTH_PUBLIC_KEY as string;
export const PLAID_WALLET_LINK: string = process.env
  .REACT_APP_PLAID_WALLET_LINK as string;
export const LAUNCH_DARKLY_CLIENT_ID: string = process.env
  .REACT_APP_LAUNCH_DARKLY_CLIENT_ID as string;
export const SKYFLOW_VAULT_ID: string = process.env
  .REACT_APP_SKYFLOW_VAULT_ID as string;
export const SKYFLOW_VAULT_URL: string = process.env
  .REACT_APP_SKYFLOW_VAULT_URL as string;
export const SKYFLOW_CARDS_TABLE: string = process.env
  .REACT_APP_SKYFLOW_CARDS_TABLE as string;
export const SOLANA_RPC_ENDPOINT: string = process.env
  .REACT_APP_SOLANA_RPC_ENDPOINT as string;
export const DECENT_API_KEY: string = process.env
  .REACT_APP_DECENT_API_KEY as string;

export const validateConfig = async () => {
  const configSchema = Joi.object({
    REACT_APP_BACKEND_URL: Joi.string().required(),
    REACT_APP_PERSONA_TEMPLATE_ID: Joi.string().required(),
    REACT_APP_PERSONA_ENVIRONMENT: Joi.string()
      .allow("sandbox", "production")
      .required(),
    REACT_APP_FULLSTORY_ORG_ID: Joi.string().optional(),
    REACT_APP_MAGIC_AUTH_PUBLIC_KEY: Joi.string().optional(),
    REACT_APP_PLAID_WALLET_LINK: Joi.string().required(),
    REACT_APP_LAUNCH_DARKLY_CLIENT_ID: Joi.string().required(),
    REACT_APP_SKYFLOW_VAULT_ID: Joi.string().required(),
    REACT_APP_SKYFLOW_VAULT_URL: Joi.string().required(),
    REACT_APP_SKYFLOW_CARDS_TABLE: Joi.string().required(),
    REACT_APP_SOLANA_RPC_ENDPOINT: Joi.string().required(),
    REACT_APP_DECENT_API_KEY: Joi.string().required(),
  }).unknown();

  try {
    await configSchema.validateAsync(process.env);
  } catch (err) {
    throw new Error(
      `Environment variables config validation failed with message: ${err}`
    );
  }
};
