import { PublicKey } from "@solana/web3.js";

function isValidSolAddress(addr: string) {
  try {
    // if the address is not a valid public key it throws an error, invalid public key
    const key = new PublicKey(addr);
    return key.toBase58() === addr;
  } catch (error) {
    return false;
  }
}

export { isValidSolAddress };
