import { useMemo, useState } from "react";
import List from "@mui/material/List";
import Alert from "@mui/material/Alert";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Divider, Grid, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useFeatureFlag from "../../hooks/useFeatureFlag";
import {
  BlockchainAccountType,
  BlockchainFullNames,
  BlockchainNativeToken,
  ChainConfig,
  BEAM_SUPPORTED_ZEROHASH_ASSETS,
  BeamSupportedZeroHashAsset,
} from "../../types";
import { User } from "../../models/user";
import "./PayoutAddresses.scss";
import { Loading } from "../Loading";

type Props = {
  user?: User | null;
  chainConfig: ChainConfig | null;
};

export default function PayoutAddresses({ user, chainConfig }: Props) {
  const [copiedIndex, setCopiedIndex] = useState<number>(-1);

  // These chains will show in the list
  const supportedChains = useMemo(
    () => Object.keys(chainConfig ?? {}),
    [chainConfig]
  ).filter((x) => x !== "XLM" && x !== "BASE" && x !== "ALGO" && x !== "ZORA");

  const copy = async (address: string, index: number) => {
    await navigator.clipboard.writeText(address);
    setCopiedIndex(index);
  };

  const AddressRow = ({
    chain,
    copied,
    index,
  }: {
    chain: BlockchainAccountType;
    copied: boolean;
    index: number;
  }) => {
    const showCopiedAddressIcon = Boolean(
      useFeatureFlag("show-copied-address-icon", false)
    );
    if (chain === BlockchainAccountType.ALGO) {
      return null;
    }

    const usdcSupport = BEAM_SUPPORTED_ZEROHASH_ASSETS.includes(
      `USDC.${chain}` as BeamSupportedZeroHashAsset
    );
    const label = BlockchainFullNames[chain];
    const nativeToken = BlockchainNativeToken[chain];
    const nativeTokenSupport =
      BEAM_SUPPORTED_ZEROHASH_ASSETS.includes(
        `${chain}` as BeamSupportedZeroHashAsset
      ) ||
      BEAM_SUPPORTED_ZEROHASH_ASSETS.includes(
        `${nativeToken}.${chain}` as BeamSupportedZeroHashAsset
      );
    const Icon = chainConfig?.[chain]?.Icon;

    return (
      <div key={chain}>
        {!!chainConfig ? (
          <>
            <ListItem
              key={chain}
              sx={{ width: "100%", pl: 0, pr: 0, pt: 0.5, pb: 0.5 }}
              secondaryAction={
                chainConfig?.[chain]?.address ? (
                  <Button
                    size="small"
                    variant="outlined"
                    color={copied ? "success" : "inherit"}
                    onClick={() => copy(chainConfig?.[chain]?.address, index)}
                    disabled={!chainConfig?.[chain]?.address}
                  >
                    {copied ? "Copied" : "Copy"}
                  </Button>
                ) : (
                  <div style={{ paddingTop: 5, paddingRight: 20 }}>
                    <CircularProgress size={25} />
                  </div>
                )
              }
            >
              <ListItemAvatar>{Icon ? <Icon /> : null}</ListItemAvatar>
              <ListItemText
                primary={label}
                primaryTypographyProps={{ variant: "body2" }}
                secondary={`Accepts ${
                  nativeTokenSupport ? `${nativeToken}` : ""
                }${nativeTokenSupport && usdcSupport ? " & " : ""}${
                  usdcSupport ? "Native USDC" : ""
                }`}
                secondaryTypographyProps={{ variant: "caption" }}
              />
            </ListItem>
            {copied && (
              <Alert
                icon={
                  showCopiedAddressIcon ? <CheckCircleOutlineIcon /> : false
                }
                severity="success"
                sx={{
                  width: "calc(100% - 32px)",
                  marginTop: 1,
                  marginBottom: 1,
                  wordBreak: "break-word",
                }}
              >
                {chainConfig?.[chain]?.address}
              </Alert>
            )}
          </>
        ) : (
          <Loading />
        )}
      </div>
    );
  };

  // detect if user is brand new
  const isNewUser = Boolean(
    supportedChains.filter((wallet) => {
      const address = chainConfig?.[wallet]?.address;
      return address !== undefined && address !== "";
    }).length === 0
  );

  // link to what native USDC is
  const nativeUSDCUrl =
    "https://www.circle.com/blog/bridged-usdc-standard#:~:text=Native%20USDC%20vs.,1%3A1%20for%20US%20dollars";

  // return the address display
  return (
    <>
      <Grid
        container
        width="100%"
        direction="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <Typography
          variant="h6"
          align="center"
          classes={{ root: "dashboard-section-title" }}
        >
          Payout address
        </Typography>

        {isNewUser ? (
          <Alert
            severity="info"
            sx={{ width: "calc(100% - 32px)", marginTop: 1, marginBottom: 1 }}
          >
            Your new Beam Addresses are being generated!
          </Alert>
        ) : (
          <Typography
            variant="caption"
            align="left"
            classes={{ root: "payout-address-instructions" }}
          >
            Copy your address and send the desired payout amount from your
            preferred wallet.{" "}
            <b>
              Only{" "}
              <a target="_blank" rel="noopener noreferrer" href={nativeUSDCUrl}>
                Native USDC
              </a>{" "}
              is supported at this time
            </b>
          </Typography>
        )}
      </Grid>
      <Divider style={{ width: "100%" }} sx={{ mt: 1 }} />
      <Grid
        container
        width="100%"
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 0 }}
      >
        <List sx={{ width: "100%", pt: 0, pb: 0 }}>
          {supportedChains.map((chain, index) => {
            // TODO: make this smarter later (index 2 is when alphabetical sorting begins currently hardcoded)
            if (index === 2) {
              return (
                <div key={index}>
                  <Divider style={{ width: "100%" }} sx={{ mt: 1 }} />
                  <AddressRow
                    chain={chain as BlockchainAccountType}
                    copied={copiedIndex === index}
                    index={index}
                  />
                </div>
              );
            }
            return (
              <div key={index}>
                <AddressRow
                  chain={chain as BlockchainAccountType}
                  copied={copiedIndex === index}
                  index={index}
                />
              </div>
            );
          })}
        </List>
      </Grid>
    </>
  );
}
