import { useQuery } from "@tanstack/react-query";
import { useEffect, useState, useMemo } from "react";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { Grid, Typography, Divider, Link } from "@mui/material";

import { transactionsQueryKey, transactionsApi } from "../../api";
import { userAccountQueryKey, usersApi } from "../../api";
import { Result } from "../../models/result";
import { TransactionsPreview } from "../../components/TransactionsPreview/TransactionPreview";
import { CashOut } from "../../components/CashOut/CashOut";
import PayoutAddresses from "../../components/PayoutAddresses/PayoutAddresses";
import {
  getChainConfig,
  mapChainsToSupportedAssets,
  getAssetOptions,
  getNetworkOptions,
} from "../../config/chains";
import { assetsApi } from "../../api";
import { BeamSupportedZeroHashAsset } from "../../types";
import "./Dashboard.scss";

const amountRegex = /^[0-9]{0,78}\.?[0-9]{0,8}$/;

const _Dashboard = () => {
  const [amount, setAmount]: [string, Function] = useState("0");
  const [retryInterval] = useState<number>(5000);
  const [selectedCurrency, setSelectedCurrency]: [
    BeamSupportedZeroHashAsset,
    Function
  ] = useState("" as BeamSupportedZeroHashAsset);
  const [selectedCurrencyLabel, setSelectedCurrencyLabel]: [string, Function] =
    useState("");
  const [selectedNetwork, setSelectedNetwork]: [string, Function] =
    useState("");
  const [networkOptions, setNetworkOptions]: [
    { label: string; value: string }[],
    Function
  ] = useState([]);
  const [assetOptions, setAssetOptions]: [
    { label: string; value: string }[],
    Function
  ] = useState([]);

  // get supported assets
  const { data: supportedAssets } = useQuery(["supportedAssets"], async () => {
    return (await assetsApi.getSupportedAssets()) ?? [];
  });
  const supportedAssetsLoaded =
    Array.isArray(supportedAssets) && supportedAssets.length > 0;

  const { data: userResult } = useQuery(
    userAccountQueryKey,
    async () => {
      return await usersApi.getUserAccountById();
    },
    {
      enabled: retryInterval === 0 ? false : true,
      refetchInterval: retryInterval,
    }
  );
  const user = userResult && Result.getOrNull(userResult);

  const { data: transactions } = useQuery({
    queryKey: transactionsQueryKey,
    queryFn: async () => {
      return await transactionsApi.getTransactions();
    },
  });

  const chainConfig = useMemo(
    () => (!!user ? getChainConfig(user) : null),
    [user]
  );

  // map chains to supported assets
  const chainSupportedAssets: { [key: string]: Set<string> } = useMemo(
    () => mapChainsToSupportedAssets(supportedAssets),
    [supportedAssets]
  );

  useEffect(() => {
    if (!selectedCurrency && supportedAssets && user) {
      const networkOptions = getNetworkOptions({ user, supportedAssets });
      setNetworkOptions(networkOptions);

      const defaultNetwork = "ETH";
      setSelectedNetwork(defaultNetwork);

      const assetOptions = getAssetOptions(
        chainSupportedAssets[defaultNetwork]
      );
      setAssetOptions(assetOptions);
      setSelectedCurrency(assetOptions[0].value);
      setSelectedCurrencyLabel(assetOptions[0].label);
    }
  }, [selectedCurrency, supportedAssets, user, chainSupportedAssets]);

  const onAmountChange = (e) => {
    const value = e.target.value;
    if (amountRegex.test(value)) {
      setAmount(value);
      // if (onChange) onChange(selectedCurrency, amount);
    }
  };

  const onSelectedCurrencyChange = (e) => {
    const value = e.target.value;

    setSelectedCurrency(e.target.value);
    setSelectedCurrencyLabel(
      Array.from(assetOptions).find((o) => o.value === value)?.label || value
    );
  };

  const onSelectedNetworkChange = (e) => {
    const network = e.target.value;
    setSelectedNetwork(network);

    const assetOptions = getAssetOptions(chainSupportedAssets[network]);
    setAssetOptions(assetOptions);
    setSelectedCurrency(assetOptions[0].value);
    setSelectedCurrencyLabel(assetOptions[0].label);
  };

  return (
    <Grid
      container
      columns={12}
      alignItems="center"
      justifyItems="center"
      direction="column"
      sx={{ padding: 2 }}
    >
      {!!user && user.blocked && !user.deletedAt && (
        <Grid
          container
          width="100%"
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Typography
            variant="h6"
            align="center"
            classes={{ root: "dashboard-section-title" }}
            sx={{ mb: 2 }}
          >
            Beam Account
          </Typography>
          <Typography
            variant="body2"
            sx={{ textAlign: "justify", opacity: 0.6, mb: 3 }}
          >
            Your Beam account has been deactivated and new deposits to any Beam
            address are prohibited. Any future deposits to a Beam address will
            be retained and refunded in the form of the original asset in which
            it was sent. If you have any questions or concerns, please reach out
            to{" "}
            <Link href={"mailto:support@ansiblelabs.xyz"}>
              support@ansiblelabs.xyz
            </Link>{" "}
            for assistance.
          </Typography>
          <Divider sx={{ width: "100%" }} />
        </Grid>
      )}
      {!!user && user.deletedAt && (
        <Grid
          container
          width="100%"
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Typography
            variant="h6"
            align="center"
            classes={{ root: "dashboard-section-title" }}
            sx={{ mb: 2 }}
          >
            Beam Account
          </Typography>
          <Typography
            variant="body2"
            sx={{ textAlign: "justify", opacity: 0.6, mb: 3 }}
          >
            Your account has been deleted. If you have any questions or
            concerns, please reach out to{" "}
            <Link href={"mailto:support@ansiblelabs.xyz"}>
              support@ansiblelabs.xyz
            </Link>{" "}
            for assistance.
          </Typography>
          <Divider sx={{ width: "100%" }} />
        </Grid>
      )}
      {!!user && !(user.blocked || user.deletedAt) && (
        <Grid
          container
          width="100%"
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 4 }}
        >
          <Grid
            container
            width="100%"
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
            sx={{ mb: 3 }}
          >
            <Typography
              variant="h6"
              align="center"
              classes={{ root: "dashboard-section-title" }}
              sx={{ mb: 2 }}
            >
              Cash out with Beam
            </Typography>
            <CashOut
              user={user}
              amount={amount}
              selectedCurrency={selectedCurrency}
              selectedCurrencyLabel={selectedCurrencyLabel}
              onAmountChange={onAmountChange}
              onSelectedCurrencyChange={onSelectedCurrencyChange}
              assetOptions={assetOptions}
              supportedAssetsLoaded={supportedAssetsLoaded}
              chainConfig={chainConfig}
              selectedNetwork={selectedNetwork}
              onSelectedNetworkChange={onSelectedNetworkChange}
              networkOptions={networkOptions}
            />
          </Grid>
        </Grid>
      )}
      {!!user && !user.blocked && !user.deletedAt && (
        <PayoutAddresses user={user} chainConfig={chainConfig} />
      )}
      <Grid
        container
        width="100%"
        direction="column"
        alignItems="flex-start"
        justifyContent="center"
        sx={{ mt: 3 }}
      >
        <Typography
          variant="h6"
          align="center"
          classes={{ root: "dashboard-section-title" }}
        >
          Transactions
        </Typography>
        <TransactionsPreview data={transactions || []} />
      </Grid>
      <div style={{ height: 120 }}></div>
      {/* <Typography variant="h4" color="dark-green">
        Beam Address
      </Typography>
      <Address
        mt={24}
        mb={32}
        title="Send Crypto your Beam address!"
        description="Send ETH or USDC from an exchange, a wallet or ask a friend!"
        address={walletAddress}
      ></Address>
      <Typography variant="bigTitle" color="dark-green">
        Transactions
      </Typography>
      <ComingSoon
        mb={32}
        mt={32}
        title="Coming soon!"
        description="We’ll send you an update when we launch"
        icon={<ComingSoonIcon />}
      />
      <Typography variant="bigTitle" color="dark-green">
        Learn More
      </Typography>
      <ExpandingCard
        mt={16}
        title="Getting started"
        description="How to use your Beam address"
        icon={<QRIcon />}
        items={[
          "Open your crypto wallet, copy and paste your Beam address",
          "Send up to $10,000 USD equivalent per transfer (ETH or USDC only)",
          "Save your Beam address as a contact in your wallet for future use",
          "We’ll send a final email when your money is on the way",
        ]}
      />
      <ExpandingCard
        mt={16}
        title="About transfers"
        description="How to use your Beam address"
        icon={<TransferIcon />}
        items={[
          "We’ll send an initial email when we see a deposit in your Beam account",
          "Deposits are subject to compliance review and approval",
          "Once approved, deposits are traded for USD and transferred to your bank account",
          "We’ll send a final email when your money is on the way",
        ]}
      /> */}
    </Grid>
  );
};

export const Dashboard = withLDConsumer()(_Dashboard);
