import { useNavigate } from "react-router-dom";
import { Magic } from "magic-sdk";
import { OAuthExtension } from "@magic-ext/oauth";
import {
  Divider,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  PopoverOrigin,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useDisconnect } from "wagmi";
import { usersApi } from "../../api";
import { logout } from "../../api/utils";
import { MAGIC_AUTH_PUBLIC_KEY } from "../../config";
import { User } from "../../models/user";

import "./Menu.scss";

const magic = new Magic(MAGIC_AUTH_PUBLIC_KEY, {
  extensions: [new OAuthExtension()],
});

interface MenuProps {
  open: boolean;
  fullPage?: boolean;
  // anchorRef: React.MutableRefObject<null> | { current?: Element } | undefined;
  anchorRef: any;
  user: User | undefined | null;
  transformOrigin: PopoverOrigin;
  onClose?:
    | Function
    | ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
    | undefined;
}

function PopoverMenu({
  open,
  fullPage,
  anchorRef,
  user,
  onClose,
  transformOrigin,
}: MenuProps) {
  const navigate = useNavigate();
  const { disconnectAsync } = useDisconnect();

  const handleClick = (path) => {
    navigate(path);
  };

  const handleClose = () => {
    if (onClose) onClose({}, "backdropClick");
  };

  return (
    <Menu
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      anchorEl={anchorRef?.current}
      transformOrigin={transformOrigin}
      classes={{
        root: "menu-popover",
      }}
    >
      <MenuItem>
        <ListItemText>
          {`${user?.firstName} ${user?.lastName}`}
          <Typography variant="body2" classes={{ root: "menu-email-address" }}>
            {user?.emailAddress}
          </Typography>
        </ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => handleClick("/settings")}>
        <ListItemIcon classes={{ root: "menu-icon" }}>
          <SettingsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText classes={{ root: "menu-list-item-text" }}>
          Settings
        </ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleClick("/profile")}>
        <ListItemIcon classes={{ root: "menu-icon" }}>
          <PersonIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText classes={{ root: "menu-list-item-text" }}>
          Profile
        </ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleClick("/bank-info")}>
        <ListItemIcon classes={{ root: "menu-icon" }}>
          <AccountBalanceIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText classes={{ root: "menu-list-item-text" }}>
          Bank account
        </ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={async () => {
          logout();
          await usersApi.logout();
          const isLoggedInWithMagic = await magic.user.isLoggedIn();
          if (isLoggedInWithMagic) await magic.user.logout();
          if (disconnectAsync) await disconnectAsync();
        }}
      >
        <ListItemIcon classes={{ root: "menu-icon" }}>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText classes={{ root: "menu-list-item-text" }}>
          Sign out
        </ListItemText>
      </MenuItem>
    </Menu>
  );
}

export default PopoverMenu;
