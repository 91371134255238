import { useState } from "react";
import * as Yup from "yup";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import {
  Button,
  Grid,
  Typography,
  Divider,
  TextField,
  IconButton,
} from "@mui/material";
import ArrowBackFilledIcon from "@mui/icons-material/ArrowBack";
import React from "react";
import { useFormik } from "formik";
import { isValidRoutingNum } from "../../../utils/isValidRoutingNum";
import { usersApi } from "../../../api";
import { Loading } from "../../../components/Loading";
import {
  usBankAccountNumberInputRegex,
  usBankRoutingNumberInputRegex,
} from "../../../utils";

const validationSchema = Yup.object({
  routingNumber: Yup.string().test(
    "isValidRoutingNum",
    "Please enter a valid routing number",
    (value = "") => {
      return isValidRoutingNum(value);
    }
  ),
  accountNumber: Yup.string().min(6).max(17).required(),
  confirmAccountNumber: Yup.string()
    .required()
    .oneOf([Yup.ref("accountNumber")], "Account numbers must match"),
});

const _AchForm = ({
  onComplete,
  goBack,
}: {
  onComplete: (publicToken?: string) => void;
  goBack: () => void;
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      accountNumber: "",
      confirmAccountNumber: "",
      routingNumber: "",
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      setLoading(true);
      const response = await usersApi.updateBankingInfo(
        String(values.accountNumber),
        String(values.routingNumber)
      );
      setLoading(false);
      onComplete(response);
    },
  });

  const handlePaste = (e) => {
    e.preventDefault();
  };

  const handleRoutingNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = (e.target as HTMLInputElement).value;
    if (RegExp(usBankRoutingNumberInputRegex).test(value)) {
      formik.setFieldValue("routingNumber", value);
    }
  };

  const handleAccountNumberChange = (e) => {
    const value = (e.target as HTMLInputElement).value;
    if (RegExp(usBankAccountNumberInputRegex).test(value)) {
      formik.setFieldValue("accountNumber", value);
    }
  };

  const handleConfirmAccountNumberChange = (e) => {
    const value = (e.target as HTMLInputElement).value;
    if (RegExp(usBankAccountNumberInputRegex).test(value)) {
      formik.setFieldValue("confirmAccountNumber", value);
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      <Grid
        container
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ mb: 3 }}
      >
        <IconButton
          size="small"
          aria-label="delete"
          style={{ alignSelf: "flex-start" }}
          onClick={goBack}
        >
          <ArrowBackFilledIcon />
          <Typography variant="body1">Back</Typography>
        </IconButton>
      </Grid>

      <Typography variant="h6" align="center">
        Add bank account
      </Typography>
      <Typography variant="body2" align="center">
        We are only accepting Visa debit card payouts at this time.
      </Typography>
      <Grid
        container
        width="100%"
        direction="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <Typography variant="subtitle2" align="center" sx={{ mt: 4 }}>
          Bank account details
        </Typography>
      </Grid>
      <Divider style={{ width: "100%" }} sx={{ mt: 1 }} />
      <TextField
        fullWidth
        sx={{ mt: 3 }}
        label="Account number"
        onChange={handleAccountNumberChange}
        value={formik.values.accountNumber}
        error={
          formik.values.accountNumber && formik.errors.accountNumber
            ? true
            : false
        }
        helperText={
          formik.values.accountNumber && formik.errors.accountNumber
            ? formik.errors.accountNumber
            : ""
        }
      />
      <TextField
        fullWidth
        sx={{ mt: 3 }}
        label="Validate account number"
        onChange={handleConfirmAccountNumberChange}
        onPaste={handlePaste}
        value={formik.values.confirmAccountNumber}
        error={
          formik.values.confirmAccountNumber &&
          formik.errors.confirmAccountNumber
            ? true
            : false
        }
        helperText={
          formik.values.confirmAccountNumber &&
          formik.errors.confirmAccountNumber
            ? formik.errors.confirmAccountNumber
            : ""
        }
      />
      <TextField
        fullWidth
        sx={{ mt: 3 }}
        label="Routing number"
        onChange={handleRoutingNumberChange}
        value={formik.values.routingNumber}
        error={Boolean(
          formik.values.routingNumber && formik.errors.routingNumber
        )}
        helperText={
          formik.values.routingNumber && formik.errors.routingNumber
            ? formik.errors.routingNumber
            : ""
        }
      />
      <Button
        variant="contained"
        color="secondary"
        size="large"
        sx={{ mt: 4 }}
        fullWidth
        disabled={!formik.isValid || loading}
        onClick={() => formik.handleSubmit()}
      >
        Add Bank Account
      </Button>
    </>
  );
};

export const AchForm = withLDConsumer()(_AchForm);
