// This is using a Mod 10 checksum, aka the Luhn algorithm
// http://www.brainjar.com/js/validation/
// https://en.wikipedia.org/wiki/ABA_routing_transit_number#Check_digit

export function isValidRoutingNum(routingNum: string) {
  let n, t;

  // First, remove any non-numeric characters.
  t = "";
  for (let i = 0; i < routingNum.length; i++) {
    const c = parseInt(routingNum.charAt(i), 10);
    if (c >= 0 && c <= 9) t = t + c;
  }

  if (t.length !== 9) return false;

  // Now run through each digit and calculate the total.
  n = 0;
  for (let i = 0; i < t.length; i += 3) {
    n +=
      parseInt(t.charAt(i), 10) * 3 +
      parseInt(t.charAt(i + 1), 10) * 7 +
      parseInt(t.charAt(i + 2), 10);
  }

  // If the resulting sum is an even multiple of ten (but not zero),
  // the aba routing number is good.
  if (n !== 0 && n % 10 === 0) return true;
  else return false;
}
