import { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Result } from "../../models/result";
import { usersApi, bankingInfoQueryKey, userAccountQueryKey } from "../../api";
import { BankingAccount, BankingAccountType } from "../../api/users";
import { BankingInfoDto } from "../../dtos/userDto";
import "./BankDetails.scss";

export default function BankDetails() {
  const [isSwitching, setIsSwitching] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const paymentInfoId = searchParams.get("id");

  const { data: userResult } = useQuery(userAccountQueryKey, async () => {
    return await usersApi.getUserAccountById();
  });
  const user = userResult && Result.getOrNull(userResult);

  const { data: bankingInfoResult, isFetching } = useQuery(
    [...bankingInfoQueryKey, paymentInfoId],
    async () => {
      return paymentInfoId
        ? await usersApi.getBankingInfoById(paymentInfoId)
        : null;
    }
  );
  const bankingInfo: BankingAccount =
    bankingInfoResult && Result.getOrNull(bankingInfoResult);
  const isSolePayoutMethod = bankingInfo?.paymentInfoCount === 1;

  useEffect(() => {
    setSwitchChecked(!!bankingInfo?.preferred);
  }, [bankingInfo?.preferred]);

  const getSubtitle = (): string => {
    switch (bankingInfo?.type) {
      case BankingAccountType.CARD:
        return "Debit Card";
      default:
        return "Bank Account";
    }
  };

  const handleRemove = async () => {
    setIsRemoving(true);

    try {
      const resp = await usersApi.deleteBankingInfo(bankingInfo.id);

      if (resp.isOk) {
        navigate("/bank-info");
      }
    } finally {
      setIsRemoving(false);
    }
  };

  const handlePrimarySwitch = async (event) => {
    setIsSwitching(true);

    try {
      const resp = await usersApi.updatePreferredBankingInfo(
        bankingInfo.id,
        event.target.checked
      );
      const result: BankingInfoDto | null = resp && Result.getOrNull(resp);

      if (result?.id) {
        setSwitchChecked(!!result?.preferred);
      }
    } finally {
      setIsSwitching(false);
    }
  };

  const disabled = useCallback(() => {
    return (
      !user?.deletedAt &&
      !user?.blocked &&
      (isSolePayoutMethod || isFetching || isSwitching || isRemoving)
    );
  }, [user, isSolePayoutMethod, isFetching, isSwitching, isRemoving]);

  const showAddBackupPayment = useCallback(() => {
    return !user?.deletedAt && !user?.blocked && isSolePayoutMethod;
  }, [isSolePayoutMethod, user]);

  return (
    <>
      <Grid
        container
        direction={"column"}
        classes={{ root: "bank-details-container" }}
      >
        {/* todo: create shared component/hook for back */}
        <Grid item classes={{ root: "menu-back-row" }}>
          <IconButton
            classes={{ root: "menu-back-btn" }}
            onClick={() => navigate("/bank-info")}
          >
            <ArrowBackIcon fontSize="medium" />
          </IconButton>
          <Typography variant="body1" classes={{ root: "menu-back-text" }}>
            Back
          </Typography>
        </Grid>
        <Grid item direction={"column"} classes={{ root: "menu" }}>
          <Grid item classes={{ root: "profile-subtitle" }}>
            <Typography>{getSubtitle()}</Typography>
          </Grid>
          <Divider classes={{ root: "menu-divider" }} />
          <Grid
            item
            direction={"column"}
            classes={{ root: "profile-content-container" }}
          >
            {bankingInfo ? (
              <>
                <Grid item>
                  <Typography variant="h6" classes={{ root: "gfdgds" }}>
                    {bankingInfo?.name}
                  </Typography>
                  <Typography variant="body1" classes={{ root: "fsafsafa" }}>
                    {`********** ${bankingInfo?.last4Digits}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  display={"flex"}
                  alignItems={"center"}
                  classes={{ root: "primary-switch-container" }}
                >
                  <Switch
                    color="primary"
                    checked={switchChecked}
                    disabled={disabled()}
                    onChange={handlePrimarySwitch}
                  />
                  <Typography
                    variant="body1"
                    classes={{ root: "primary-switch-label" }}
                  >
                    Primary payout method
                  </Typography>
                </Grid>
                <Grid
                  item
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={
                    isSolePayoutMethod ? "space-between" : "flex-end"
                  }
                  classes={{ root: "remove-payout-container" }}
                >
                  {isSolePayoutMethod && (
                    <Typography
                      variant="caption"
                      classes={{ root: "remove-warning-text" }}
                    >
                      Before you can remove this payout method, we need you to
                      add a backup payout
                    </Typography>
                  )}
                  <Button
                    variant="outlined"
                    color="error"
                    disabled={disabled()}
                    onClick={() => {
                      setShowRemoveConfirmation(true);
                    }}
                  >
                    Remove
                  </Button>
                </Grid>
                {showAddBackupPayment() && (
                  <Grid item classes={{ root: "add-backup-container" }}>
                    <Button
                      variant="outlined"
                      classes={{ root: "add-backup-btn" }}
                      onClick={() => {
                        navigate("/payment");
                      }}
                    >
                      Add Backup Payout
                    </Button>
                  </Grid>
                )}
              </>
            ) : (
              <Grid
                item
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress color="primary" sx={{ mt: 10 }} />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Modal
          open={showRemoveConfirmation}
          onClose={() => {
            setShowRemoveConfirmation(false);
          }}
          classes={{ root: "remove-conf-modal" }}
          sx={{
            top: "40%",
            left: "50%",
            maxWidth: "350px",
            ml: "-190px",
          }}
        >
          <Alert severity="warning" sx={{ width: "100%" }}>
            <Grid container direction={"row"}>
              <Grid item>
                <AlertTitle>Warning</AlertTitle>
                Are you sure you want to remove this payout method?
              </Grid>
              <Grid
                item
                display={"flex"}
                justifyContent={"flex-end"}
                width={"100%"}
              >
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={handleRemove}
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          </Alert>
        </Modal>
      </Grid>
    </>
  );
}
