import { Grid, Typography } from "@mui/material";

export const SevereRiskWalletWarning = () => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Typography variant="h5" align="center" sx={{ mt: 14, mb: 1 }}>
        You are not permitted to use the Beam service
      </Typography>
      <Typography variant="body1" align="center">
        please contact us if you have any further questions
      </Typography>
    </Grid>
  );
};
