import { BACKEND_URL, SKYFLOW_VAULT_ID, SKYFLOW_VAULT_URL } from "../config";
import { LogLevel, Env } from "skyflow-react-js";

// utility function to get Skyflow bearer token from Ansible API
const getSkyflowBearerToken = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    const Http = new XMLHttpRequest();

    Http.onreadystatechange = () => {
      if (Http.readyState === 4) {
        if (Http.status === 202) {
          const response = JSON.parse(Http.responseText);
          resolve(response.accessToken);
        } else {
          console.log(Http.status);
          console.log(Http.responseText);
          reject("Error occured");
        }
      }
    };

    Http.onerror = (error) => {
      console.log(error);
      reject("Error occured");
    };
    const url = `${BACKEND_URL}/skyflow/bearerToken`;
    Http.open("GET", url);
    Http.setRequestHeader(
      "Authorization",
      `Bearer ${localStorage.getItem("accessToken")}`,
    );
    Http.withCredentials = true;
    Http.send();
  });
};

// setup Skyflow Elements config
const skyflowConfig = {
  vaultID: SKYFLOW_VAULT_ID, // ID of the vault that the client should connect to.
  vaultURL: SKYFLOW_VAULT_URL, // URL of the vault that the client should connect to.
  getBearerToken: getSkyflowBearerToken, // Helper function that retrieves a Skyflow bearer token from your backend.
  options: {
    logLevel:
      process?.env?.NODE_ENV === "production" ? LogLevel.ERROR : LogLevel.DEBUG, // Optional, defaults to 'ERROR'.
    env: process?.env?.NODE_ENV === "production" ? Env.PROD : Env.DEV, // Optional, defaults to 'PROD'.
  },
};

export default skyflowConfig;
